import React from "react";
import "./index.scss";
import { useParams } from "react-router-dom";
import { setAcceptIncomming } from "../../../components/SIPJS";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import * as urlModule from "../../../modules/urlModule";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Spinner from "../../../components/customComponents/Spinner";
import Articles from "../../AdminPanel/Articles";

const GenerateLabelsDaily = () => {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState();
    const idsRef = React.useRef();
    const [searchParams] = useSearchParams();
    const ids = searchParams.get('IDs');
    const targetRef = React.useRef();

 

 

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getDataForShipmentLabels`,
            data: {
                deals: ids.split(","),
            },
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === "ok") {
                let tmp = res.data.data;
                tmp.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
                setData({ status: "ok", data: tmp });
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            }
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!ids) return;
        getData();
    }, [ids]);

    React.useEffect(() => {
        const rootImg = document.querySelector("#rootImg");
        if (rootImg) rootImg.remove();
        setAcceptIncomming(false);
    }, []);

    return (
        <div className="route__kreirajNaljepnice" ref={targetRef}>
            {spinner && <Spinner />}
            {(() => {
                let final = [];
                if (!data) return null;
                for (let i = 0; i <= data.data.length - 1; i += 12) {
                    final.push(
                        <div className="route__kreirajNaljepnice__wrap" key={i}>
                            {data.data.slice(i, i + 12).map((item, index) => (
                                <Naljepnica key={index} data={item} />
                            ))}
                        </div>
                    );
                }
                return final;
            })()}
        </div>
    );
};

const Naljepnica = ({ data }) => {
    const barcodeRef = React.useRef();
    const generatedRef = React.useRef(false);
    const [finalCode, setFinalCode] = React.useState();

    const fillData = (text) => {
        text = String(text);
        while (text.length < 10) text = "0" + text;
        return text;
    };

    React.useEffect(() => {
        if (!data || !barcodeRef.current || generatedRef.current) return;
        let finalCodeTmp = String(data.DealID).padStart(7, '0');
        finalCodeTmp = `006${finalCodeTmp}`;
        setFinalCode(finalCodeTmp);

        let code = new window.Code128(`AG${fillData(finalCodeTmp)}`);
        code.insert(barcodeRef.current);
        generatedRef.current = true;
    }, [data]);

    return (
        <div className="route__kreirajNaljepnice__naljepnica">
            <div className="route__kreirajNaljepnice__naljepnica__left">
                <div className="route__kreirajNaljepnice__naljepnica__left__dexpress">
                    <p>D Express</p>
                    <p>Zage Malivuk 1</p>
                    <p>Beograd (Palilula)</p>
                </div>
                <div ref={barcodeRef} className="route__kreirajNaljepnice__naljepnica__left__barcode"></div>
                <div className="route__kreirajNaljepnice__naljepnica__left__barcodeBottom">AG{finalCode}</div>
                <div className="route__kreirajNaljepnice__naljepnica__left__date">{moment(data.createdAt).format("DD.MM.YYYY. HH:mm")}</div>
                <div className="route__kreirajNaljepnice__naljepnica__left__ref">
                    <p>Ref: {data.ID}</p>
                    <p>CRM: {data.DealID}</p>
                </div>
                <div className="route__kreirajNaljepnice__naljepnica__left__product">
                    <p><b>{data.ProductName}</b></p>
                </div>
                <div className="route__kreirajNaljepnice__naljepnica__left__price">
                    <span>Cena</span>
                    <span>{data.ProductPayout} RSD</span>
                </div>
                <p>Plaća nalogodavac virmanom</p>
            </div>
            <div className="route__kreirajNaljepnice__naljepnica__right">
                <div className="route__kreirajNaljepnice__naljepnica__right__sender">
                    <h3>Pošiljalac:</h3>
                    <p>SPARK NATURA DOO</p>
                    <p>Marije Bursać 6</p>
                    <p>Loznica</p>
                </div>
                <br />
                <div className="route__kreirajNaljepnice__naljepnica__right__recipient">
                    <h3>Primalac:</h3>
                    <p>{data.BuyerName}</p>
                    <p>{data.BuyerAddress}</p>
                    <p>{data?.BuyerTown?.toUpperCase()} {data.BuyerZIP}</p>
                    <p>Telefon: {data.BuyerPhone}</p>
                    {data.Note && <p>INFO: <b>{data.Note}</b></p>}
                </div>
            </div>
        </div>
    );
};

export default GenerateLabelsDaily;
