import React, { useCallback, useState } from "react";
import "./index.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as modalActions from "../../actions/modalActions";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import Spinner from "../customComponents/Spinner";
import { Kanban } from "../customComponents/Kanban";
import { headerColors } from "../../modules/leadModule";


const SingleClient = (props) => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState();

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/singleClientStatistics`,
            data: {
                ClientID: props.data,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getData();
    }, [])

    return <div className="component__singleClient" style={{ backgroundColor: "#272737" }} >
        <div className="component__singleClient__close" >
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value="X" onClick={props.onClose} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
        {data?.status === "ok" ? <div className="component__singleClient__content">
            <>{(() => {
                let dr = Number(data.data.ClientInfo[data.data.Client.ID]?.deliveredDeals) / Number(data.data.ClientInfo[data.data.Client.ID]?.deals) * 100;
                let badge = "?";
                if (data.data.ClientInfo[data.data.Client.ID]?.deals === 1) {
                    if (dr < 100) {
                        badge = "?";
                    } else if (dr > 50) {
                        badge = "Vrlo Pouzdan Kupac";
                    };
                } else if (data.data.ClientInfo[data.data.Client.ID]?.deals === 2) {
                    if (dr < 50) {
                        badge = "Rizičan Kupac";
                    } else if (dr === 50) {
                        badge = "Pouzdan Kupac";
                    } else if (dr > 50) {
                        badge = "Vrlo Pouzdan Kupac";
                    };
                } else if (data.data.ClientInfo[data.data.Client.ID]?.deals === 3) {
                    if (dr < 66) {
                        badge = "Rizičan Kupac";
                    } else if (dr === 66) {
                        badge = "Pouzdan Kupac";
                    } else if (dr > 66) {
                        badge = "Vrlo Pouzdan Kupac";
                    };
                } else if (data.data.ClientInfo[data.data.Client.ID]?.deals > 3) {
                    if (dr < 50) {
                        badge = "Rizičan Kupac";
                    } else if (dr < 75) {
                        badge = "Nepouzdani Kupac";
                    } else if (dr <= 85) {
                        badge = "Pouzdan Kupac";
                    } else {
                        badge = "Vrlo Pouzdan Kupac";
                    };
                }
                return <p style={{
                    color: badge === "Rizičan Kupac" ? "red" : badge === "Nepouzdani Kupac" ? "orange" : badge === "Pouzdan Kupac" ? "green" : "#D4AF37"
                }}>{badge === "Vrlo pouzdan Kupac" && <img src="/images/star.png" />} {badge}</p>;
            })()}</>
            <img src="/images/undefined.svg" />
            <p>ID: {data.data.Client.ID}</p>
            <p>Ime i prezime: {data.data.Client.FirstLastName}</p>
            <p>Broj telefona: {data.data.Client.PhoneNumber}</p>
            <div className="component__singleClient__content__table">
                <p>Leadova</p>
                <p>Potvrdjeno</p>
                <p>AR</p>
                <p> {data.data.ClientInfo[data.data.Client.ID].leads}</p>
                <p>{data.data.ClientInfo[data.data.Client.ID].approvedLeads}</p>
                <p>{(data.data.ClientInfo[data.data.Client.ID].approvedLeads / data.data.ClientInfo[data.data.Client.ID].leads * 100).toFixed(2)} %</p>
                <p>Dealova</p>
                <p>Preuzeto</p>
                <p>DR</p>
                <p>{data.data.ClientInfo[data.data.Client.ID].deals}</p>
                <p>{data.data.ClientInfo[data.data.Client.ID].deliveredDeals} </p>
                <p>{(data.data.ClientInfo[data.data.Client.ID].deliveredDeals / data.data.ClientInfo[data.data.Client.ID].deals * 100).toFixed(2)} %</p>
                <p>Vrijednost</p>
                <p>Preuzeta vrijednost </p>
                <p></p>
                <p> {data.data.ClientInfo[data.data.Client.ID].revenue.toFixed(2)} €</p>
                <p> {data.data.ClientInfo[data.data.Client.ID].deliveredRevenue.toFixed(2)} €</p>
                <p></p>
            </div>
            <div className="component__singleClient__content__buttons">
                <CustomButtonSmall theme="dark" accent="#2b2c3e" value="Pogledaj leadove" onClick={(e)=>{animateBox(e, <DealKanban deals={data.data.ClientInfo[data.data.Client.ID]?.leadIDs} type={"lead"} />)}} style={{ border: "1px solid rgb(192 10 235)" }} />
                <CustomButtonSmall theme="dark" accent="#2b2c3e" value="Pogledaj dealove" onClick={(e)=>{animateBox(e, <DealKanban deals={data.data.ClientInfo[data.data.Client.ID]?.dealIDs} />)}} style={{ border: "1px solid rgb(192 10 235)" }} />
            </div>

        </div> : <Spinner color="rgb(102, 100, 229)" />}



    </div>

}


const DealKanban = (props) => {
    return <div style={{
        all: "initial",
        width: '100%',
        height: '100%',
        display: "grid",
        gridTemplateRows: "40px max-content",
        gridTemplateColumns: "1fr"
    }}>
        <CustomButtonSmall accent="#2b2c3e" value="X" style={{ marginLeft: "auto", border: "1px solid rgb(233, 86, 86)" }} onClick={props.onClose} />
        <Kanban
            type={props.type === "lead" ? "lead" : 'deal'}
            headersColors={props.type === "lead" ? headerColors("lead") : headerColors("deal")}
            customFilters={[{
                name: "ID",
                op: "in",
                value: props.deals
            }]}
        /></div>;
};

export default SingleClient