import React from "react";
import "./index.scss";

import * as modalActions from "../../actions/modalActions";
import {useSelector, useDispatch} from "react-redux";

let showModalController = () => null;

const ModalController = () => {
    const internalRef = React.useRef();
    const modalSelector = useSelector(state => state.modal);
    const modalDispatch = useDispatch();

    const setModalVisibility = newState => {
        if (!internalRef.current) return modalDispatch(modalActions.setModalVisibility(newState));

        Promise.allSettled([...internalRef.current.children].map(ch => new Promise(r2 => {
            try {
                let computed = getComputedStyle(ch);
                ch.animate([
                    {opacity: computed.opacity},
                    {opacity: newState ? 1 : 0}
                ], {
                    duration: 150,
                    iterations: 1,
                    easing: "ease",
                    fill: "both"
                }).onfinish = () => r2();
            } catch {
                r2();
            };
        }))).then(() => {
            modalDispatch(modalActions.setModalVisibility(newState));
        }).catch(() => {
            modalDispatch(modalActions.setModalVisibility(newState));
        });
    };

    React.useEffect(() => {
        if (!internalRef.current) return;
        if (modalSelector.modals.length > 0) {
            internalRef.current.style.pointerEvents = "all";
            internalRef.current.animate([
                {opacity: getComputedStyle(internalRef.current).opacity},
                {opacity: 1}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease-in-out"});
        } else {
            internalRef.current.style.pointerEvents = "none";
            internalRef.current.animate([
                {opacity: getComputedStyle(internalRef.current).opacity},
                {opacity: 0}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease-in-out"});
        };
    }, [modalSelector]);

    React.useEffect(() => {
        if (modalSelector.modals.length === 0) return;
        if (!internalRef?.current) return;

        let computed = getComputedStyle(internalRef.current);

        if (modalSelector.visible) {
            internalRef.current.animate([
                {width: computed.width, height: computed.height, bottom: computed.bottom, right: computed.right},
                {width: "100%", height: "100%", bottom: 0, right: 0}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease-in-out"});
        } else {
            internalRef.current.animate([
                {width: computed.width, height: computed.height, bottom: computed.bottom, right: computed.right},
                {width: "64px", height: "64px", bottom: "32px", right: "32px"}
            ], {duration: 300, iterations: 1, fill: "both", easing: "ease-in-out"});
        };
    }, [modalSelector.visible]);

    React.useEffect(() => {
        showModalController = () => setModalVisibility(true);
    }, [setModalVisibility]);

    return <div ref={internalRef} draggable={false} className={`component__modalController ${!modalSelector.visible ? "component__modalController--hidden" : ""}`}>
        {modalSelector.visible === false ? <>
            <img draggable={false} src="/images/call.png" onClick={() => {
                setModalVisibility(true);
            }} />
        </> : null}
        {modalSelector.modals.map(item => {
            return <React.Fragment key={item.ID}>
                {React.cloneElement(item.component, {
                    onClose: () => modalDispatch(modalActions.removeModal(item.ID)),
                    hideModal: () => setModalVisibility(false),
                    data: item.additionalInfo,
                })}
            </React.Fragment>;
        })}
    </div>
};

export default ModalController;
export {
    showModalController
};