import "./index.scss";
import React from "react";
import SIPButton from "../SIPJS/SIPButton";
import axios from "axios";

import * as backendModule from "../../modules/backendModule";
import * as notificationActions from "../../actions/notificationActions";
import { compareUsers } from "../../actions/chatUsersAction";
import AnimateModule from '../../modules/animateModule'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animateBox } from "../../modules/componentAnimation";
import { on as socketOn, off as socketOff } from "../../modules/socketModule";
import { updateLanguage } from "../../actions/languageAction";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import AgentSector from "../AgentSector";
import BackendTasks from "../BackendTasks";



const HeaderNotifications = props => {
    const notificationSelector = useSelector(state => state.notifications ?? []);

    return <div className="component__header__notifications">
        {notificationSelector.map(notify => <SingleNotification key={notify.ID} data={notify} />)}
    </div>
};

const SingleNotification = props => {
    const mainRef = React.useRef();
    const mainDispatch = useDispatch();
    const notificationSelector = useSelector(state => state.notifications ?? []);

    const closeNotification = (e) => {
        if (!mainRef.current) {
            if (props.data.onClose) props.data.onClose(e, props.data);
            return mainDispatch(notificationActions.removeNotification(props.data.ID));
        };
        mainRef.current.animate([
            { transform: getComputedStyle(mainRef.current).transform },
            { transform: "translateX(100%)" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" }).onfinish = () => {
            if (!mainRef.current) {
                if (props.data.onClose) props.data.onClose(e, props.data);
                return mainDispatch(notificationActions.removeNotification(props.data.ID));
            };
            mainRef.current.animate([
                { height: getComputedStyle(mainRef.current).height, padding: getComputedStyle(mainRef.current).padding, marginBottom: getComputedStyle(mainRef.current).marginBottom },
                { height: 0, padding: `0 ${getComputedStyle(mainRef.current).paddingTop}`, marginBottom: 0 }
            ], { duration: 300, iterations: 1, fill: "both", easing: "ease" }).onfinish = () => {
                if (props.data.onClose) props.data.onClose(e, props.data);
                return mainDispatch(notificationActions.removeNotification(props.data.ID));
            };
        };
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { transform: getComputedStyle(mainRef.current).transform },
            { transform: "translateX(0)" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef]);

    React.useEffect(() => {
        let curNotify = notificationSelector.find(ns => ns.ID === props.data.ID);
        if (!curNotify) return;

        if (curNotify.isClosed) {
            setTimeout(() => closeNotification(), 100);
        };
    }, [notificationSelector]);

    return <div className="component__header__notifications__notification" ref={mainRef}>
        <p className="component__header__notifications__notification__text">{props.data.text}</p>
        <div className="component__header__notifications__notification__info">
            <p className="component__header__notifications__notification__info__date">{props.data.date.toLocaleTimeString()}</p>
            <div className="component__header__notifications__notification__info__buttons">
                {props.data.onConfirm && <CustomButtonSmall value={props.data.confirmText ?? "Pogledaj"} style={{ height: "40px", marginRight: "10px" }} accent="#0D99FF" onClick={e => {
                    if (props.data.onConfirm) {
                        props.data.onConfirm(e, props.data);
                    };
                }} />}
                <CustomButtonSmall value={props.data.closeText ?? "Obriši"} style={{ height: "40px" }} accent="#E94242" onClick={closeNotification} />
            </div>
        </div>
    </div>
};
export default HeaderNotifications