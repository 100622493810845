import React from "react";
import "./index.scss";

import * as backendModule from "../../../modules/backendModule";
import axios from "axios";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import Dropdown from "../../../components/customComponents/Dropdown";


const DealsList = (props) => {
    const [allCountries, setAllCountries] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setAllCountries(res.data)
            };
            return setAllCountries({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setAllCountries({ status: "error", data: "SERVER_ERROR" });
        });
    }, []);

    const startProcess = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/forcePrepareShippment`,
            data: { forceCountries: [selectedCountry], forceFE: true },
            ...backendModule.axiosConfig
        }).then(res => {
        }).catch(() => {

        }).finally(() => {
            props.onClose();
        })
    }

    return <div className="route__processDeals__dealsList" style={{
        backgroundColor: "#2e2e41",
        width: "500px",
        height: "300px",
        margin: "auto",
        borderRadius: "5px"
    }}>
        {allCountries?.status === "ok" && <><p>Izaberite državu za koju zelite odraditi najavu</p>
            <Dropdown theme="dark" accent="#3D3D55" data={allCountries.data.map(elem => {
                return {
                    name: `${elem.CountryName}`,
                    value: elem.CountryCode
                }
            })} selected={''} onChange={e => setSelectedCountry(e.value)} /></>}
        <div className="route__processDeals__dealsList__buttons">
            <button onClick={startProcess}>Potvrdi</button>
            <button onClick={() => { props.onClose() }}>Zatvori</button>
        </div>

    </div>

}

// const DealsList = (props) => {
//     const [data, setData] = React.useState();
//     const [countries, setCountries] = React.useState();
//     const timestampRef = React.useRef(Date.now());

//     const getData = () => {
//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/deals/countAllDealsPerCountry`,
//             data: {
//                 filters: [
//                     {
//                         name: "updatedAt",
//                         op: "dleq",
//                         value: timestampRef.current
//                     },
//                     {
//                         name: "Status",
//                         op: "eq",
//                         value: props.Status
//                     }
//                 ]
//             },
//             ...backendModule.axiosConfig
//         }).then(res => {
//             if (res.data.status === "ok") return setData(res.data);
//             return setData({status: "error", data: "SERVER_ERROR"});
//         }).catch(() => {
//             return setData({status: "error", data: "SERVER_ERROR"});
//         });
//     };

//     const getCountries = () => {
//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/countries/getAllCountries`,
//             ...backendModule.axiosConfig
//         }).then(res => {
//             if (res.data.status === "ok") return setCountries(res.data);
//             return setCountries({status: "error", data: "SERVER_ERROR"});
//         }).catch(() => {
//             return setCountries({status: "error", data: "SERVER_ERROR"});
//         });
//     };

//     React.useEffect(() => {
//         getData();
//         getCountries();
//     }, []);

//     React.useEffect(() => {
//         if (data?.status === "ok") {
//             if (data?.data?.length === 0) props.onClose();
//         };
//     }, [data]);

//     let text = "";
//     switch (props.Status) {
//         case 0: text = "Obradi"; break;
//         case 1: text = "Pripremi za isporuku"; break;
//         case 2: text = "Najavi pošiljke"; break;
//         default: text = ""; break;
//     };

//     return <div className="route__shippmentAgent__dealsList">
//         <div className="route__shippmentAgent__dealsList__buttons">
//         {props.onClose && <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => props.onClose()} style={{border: "1px solid rgb(233, 86, 86)"}} />}
//         </div>

//         <FilteredCustomTable
//             headers={["Država", "Broj dealova"]}
//             theme="dark"
//             accent="#3D3D55"
//             data={(()=>{
//                 if (!data || !countries) return [[{keyID: "dataSpinner", type: "spinner"}]];
//                 if (data.status === "error") return [[{keyID: "dataErrorText", type: "custom", data: <p style={{textAlign: "center"}}>Došlo je do greške prilikom dohvatanja artikala</p>}]]
//                 if (countries.status === "error") return [[{keyID: "dataErrorText", type: "custom", data: <p style={{textAlign: "center"}}>Došlo je do greške prilikom dohvatanja država</p>}]]
//                 if (data.data.length === 0) return [[{keyID: "noDataText", type: "custom", data: <p style={{textAlign: "center"}}>Nema artikala za prikaz</p>}]];
//                 let tmp = data.data.map(elem => {
//                     let curCountry = countries.data.find(t => t.CountryCode === elem.Country);
//                     if (!curCountry) curCountry = {
//                         CountryName: "Nepoznata država",
//                         CountryCode: elem.Country 
//                     };
//                     return [
//                         {keyID: String(elem.ID), type: "text", text: curCountry.CountryName},
//                         {keyID: String(elem.ID), type: "text", text: elem.Count},
//                         {keyID: String(elem.ID), type: "groupNewline", group: [
//                             {keyID: String(elem.ID), type: "button", text: text, onClick: e => {
//                                 if (props.Status === 0) {
//                                     let wnd = window.open(
//                                         `${window.location.origin}/#/ShippmentAgent/ProcessDeals?timestamp=${timestampRef.current}&country=${curCountry.CountryCode}`,
//                                         "_blank"
//                                     );
//                                     wnd.addEventListener("beforeunload", () => {
//                                         getData()
//                                     });
//                                 } else if (props.Status === 1) {
//                                     let wnd = window.open(
//                                         `${window.location.origin}/#/ShippmentAgent/PrepareShippment?timestamp=${timestampRef.current}&country=${curCountry.CountryCode}`,
//                                         "_blank"
//                                     );
//                                     wnd.addEventListener("beforeunload", () => {
//                                         getData()
//                                     });
//                                 } else if (props.Status === 2) {
//                                     let wnd = window.open(
//                                         `${window.location.origin}/#/ShippmentAgent/CreateShipment?timestamp=${timestampRef.current}&country=${curCountry.CountryCode}`,
//                                         "_blank"
//                                     );
//                                     wnd.addEventListener("beforeunload", () => {
//                                         getData()
//                                     });
//                                 };
//                             }, style: {
//                                 background: "#2b2c3e",
//                                 border: "1px solid #E264E59E"
//                             }}
//                         ]}
//                     ];
//                 });

//                 return tmp;
//             })()}
//         />
//     </div>
// };

export default DealsList;