export const sipUpdate = (newData) => {
    return {
        type: "SIP_UPDATE",
        payload: newData
    };
};

export const setInboundStatus = (newStatus) => {
    return {
        type: "SIP_SET_INBOUND_STATUS",
        payload: newStatus
    };
};