import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { on as socketOn, off as socketOff } from "../../modules/socketModule";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import Dropdown from "../customComponents/Dropdown";

let searchTimeout = null;
const Tasks = (props) => {
    const [refreshers, setRefreshers] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState();
    const [animRunning, setAnimRunning] = React.useState(false);  
    const [search, setSearch] = React.useState("");

    const mianRef = React.useRef();
    const searchRef = React.useRef();
    
    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    let onSelect = props.onSelect ? (type, data) => {
        props.onSelect({type, data});
        return onClose();
    } : null;

    const onClose = () => {
        if (!mianRef?.current) return props.onClose();

        mianRef.current.animate([
            {opacity: getComputedStyle(mianRef.current).opacity},
            {opacity: 0}
        ], {duration: 200, iterations: 1, fill: "both", easing: "ease"});
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mianRef?.current) return;

        mianRef.current.animate([
            { opacity: getComputedStyle(mianRef.current).opacity },
            { opacity: 1 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease-in-out" });
    }, [mianRef.current]);

    React.useEffect(() => {
        if (!searchRef?.current) return;

        const handler = (e) => {
            let val = e.currentTarget.value;
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
                setSearch(val);
            }, 1000);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            clearTimeout(searchTimeout);
            try {
                searchRef?.current?.removeEventListener("input", handler);
            } catch {};
        };
    }, [searchRef]);

    return <div className="components__tasks" ref={mianRef}>
        <div className="components__tasks__close">
            <img src="/images/closeImg.svg" onClick={onClose} />
        </div>
        <div className="components__tasks__wrap">

            <div className="components__tasks__wrap__left">
                <CustomInput ref={searchRef} theme="dark" accent="white" placeholder="🔍 Traži" style={{ width: "100%" }} />

                <div className="components__tasks__wrap__left__toggle">
                    <div className="components__tasks__wrap__left__toggle__main" onClick={e => {
                        e.currentTarget.parentNode.querySelector(".components__tasks__wrap__left__toggle__side").classList.toggle("active");
                        e.currentTarget.classList.toggle("active");
                    }}>
                        <img src="/images/closeImg.svg" className="components__tasks__wrap__left__toggle__main__imgMain" />
                        <span>Kreiraj...</span>
                        <img src="/images/downArrow.png" className="components__tasks__wrap__left__toggle__main__img" />
                    </div>
                    <div className="components__tasks__wrap__left__toggle__side" onClick={e => e.stopPropagation()}>
                        {(userFlagsSelector.isAdmin || userFlagsSelector.isCCManager) && <div className="components__tasks__wrap__left__toggle__side__btn" onClick={e => {
                            animateBox(e, <Tasks_addTask onChange={() => {
                                refreshers["allTasks"] && refreshers["allTasks"]()
                            }} />);
                        }}>
                            <img src="/images/taskTask.svg" />
                            <span>Novi zadatak</span>
                        </div>}
                        <div className="components__tasks__wrap__left__toggle__side__btn" onClick={e => {
                            animateBox(e, <Tasks_addNote onChange={() => {
                                refreshers["allNotes"] && refreshers["allNotes"]();
                                refreshers["pinnedNotes"] && refreshers["pinnedNotes"]();
                            }} />)
                        }}>
                            <img src="/images/taskNote.svg" />
                            <span>Nova bilješka</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`components__tasks__wrap__right ${(modalOpen && !animRunning) ? "components__tasks__wrap__right--animateOut" : ""}`}>
                <Tasks_allNotes modal={{ modalOpen, setModalOpen }} refresher={r => {
                    setRefreshers(oldR => {
                        return { ...oldR, "allNotes": r }
                    });
                }} allRefreshers={refreshers} search={search} onSelect={onSelect} />
                <Tasks_allTasks refresher={r => {
                    setRefreshers(oldR => {
                        return { ...oldR, "allTasks": r }
                    });
                }} modal={{ modalOpen, setModalOpen }}  search={search} onSelect={onSelect} />
                <Tasks_scratchpad />
                <Tasks_pinnedNotes modal={{ modalOpen, setModalOpen }} refresher={r => {
                    setRefreshers(oldR => {
                        return { ...oldR, "pinnedNotes": r }
                    });
                }} allRefreshers={refreshers}  search={search} onSelect={onSelect} />
                {modalOpen && <Tasks_ModalController runAnim={() => setAnimRunning(true)} onClose={() => {
                    setAnimRunning(false);
                    setModalOpen();
                    for (let item of Object.keys(refreshers)) refreshers[item]();
                }} search={search}>
                    {modalOpen}
                </Tasks_ModalController>}
            </div>

        </div>
    </div>
};

const Tasks_ModalController = (props) => {
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef?.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, delay: 100, fill: "both", easing: "ease" });
    }, [mainRef]);

    return <div className="components__tasks__wrap__right__modal" ref={mainRef}>
        <div className="components__tasks__close">
            <img src="/images/closeImg.svg" style={{ marginTop: 0 }} onClick={() => {
                if (!mainRef.current) return props.onClose();
                props.runAnim();
                mainRef.current.animate([
                    { right: getComputedStyle(mainRef.current).right },
                    { right: "-150%" }
                ], { duration: 300, iterations: 1, fill: "both", easing: "ease" }).onfinish = () => {
                    props.onClose();
                };
            }} />
        </div>
        {React.Children.map(props.children, child => {
            return React.cloneElement(child, {search: props.search});
        })}
    </div>
};

const Tasks_singleNote = props => {
    const [data, setData] = React.useState(props.data);
    const [spinner, setSpinner] = React.useState(false);
    const [wasChanged, setWasChanged] = React.useState(false);

    const onClose = (wasChangedForced = false) => {
        if (wasChanged || wasChangedForced) {
            if (props.onChange) props.onChange();
        };
        props.onClose();
    };

    const refreshData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/getAllNotes`,
            data: {
                pagination: 0,
                limit: 1,
                filters: [{name: "ID", op: "eq", value: data.ID}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) setData(res.data.data[0]);
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const changeState = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/setPinnedStatus`,
            data: {
                ID: data.ID,
                NewStatus: !data.isPinned
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setWasChanged(true);
            refreshData();
        });
    };

    const removeNote = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/removeNote`,
            data: {
                ID: data.ID
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            onClose(true);
        });
    };

    return <div className="components__tasks__singleNote">
        <div className="components__tasks__singleNote__wrap">
            <div className="components__tasks__singleNote__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" />
            </div>

            <div className="components__tasks__singleNote__wrap__close">
                {props.onSelect ? <CustomButtonSmall accent="#6664E5" theme="dark" value="Pošalji u chat" style={{height: "30px", width: "120px"}} onClick={() => {
                    props.onClose();
                    props.onSelect("note", {name: data.Name, description: data.Description});
                }} /> : <span></span>}
                <img src="/images/closeImg.svg" style={{ marginTop: 0 }} onClick={onClose} />
            </div>

            <div className="components__tasks__singleNote__wrap__btns">
                <div className="components__tasks__singleNote__wrap__btns__pin" onClick={changeState}>
                    <img src="/images/tasks_pinNote.svg" style={{
                        transform: data.isPinned ? "rotate(90deg)" : null,
                        marginRight: "15px"
                    }} />
                    <span>{data.isPinned ? "Otkači bilješku" : "Zakači bilješku"}</span>
                </div>
                <div className="components__tasks__singleNote__wrap__btns__del" onClick={removeNote}>
                    <span>Obriši</span>
                    <img src="/images/trashcan.svg" style={{
                        marginLeft: "15px"
                    }} />
                </div>
            </div>

            <h3>{data.Name}</h3>
            <p>{data.Description}</p>
        </div>
    </div>
};

const Tasks_allNotes = props => {
    const [data, setData] = React.useState();

    const truncateString = str => {
        str = String(str);
        if (str.length > 117) {
            return `${str.substring(0, 117)}...`;
        } else {
            return str
        };
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/getAllNotes`,
            data: {
                pagination: 0,
                limit: 10,
                orders: [{ name: "createdAt", order: "desc" }],
                filters: [
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        getData();
        if (typeof (props?.refresher) === "function") props.refresher(getData);
    }, [props.search]);

    return <div className="components__tasks__allNotes components__tasks__taskItem">
        <div className="components__tasks__taskItem__spinner" style={{
            opacity: data ? 0 : 1,
            pointerEvents: data ? "none" : "all"
        }}>
            <Spinner color="white" />
        </div>

        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left" style={{ cursor: "pointer" }} onClick={() => {
                props.modal.setModalOpen(<Tasks_allNotesExpanded onSelect={props.onSelect} />);
            }}>
                <p>Bilješke</p>
                <img src="/images/rightArrow.svg" />
            </div>
            <div className="components__tasks__taskItem__heading__right">
            </div>
        </div>

        <div className="components__tasks__allNotes__notes">
            {data && (data?.status !== "ok" ? <>
                <p>Došlo je do greške</p>
            </> : <>
                {data.data.length === 0 ? <p style={{ color: "#BEBEBE" }}>Nema zadataka za prikaz</p> : <div className="components__tasks__allNotes__notes__wrap">
                    {data.data.map(item => {
                        return <div className="components__tasks__allNotes__notes__wrap__note" onClick={e => {
                            animateBox(e, <Tasks_singleNote onSelect={props.onSelect} data={item} onChange={() => {
                                props.allRefreshers["allNotes"] && props.allRefreshers["allNotes"]();
                                props.allRefreshers["pinnedNotes"] && props.allRefreshers["pinnedNotes"]();
                            }} />);
                        }}>
                            <div>
                                <span>{new Date(item.createdAt).toLocaleDateString()}</span>
                                {item.isPinned && <img src="/images/tasks_pinNote.svg" />}
                            </div>
                            <h3>{item.Name}</h3>
                            <p>{truncateString(item.Description)}</p>
                        </div>
                    })}
                </div>}
            </>)}
        </div>
    </div>
};

const Tasks_allNotesExpanded = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [pagination, setPagination] = React.useState(false);

    const paginationRef = React.useRef();

    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/getAllNotes`,
            data: {
                limit: 20,
                orders: [
                    { name: "isPinned", order: "desc" },
                    { name: "createdAt", order: "desc" }
                ],
                filters: [
                    ...(props.filters ? props.filters : [null]),
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setPagination(true);
                };
                setData(res.data);
            } else {
                setData(backendModule.genericError)
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/getAllNotes`,
            data: {
                limit: 20,
                pagination: data.data.length,
                orders: [
                    { name: "isPinned", order: "desc" },
                    { name: "createdAt", order: "desc" }
                ],
                filters: [
                    ...(props.filters ? props.filters : [null]),
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(f => f)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPagination(false);
                if (res.data.data.length === 20) {
                    setPagination(true);
                };
                setData({
                    ...data,
                    data: [
                        ...data.data,
                        ...res.data.data
                    ]
                });
            } else {
                setPagination(false);
            };
        }).catch(() => {
            setPagination(false);
        });
    };

    React.useEffect(() => {
        if (!paginationRef.current) return;

        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    continueData();
                };
            });
        }, { threshold: [1] })

        observer.observe(paginationRef.current);

        return () => {
            try {
                observer.unobserve(paginationRef.current);
            } catch { };
        };
    }, [paginationRef]);

    React.useEffect(() => {
        getData();
        if (typeof (props?.refresher) === "function") props.refresher(getData);
    }, [props.search]);

    return <div className="components__tasks__allNotes components__tasks__taskItem">
        <div className="components__tasks__taskItem__spinner" style={{
            opacity: (!data || spinner) ? 1 : 0,
            pointerEvents: (!data || spinner) ? "all" : "none"
        }}>
            <Spinner color="white" />
        </div>

        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left">
                <p>Sve bilješke</p>
            </div>
            <div className="components__tasks__taskItem__heading__right">
                {(userFlagsSelector.isAdmin || userFlagsSelector.isCCManager) && <div onClick={e => {
                    animateBox(e, <Tasks_addNote onChange={getData} />)
                }} className="components__tasks__taskItem__heading__right__mask" style={{ mask: "url('/images/taskNote.svg')", WebkitMaskImage: "url('/images/taskNote.svg')", maskSize: "100% 100%", webkitMaskSize: "100% 100%" }}></div>}
            </div>
        </div>

        <div className="components__tasks__allNotes__notes">
            {data?.status !== "ok" ? <p>Došlo je do greške</p> : <>
                {data.data.length === 0 ? <p>Nema bilješki za prikaz</p> : <div className="components__tasks__allNotes__notes__wrapExpand">
                    {data.data.map(item => {
                        return <div className="components__tasks__allNotes__notes__wrapExpand__note" onClick={e => {
                            animateBox(e, <Tasks_singleNote onSelect={props.onSelect} data={item} onChange={getData} />)
                        }}>
                            <img className="components__tasks__allNotes__notes__wrapExpand__note__img" src="/images/tasks_pinNote.svg" style={{
                                filter: item.isPinned ? null : "invert(86%) sepia(42%) saturate(0%) hue-rotate(211deg) brightness(83%) contrast(87%) invert(0.7)",
                                transform: item.isPinned ? null : "rotate(90deg)"
                            }} />
                            <h3 className="components__tasks__allNotes__notes__wrapExpand__note__name">{item.Name}</h3>
                            <p className="components__tasks__allNotes__notes__wrapExpand__note__description">{item.Description}</p>
                            <p className="components__tasks__allNotes__notes__wrapExpand__note__date">{new Date(item.createdAt).toLocaleString()}</p>
                        </div>
                    })}
                    {pagination && <div className="components__tasks__allNotes__notes__wrapExpand">
                        <div ref={paginationRef} style={{ position: "absolute", bottom: "30px", left: 0, opacity: 1, width: "1px", height: "1px" }}></div>
                        <Spinner key="paginateSpinner" color="white" style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }} align="center" />
                    </div>}
                </div>}
            </>}
        </div>
    </div>
};

const Tasks_pinnedNotes = props => {
    const [data, setData] = React.useState();

    const truncateString = str => {
        str = String(str);
        if (str.length > 117) {
            return `${str.substring(0, 117)}...`;
        } else {
            return str
        };
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/getAllNotes`,
            data: {
                pagination: 0,
                limit: 10,
                orders: [{ name: "createdAt", order: "desc" }],
                filters: [
                    {name: "isPinned", op: "eq", value: true},
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
        if (typeof (props?.refresher) === "function") props.refresher(getData);
    }, [props.search]);

    return <div className="components__tasks__pinnedNotes components__tasks__taskItem">
        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left" style={{ cursor: "pointer" }} onClick={() => {
                props.modal.setModalOpen(<Tasks_allNotesExpanded onSelect={props.onSelect} filters={[{name: "isPinned", op: "eq", value: true}]} />);
            }}>
                <p>Zakačene bilješke</p>
                <img src="/images/rightArrow.svg" />
            </div>
            <div className="components__tasks__taskItem__heading__right">

            </div>
        </div>

        <div className="components__tasks__allNotes__notes">
            {data && (data?.status !== "ok" ? <>
                <p>Došlo je do greške</p>
            </> : <>
                {data.data.length === 0 ? <p style={{ color: "#BEBEBE" }}>Nema zadataka za prikaz</p> : <div className="components__tasks__allNotes__notes__wrap">
                    {data.data.map(item => {
                        return <div className="components__tasks__allNotes__notes__wrap__note" onClick={e => {
                            animateBox(e, <Tasks_singleNote onSelect={props.onSelect} data={item} onChange={() => {
                                props.allRefreshers["allNotes"] && props.allRefreshers["allNotes"]();
                                props.allRefreshers["pinnedNotes"] && props.allRefreshers["pinnedNotes"]();
                            }} />);
                        }}>
                            <div>
                                <span>{new Date(item.createdAt).toLocaleDateString()}</span>
                                {item.isPinned && <img src="/images/tasks_pinNote.svg" />}
                            </div>
                            <h3>{item.Name}</h3>
                            <p>{truncateString(item.Description)}</p>
                        </div>
                    })}
                </div>}
            </>)}
        </div>
    </div>
};

const Tasks_allTasksExpanded = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [pagination, setPagination] = React.useState(false);

    const paginationRef = React.useRef();
    const taskListRef = React.useRef();

    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    React.useEffect(() => {
        const handler = task => {
            task = backendModule.parseData(task);
            if (data?.status !== "ok") return;
            let tmp = data.data.find(t => t.ID === task.ID);
            if (tmp) {
                let idx = data.data.indexOf(tmp);
                setData({
                    ...data,
                    data: [
                        ...data.data.filter((_, tIdx) => tIdx < idx),
                        task?.isDeleted ? null : task,
                        ...data.data.filter((_, tIdx) => tIdx > idx)
                    ].filter(f => f)
                });
            };
        };

        socketOn("pingTaskChanges", handler);

        return () => socketOff("pingTaskChanges", handler);
    }, [data]);

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: 20,
                orders: [
                    { name: "isCompleted", order: "asc" },
                    { name: "DueDate", order: "asc" }
                ],
                filters: [
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setPagination(true);
                };
                setData(res.data);
            } else {
                setData(backendModule.genericError)
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: 20,
                pagination: data.data.length,
                orders: [
                    { name: "isCompleted", order: "asc" },
                    { name: "DueDate", order: "asc" }
                ],
                filters: [
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setPagination(false);
                if (res.data.data.length === 20) {
                    setPagination(true);
                };
                setData({
                    ...data,
                    data: [
                        ...data.data,
                        ...res.data.data
                    ]
                });
            } else {
                setPagination(false);
            };
        }).catch(() => {
            setPagination(false);
        });
    };

    React.useEffect(() => {
        if (!paginationRef.current) return;

        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    continueData();
                };
            });
        }, { threshold: [1] })

        observer.observe(paginationRef.current);

        return () => {
            try {
                observer.unobserve(paginationRef.current);
            } catch { };
        };
    }, [paginationRef.current, pagination, data]);

    React.useEffect(() => {
        getData();
        if (typeof (props?.refresher) === "function") props.refresher(getData);
    }, [props.search]);

    return <div className="components__tasks__allTasks components__tasks__taskItem">
        <div className="components__tasks__taskItem__spinner" style={{
            opacity: (!data || spinner) ? 1 : 0,
            pointerEvents: (!data || spinner) ? "all" : "none"
        }}>
            <Spinner color="white" />
        </div>

        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left">
                <p>Svi zadaci</p>
            </div>
            <div className="components__tasks__taskItem__heading__right">
                {(userFlagsSelector.isAdmin || userFlagsSelector.isCCManager) && <div onClick={e => {
                    animateBox(e, <Tasks_addTask onChange={getData} />)
                }} className="components__tasks__taskItem__heading__right__mask" style={{ mask: "url('/images/taskAdd.svg')", WebkitMaskImage: "url('/images/taskAdd.svg')", maskSize: "100% 100%", webkitMaskSize: "100% 100%" }}></div>}
            </div>
        </div>

        <div className="components__tasks__allTasks__tasks components__tasks__allTasks__tasks--expanded" ref={taskListRef}>
            {data && (data?.status !== "ok" ? <>
                <p>Došlo je do greške</p>
            </> : <>
                {data.data.length === 0 ? <p style={{ color: "#BEBEBE" }}>Nema zadataka za prikaz</p> : <div className="components__tasks__allTasks__tasks__wrap components__tasks__allTasks__tasks__wrap--expand">
                    {data.data.map(item => {
                        return <div className={`components__tasks__allTasks__tasks__wrap__task ${item.isCompleted ? "components__tasks__allTasks__tasks__wrap__task--finished" : ""}`} onClick={e => {
                            animateBox(e, <Tasks_singleTask onSelect={props.onSelect} data={item} />);
                        }}>
                            <div className="components__tasks__allTasks__tasks__wrap__task__dot" style={{
                                backgroundImage: `url(${item.isCompleted ? "/images/task_checked.svg" : ""})`
                            }}></div>
                            <p className="components__tasks__allTasks__tasks__wrap__task__name">{item.Name}</p>
                            <p className={`components__tasks__allTasks__tasks__wrap__task__date ${(!item.isCompleted && moment().isAfter(moment(item.DueDate))) ? "components__tasks__allTasks__tasks__wrap__task__date--due" : ""}`}>
                                <img src="/images/task_setDueDate.svg" />
                                <span>{new Date(item.DueDate).toLocaleString()}</span>
                            </p>
                        </div>
                    })}
                    {pagination && <div className="components__tasks__allTasks__tasks__wrap components__tasks__allTasks__tasks__wrap--expand">
                        <div ref={paginationRef} style={{ position: "absolute", bottom: "30px", left: 0, opacity: 1, width: "1px", height: "1px" }}></div>
                        <Spinner key="paginateSpinner" color="white" style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }} align="center" />
                    </div>}
                </div>}
            </>)}
        </div>
    </div>
};

const Tasks_allTasks = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    const getData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: 4,
                filters: [
                    { name: "isCompleted", op: "eq", value: false },
                    (props.search ? {name: "Name", op: "like", value: props.search} : null)
                ].filter(t => t),
                orders: [
                    { name: "DueDate", order: "asc" }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData({ ...res.data, timestamp: Date.now() });
            } else {
                setData(backendModule.genericError)
            };
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getData();
        if (typeof (props?.refresher) === "function") props.refresher(getData);
    }, [props.search]);

    React.useEffect(() => {
        const handler = task => {
            task = backendModule.parseData(task);
            if (data?.status !== "ok") return;
            let tmp = data.data.find(t => t.ID === task.ID);
            if (tmp) {
                getData();
            };
        };

        socketOn("pingTaskChanges", handler);

        return () => socketOff("pingTaskChanges", handler);
    }, [data?.timestamp]);

    return <div className="components__tasks__allTasks components__tasks__taskItem">
        <div className="components__tasks__taskItem__spinner" style={{
            opacity: (!data || spinner) ? 1 : 0,
            pointerEvents: (!data || spinner) ? "all" : "none"
        }}>
            <Spinner color="white" />
        </div>

        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left" style={{ cursor: "pointer" }} onClick={() => {
                props.modal.setModalOpen(<Tasks_allTasksExpanded onSelect={props.onSelect} />);
            }}>
                <p>Zadaci</p>
                <img src="/images/rightArrow.svg" />
            </div>
            <div className="components__tasks__taskItem__heading__right">
                {(userFlagsSelector.isAdmin || userFlagsSelector.isCCManager) && <div onClick={e => {
                    animateBox(e, <Tasks_addTask onChange={getData} />)
                }} className="components__tasks__taskItem__heading__right__mask" style={{ mask: "url('/images/taskAdd.svg')", WebkitMaskImage: "url('/images/taskAdd.svg')", maskSize: "100% 100%", webkitMaskSize: "100% 100%"}}></div>}
            </div>
        </div>

        <div className="components__tasks__allTasks__tasks">
            {data && (data?.status !== "ok" ? <>
                <p>Došlo je do greške</p>
            </> : <>
                {data.data.length === 0 ? <p style={{ color: "#BEBEBE" }}>Nema zadataka za prikaz</p> : <div className="components__tasks__allTasks__tasks__wrap">
                    {data.data.map(item => {
                        return <div className={`components__tasks__allTasks__tasks__wrap__task ${item.isCompleted ? "components__tasks__allTasks__tasks__wrap__task--finished" : ""}`} onClick={e => {
                            animateBox(e, <Tasks_singleTask onSelect={props.onSelect} data={item} />);
                        }}>
                            <div className="components__tasks__allTasks__tasks__wrap__task__dot" style={{
                                backgroundImage: `url(${item.isCompleted ? "/images/task_checked.svg" : ""})`
                            }}></div>
                            <p className="components__tasks__allTasks__tasks__wrap__task__name">{item.Name}</p>
                            <p className={`components__tasks__allTasks__tasks__wrap__task__date ${(!item.isCompleted && moment().isAfter(moment(item.DueDate))) ? "components__tasks__allTasks__tasks__wrap__task__date--due" : ""}`}>
                                <img src="/images/task_setDueDate.svg" />
                                <span>{new Date(item.DueDate).toLocaleString()}</span>
                            </p>
                        </div>
                    })}
                </div>}
            </>)}
        </div>
    </div>
};

export const Tasks_singleTask = props => {
    const [data, setData] = React.useState(props.data);
    const [subTasks, setSubTasks] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const chatUsersSelector = useSelector(state => state?.chatUsers ?? []);
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? null);

    const onClose = () => {
        props.onClose().then(() => {
            if (props.onFinish) props.onFinish();
        });
    };

    const getSubTasks = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: undefined,
                pagination: 0,
                filters: [
                    { name: "TaskID", op: "eq", value: data.ID }
                ],
                orders: [{name: "isCompleted", order: "asc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSubTasks(res.data);
            } else {
                setSubTasks(backendModule.genericError);
            };
        }).catch(() => {
            setSubTasks(backendModule.genericError);
        });
    };

    const refreshData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: 1,
                filters: [
                    { name: "ID", op: "eq", value: data.ID }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    setData(res.data.data[0]);
                    getSubTasks();
                };
            };
        }).finally(() => {
            setSpinner(false);
        });
    };

    const removeTask = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/removeTask`,
            data: {
                ID: data.ID
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            onClose();
        });
    };

    const setStatus = (status) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/setTaskStatus`,
            data: {
                ID: data.ID,
                NewStatus: status
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        const handler = task => {
            task = backendModule.parseData(task);
            if (data.ID === task.ID && !task?.isDeleted) {
                setData(task);
                getSubTasks();
            } else if (data.ID === task.TaskID) {
                setSubTasks(st => {
                    if (st?.status !== "ok") return st;
                    let id = st.data.indexOf(st.data.find(stask => stask.ID === task.ID));
                    if (!id) id = 0;
                    return {
                        ...st,
                        data: [
                            ...st.data.filter((_, idx) => idx < id),
                            task,
                            ...st.data.filter((_, idx) => idx > id)
                        ]
                    };
                });
            };
        };

        socketOn("pingTaskChanges", handler);
        getSubTasks();

        return () => socketOff("pingTaskChanges", handler);
    }, []);

    return <div className="components__tasks__singleTask">
        <div className="components__tasks__singleTask__wrap">
            <div className="components__tasks__singleTask__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" />
            </div>

            <div className="components__tasks__singleTask__wrap__close">
                {props.onSelect ? <CustomButtonSmall style={{width: "120px", height: "30px"}} value="Pošalji u chat" accent="rgb(102, 100, 229)" theme="dark" onClick={() => {
                    props.onClose();
                    props.onSelect("task", data.ID);
                }} /> : <span></span>}
                <img src="/images/closeImg.svg" style={{ marginTop: 0 }} onClick={onClose} />
            </div>

            <div className="components__tasks__singleTask__wrap__head">
                <p className="components__tasks__singleTask__wrap__head__text">{data.isCompleted ? "Završen" : "U toku"}</p>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {(data.UserID === userSelector?.ID || userSelector?.Flags?.isAdmin === true) && <div className="components__tasks__singleTask__wrap__head__action" style={{
                        marginRight: "20px"
                    }} onClick={removeTask}>
                        <img src="/images/trashcan.svg" />
                        <span>Obriši</span>
                    </div>}
                    {data.isCompleted ? <div className="components__tasks__singleTask__wrap__head__action" onClick={() => setStatus(false)}>
                        <img src="/images/task_checkDots.svg" />
                        <span>Označi kao u toku</span>
                    </div> : <div className="components__tasks__singleTask__wrap__head__action" onClick={() => setStatus(true)}>
                        <img src="/images/task_checked.svg" />
                        <span>Označi kao završen</span>
                    </div>}
                </div>
            </div>

            <div className="components__tasks__singleTask__wrap__dates">
                <div className={`components__tasks__singleTask__wrap__dates__wrap ${(!data.isCompleted && moment(data.DueDate).isBefore(moment())) ? "components__tasks__singleTask__wrap__dates__wrap--expired" : ""}`}>
                    <img src="/images/task_setDueDate.svg" style={{ marginRight: "15px" }} />
                    <span>{new Date(data.DueDate).toLocaleString()}</span>
                </div>
                {data.Reminder && <div className="components__tasks__singleTask__wrap__dates__wrap">
                    <span>{new Date(data.Reminder).toLocaleString()}</span>
                    <img src="/images/task_addReminder.svg" style={{ marginLeft: "15px" }} />
                </div>}
            </div>

            <p className="components__tasks__singleTask__wrap__name">
                {data.Name}
            </p>
            <p className="components__tasks__singleTask__wrap__description">
                {data.Description}
            </p>

            {data.isCompleted && <div className="components__tasks__singleTask__wrap__assignedUsers">
                <p className="components__tasks__singleTask__wrap__assignedUsers__text">Zadatak završio/la</p>
                <div className="components__tasks__singleTask__wrap__assignedUsers__wrap">
                    <div className="components__tasks__singleTask__wrap__assignedUsers__wrap__usr">
                        {(() => {
                            let curUsr = chatUsersSelector.find(cu => cu.ID === data.CompletedBy);

                            return <>
                                <img src={curUsr?.Image ?? "/images/undefined.png"} style={{ marginRight: "15px" }} />
                                <span>{curUsr?.FirstName ?? "?"} {curUsr?.LastName ?? "?"}</span>
                            </>
                        })()}
                    </div>

                    <div className={`components__tasks__singleTask__wrap__assignedUsers__wrap__date`}>
                        <span>{new Date(data.CompletedDate).toLocaleString()}</span>
                        <img src="/images/task_setDueDate.svg" style={{ marginLeft: "15px" }} />
                    </div>
                </div>
            </div>}

            <div className="components__tasks__singleTask__wrap__assignedUsers">
                <div className="components__tasks__singleTask__wrap__assignedUsers__text">Zadatak kreirao</div>
                <div className="components__tasks__singleTask__wrap__assignedUsers__users">
                    {(() => {
                        let curUser = chatUsersSelector.find(u => u.ID === data.UserID);
                        return <div className="components__tasks__singleTask__wrap__assignedUsers__users__user">
                            <img src={curUser?.Image ?? "/images/undefined.png"} />
                            <span>{`${curUser?.FirstName ?? "?"} ${curUser?.LastName ?? "?"}`}</span>
                        </div>
                    })()}
                </div>
            </div>

            <div className="components__tasks__singleTask__wrap__assignedUsers">
                <p className="components__tasks__singleTask__wrap__assignedUsers__text">Zadužene osobe</p>
                <div className="components__tasks__singleTask__wrap__assignedUsers__users">
                    {(() => {
                        let usrs = [];
                        try {
                            usrs = JSON.parse(data.AssignedUsers);
                            usrs = usrs.map(u => {
                                let tmp = u.split(":");
                                tmp.shift();
                                return Number(tmp.join(":"));
                            });
                        } catch {
                            usrs = [];
                        };

                        return usrs.map(usr => {
                            let curUser = chatUsersSelector.find(u => u.ID === usr);
                            return <div className="components__tasks__singleTask__wrap__assignedUsers__users__user">
                                <img src={curUser?.Image ?? "/images/undefined.png"} />
                                <span>{`${curUser?.FirstName ?? "?"} ${curUser?.LastName ?? "?"}`}</span>
                            </div>
                        })
                    })()}
                </div>
            </div>

            <div className="components__tasks__singleTask__wrap__subtasks">
                <p className="components__tasks__singleTask__wrap__subtasks__text">
                    <span>Podzadaci {subTasks?.status === "ok" && `(${subTasks.data.length})`}</span>
                    <span className="components__tasks__singleTask__wrap__subtasks__text__addSubtask" onClick={e => {
                        animateBox(e, <Tasks_addTask TaskID={data.ID} onFinish={() => refreshData()} />);
                    }}>
                        <img src="/images/taskAdd.svg" />
                        <span>Dodaj podzadatak</span>
                    </span>
                </p>
                {!subTasks ? <Spinner color="white" style={{width: "16px", height: "16px"}} /> : <>
                    {subTasks.status === "error" ? <p className="components__tasks__singleTask__wrap__subtasks__textErr">Došlo je do greške prilikom dohvatanja podzadataka</p> : <div className="components__tasks__singleTask__wrap__subtasks__tasks">
                        {subTasks.data.map(item => {
                            return <div className={`components__tasks__allTasks__tasks__wrap__task ${item.isCompleted ? "components__tasks__allTasks__tasks__wrap__task--finished" : ""}`} onClick={e => {
                                animateBox(e, <Tasks_singleTask data={item} onSelect={props.onSelect ? (...selData) => {
                                    props.onClose();
                                    props.onSelect(...selData);
                                } : null} />);
                            }}>
                                <div className="components__tasks__allTasks__tasks__wrap__task__dot" style={{
                                    backgroundImage: `url(${item.isCompleted ? "/images/task_checked.svg" : ""})`
                                }}></div>
                                <p className="components__tasks__allTasks__tasks__wrap__task__name">{item.Name}</p>
                                <p className={`components__tasks__allTasks__tasks__wrap__task__date ${(!item.isCompleted && moment().isAfter(moment(item.DueDate))) ? "components__tasks__allTasks__tasks__wrap__task__date--due" : ""}`}>
                                    <img src="/images/task_setDueDate.svg" />
                                    <span>{new Date(item.DueDate).toLocaleString()}</span>
                                </p>
                            </div>
                        })}
                    </div>}
                </>}
            </div>
        </div>
    </div>
};

const Tasks_addNote = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();
    const descRef = React.useRef();

    const saveData = () => {
        setInfoP();
        let data = {
            Name: nameRef.current.value,
            Description: descRef.current.value
        };
        if (!data.Name) return setInfoP("Naziv ne može biti prazan");
        if (!data.Description) return setInfoP("Tekst ne može biti prazan");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/notes/addNote`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="components__tasks__scratchpad__addNote">
        <div className="components__tasks__scratchpad__addNote__wrap">
            <div className="components__tasks__scratchpad__addNote__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" />
            </div>

            <div className="components__tasks__scratchpad__addNote__wrap__close">
                <img src="/images/closeImg.svg" style={{ marginTop: 0 }} onClick={props.onClose} />
            </div>

            <CustomInput ref={nameRef} accent="rgb(190, 190, 190)" theme="dark" placeholder="Naziv bilješke" />
            <textarea ref={descRef} placeholder="Tekst..." defaultValue={props.data ?? ""}></textarea>

            <div className="components__tasks__scratchpad__addNote__wrap__buttons">
                <CustomButtonSmall theme="dark" value="Izlaz" accent="#575B5E" style={{ marginRight: "20px" }} onClick={props.onClose} />
                <CustomButtonSmall theme="dark" value="Kreiraj" accent="#0D99FF" onClick={saveData} />
            </div>
            <p style={{
                color: "#ff9696",
                marginTop: "10px"
            }}>{infoP}</p>
        </div>
    </div>
};

const Tasks_scratchpad = props => {
    const mainRef = React.useRef();

    return <div className="components__tasks__scratchpad components__tasks__taskItem" ref={mainRef}>

        <div className="components__tasks__taskItem__heading">
            <div className="components__tasks__taskItem__heading__left">
                <p>Scratchpad</p>
            </div>
            <div className="components__tasks__taskItem__heading__right">
                <div className="components__tasks__taskItem__heading__right__dots" onClick={e => {
                    e.currentTarget.parentElement.querySelector(".components__tasks__taskItem__heading__right__dotsItems").classList.toggle("active");
                }}>&bull;&bull;&bull;</div>
                <div className="components__tasks__taskItem__heading__right__dotsItems">
                    <div onClick={(e) => {
                        let text = window.sessionStorage.getItem("scalecrm_scratchpad");
                        if (!text) {
                            mainRef.current.querySelector(".components__tasks__taskItem__heading__right__dotsItems").classList.toggle("active");
                        } else {
                            animateBox(e, <Tasks_addNote data={text} onChange={() => {
                                window.sessionStorage.setItem("scalecrm_scratchpad", "");
                                mainRef.current.querySelector("textarea").value = "";
                                mainRef.current.querySelector(".components__tasks__taskItem__heading__right__dotsItems").classList.toggle("active");
                                if (props.onRefresh) props.onRefresh();
                            }} />);
                        };
                    }}>Pretvori u bilješku</div>
                    <div onClick={e => {
                        window.sessionStorage.setItem("scalecrm_scratchpad", "");
                        mainRef.current.querySelector("textarea").value = "";
                        mainRef.current.querySelector(".components__tasks__taskItem__heading__right__dotsItems").classList.toggle("active");
                    }}>Očisti scratchpad</div>
                </div>
            </div>
        </div>

        <textarea defaultValue={window.sessionStorage.getItem("scalecrm_scratchpad") ?? ""} onChange={e => window.sessionStorage.setItem("scalecrm_scratchpad", e.currentTarget.value)} placeholder="Unesite tekst...." className="components__tasks__scratchpad__text"></textarea>
    </div>
};

const Tasks_addTask = props => {
    const [reminder, setReminder] = React.useState();
    const [dueDate, setDueDate] = React.useState();
    const [assignedUsers, setAssignedUsers] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();
    const descRef = React.useRef();

    const addTask = () => {
        setInfoP();

        let data = {
            Name: nameRef.current.value,
            Description: descRef.current.value,
            AssignedUsers: assignedUsers,
            TaskID: props.TaskID ? props.TaskID : null
        };

        if (!data.Name) return setInfoP("Naziv ne može biti prazan");
        if (!data.Description) return setInfoP("Opis ne može biti prazan");

        if (!dueDate) return setInfoP("Krajnji datum nije unešen");
        if (!moment(dueDate).isValid()) return setInfoP("Krajnji datum nije validan");
        data.DueDate = moment(dueDate).toDate();

        if (reminder && moment(reminder).isValid()) data.Reminder = moment(reminder).toDate();

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/addTask`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange && props.onChange();
                props.onClose();
            } else {
                setInfoP("Došlo je do greške prilikom kreiranja zadatka");
            }
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const DatetimeSelect = (props2) => {
        let curDate = props2.data ? moment(props2.data) : moment();
        if (!curDate.isValid()) curDate = moment();

        const dateRef = React.useRef();
        const timeRef = React.useRef();

        return <div className="components__tasks__addTask__dateTimeSelect">
            <div>
                <CustomInput ref={dateRef} type="date" placeholder="Datum" theme="dark" accent="#BEBEBE" defaultValue={curDate.format("YYYY-MM-DD")} />
                <CustomInput ref={timeRef} type="time" placeholder="Vrijeme" theme="dark" accent="#BEBEBE" defaultValue={curDate.format("HH:mm")} />
            </div>
            <div className="components__tasks__addTask__buttons">
                <CustomButtonSmall theme="dark" value="Izlaz" accent="#575B5E" style={{ marginRight: "20px" }} onClick={props2.onClose} />
                <CustomButtonSmall theme="dark" value="Kreiraj" accent="#0D99FF" onClick={() => {
                    props2.onChange(`${dateRef.current.value} ${timeRef.current.value}`);
                    props2.onClose();
                }} />
            </div>
        </div>
    };

    const SetAssignedUsers = (props2) => {
        const [selectedUsers, setSelectedUsers] = React.useState(props2.data);
        const [curUser, setCurUser] = React.useState();

        const chatUsersSelector = useSelector(state => state.chatUsers ?? []);

        return <div className="components__tasks__addTask--wrap">
            <div className="components__tasks__addTask__assignedUsers">
                <div className="components__tasks__addTask__assignedUsers__dropdown">
                    <Dropdown data={chatUsersSelector.map(cu => {
                        return { name: `(${cu.ID}) ${cu.FirstName} ${cu.LastName}`, value: cu.ID }
                    })} theme="dark" accent="#BEBEBE" onChange={e => setCurUser(e?.value)} />
                    <CustomButtonSmall value="Dodaj" theme="dark" accent="#0D99FF" onClick={() => {
                        if (!curUser) return;
                        if (!selectedUsers.includes(curUser)) setSelectedUsers([...selectedUsers, curUser]);
                        setCurUser();
                    }} />
                </div>

                <div className="components__tasks__addTask__assignedUsers__users">
                    {selectedUsers.map(usr => {
                        let curUsr = chatUsersSelector.find(t => t.ID === usr);
                        return <p className="components__tasks__addTask__assignedUsers__users__user">
                            <img src="/images/closeImg.svg" onClick={() => {
                                setSelectedUsers(selectedUsers.filter(u => u !== usr));
                            }} />
                            <img src={curUsr.Image ? curUsr.Image : "/images/undefined.png"} />
                            <span>{`(${curUsr.ID}) ${curUsr.FirstName} ${curUsr.LastName}`}</span>
                        </p>
                    })}
                </div>

                <div className="components__tasks__addTask__buttons" style={{ marginTop: "20px" }}>
                    <CustomButtonSmall theme="dark" value="Izlaz" accent="#575B5E" style={{ marginRight: "20px" }} onClick={props2.onClose} />
                    <CustomButtonSmall theme="dark" value="Spremi" accent="#0D99FF" onClick={() => {
                        props2.onChange(selectedUsers);
                        props2.onClose();
                    }} />
                </div>
            </div>
        </div>
    };

    return <div className="components__tasks__addTask">
        <div className="components__tasks__addTask__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="white" />
        </div>
        <div className="components__tasks__addTask__header">
            <h2>Novi zadatak</h2>
            <img src="/images/closeImg.svg" onClick={props.onClose} />
        </div>
        <CustomInput ref={nameRef} type="text" placeholder="Naziv" theme="dark" accent="#BEBEBE" />
        <textarea ref={descRef} className="components__tasks__addTask__text" placeholder="Opis"></textarea>

        <div className="components__tasks__addTask__input" onClick={e => {
            animateBox(e, <DatetimeSelect data={dueDate} onChange={newDate => setDueDate(newDate)} />);
        }}>
            <img src="/images/task_setDueDate.svg" />
            <p>Postavi krajnji datum {dueDate && `(${new Date(dueDate).toLocaleString()})`}</p>
        </div>
        <div className="components__tasks__addTask__input" onClick={e => {
            animateBox(e, <DatetimeSelect data={reminder} onChange={newDate => setReminder(newDate)} />);
        }}>
            <img src="/images/task_addReminder.svg" />
            <p>Postavi podsjetnik {reminder && `(${new Date(reminder).toLocaleString()})`}</p>
        </div>
        <div className="components__tasks__addTask__input" onClick={e => {
            animateBox(e, <SetAssignedUsers data={assignedUsers} onChange={newUsers => setAssignedUsers(newUsers)} />);
        }}>
            <img src="/images/task_assignUser.svg" />
            <p>Zaduži korisnike ({assignedUsers.length})</p>
        </div>

        <div className="components__tasks__addTask__buttons">
            <CustomButtonSmall theme="dark" value="Izlaz" accent="#575B5E" style={{ marginRight: "20px" }} onClick={props.onClose} />
            <CustomButtonSmall theme="dark" value="Kreiraj" accent="#0D99FF" onClick={addTask} />
        </div>
        <p style={{ color: "#ff7272", paddingTop: "10px" }}>{infoP}</p>
    </div>
};

export default Tasks;