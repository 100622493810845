import * as notificationsModule from "./notificationsModule";
import * as socketModule from "./socketModule";

/** @type {Object<number, {state: string, time: number}>} */
const data = {};
let isRunning = false;
let curTimeout = null;

const timeoutHandler = () => {
    if (!isRunning) return;

    for (let item of Object.keys(data)) {
        if (data[item].state === "CallCentar - ima lead" || data[item].state === "Lead - neaktivan") {
            data[item].time += 2;
        };

        if (data[item].time === 62 && !data[item].sent) {
            data[item].sent = true;
            notificationsModule.sendInactivityNotification(+item);
        };
    };

    curTimeout = setTimeout(timeoutHandler, 2000);
};

const handler = item => {
    if (data[item.ID]) {
        if (data[item.ID].state === item.status) return;
    };

    data[item.ID] = {
        state: item.status,
        time: 0,
        sent: false
    };
};

export const init = () => {
    socketModule.on("checkAgentActivity", handler);

    isRunning = true;
    timeoutHandler();
};
export const close = () => {
    socketModule.off("checkAgentActivity", handler);

    isRunning = false;
    clearTimeout(curTimeout);
};