/** @type {Worker} */
let worker = null;

export const startWorker = (wakeupCallback) => {
    worker = new Worker(new URL("./worker.js", import.meta.url));
    worker.onmessage = function (msg) {
        if (msg && msg.data === "wakeup") wakeupCallback();
    };
    return worker;
};

export const stopWorker = () => {
    try {
        worker.terminate()
    } catch {};
};