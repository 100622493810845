import React from "react";
import "./index.scss";
import "../../../components/customComponents/Kanban/index.scss";

import { useSelector } from "react-redux";
import { headerColors } from "../../../modules/leadModule";
import { getParamsFromURLObject } from "../../../modules/urlModule";

import { Kanban } from "../../../components/customComponents/Kanban";

const Modals_ViewLeads = props => {
    const [leadIDs, setLeadIDs] = React.useState();

    React.useEffect(() => {
        let curParams = getParamsFromURLObject(String(window.location));
        if (curParams?.ids) {
            let paramList = curParams.ids.split(",");
            setLeadIDs(paramList);
        };
    }, []);

    return <div className="route__modals__viewLeads">
        {leadIDs ? <LeadKanban leads={leadIDs} /> : <p className="route__modals__viewLeads__modal">ID-ovi leadova nisu proslijeđeni!</p>}
    </div>
};

const LeadKanban = (props) => {
    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    return <div style={{
        all: "initial",
        width: '100%',
        height: '100%',
        display: "grid",
        gridTemplateRows: "max-content",
        gridTemplateColumns: "1fr"
    }}>
        <Kanban
            type='lead'
            headersColors={headerColors("lead")}
            customFilters={[{
                name: "ID",
                op: "in",
                value: props.leads
            }]}
            agentMode={(userFlagsSelector?.isAdmin || userFlagsSelector?.isCCManager) ? false : true}
        /></div>;
};

export default Modals_ViewLeads;