import React from "react";
import "./naljepniceX.scss";
import { useParams } from "react-router-dom";
import { setAcceptIncomming } from "../../../components/SIPJS";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import * as urlModule from "../../../modules/urlModule";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Spinner from "../../../components/customComponents/Spinner";
import Articles from "../../AdminPanel/Articles";

const GenerateLabelsX = () => {
    const [spinner, setSpinner] = React.useState(true);
    const [data, setData] = React.useState();
    const idsRef = React.useRef();
    const [searchParams] = useSearchParams();
    const ids = searchParams.get('IDs');
    const targetRef = React.useRef();





    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getDataForShipmentLabels`,
            data: {
                deals: ids.split(","),
            },
            ...backendModule.axiosConfig,
        }).then((res) => {
            if (res.data.status === "ok") {
                let tmp = res.data.data;
                tmp.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
                setData({ status: "ok", data: tmp });
            } else {
                setData({ status: "error", data: "DB_ERROR" });
            }
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!ids) return;
        getData();
    }, [ids]);

    React.useEffect(() => {
        const rootImg = document.querySelector("#rootImg");
        if (rootImg) rootImg.remove();
        setAcceptIncomming(false);
    }, []);

    return (
        <div className="route__kreirajNaljepniceX" ref={targetRef}>
            {spinner && <Spinner />}
            {(() => {
                let final = [];
                if (!data) return null;
                for (let i = 0; i <= data.data.length - 1; i += 60) {
                    final.push(
                        <div className="route__kreirajNaljepniceX__wrap" style={{
                            gridTemplateColumns: "repeat(15, 6,6666666667%)",
                        }} key={i}>
                            {data.data.slice(i, i + 60).map((item, index) => (
                                <Naljepnica key={index} data={item} />
                            ))}
                        </div>
                    );
                }
                return final;
            })()}
        </div >
    );
};

const Naljepnica = ({ data }) => {
    const barcodeRef = React.useRef();
    const generatedRef = React.useRef(false);
    const [finalCode, setFinalCode] = React.useState();

    const fillData = (text) => {
        text = String(text);
        while (text.length < 10) text = "0" + text;
        return text;
    };

    React.useEffect(() => {
        if (!data || !barcodeRef.current || generatedRef.current) return;
        let finalCodeTmp = String(data.DealID).padStart(7, '0');
        finalCodeTmp = `000${finalCodeTmp}`;
        setFinalCode(finalCodeTmp);

        let code = new window.Code128(`BA${fillData(finalCodeTmp)}`);
        code.insert(barcodeRef.current);
        generatedRef.current = true;
    }, [data]);

    return (
        <div className="route__kreirajNaljepniceX__naljepnica" style={{
            display:"flex",
            alignItems: "center",
            flexDirection:"column"
        }}>
            <div ref={barcodeRef} className="route__kreirajNaljepniceX__naljepnica__left__barcode"></div>
            <div className="route__kreirajNaljepniceX__naljepnica__left__barcodeBottom">BA{finalCode}</div>
                <p>Ref: {data.ID}</p>
                <p>CRM: {data.DealID}</p>
                <p>{data.BuyerName}</p>
                <span>Cijena</span>
                <span>{data.ProductPayout} KM</span>
        </div>
    );
};

export default GenerateLabelsX;
