export const updateBackendTasks = (newTasks) => {
    return {
        type: "BACKNED_TASKS_UPDATE",
        payload: newTasks
    };
};

export const removeStaleBackendTasks = () => {
    return {
        type: "BACKEND_TASKS_REMOVE_STALE"
    };
};