import React, { useCallback } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { on as socketOn, off as socketOff } from "../../modules/socketModule";
import { useSelector } from "react-redux";
import { getDealStatusColor2, getLeadStatusColor2, getDealShipColor, getDealSaveShipColor, getUnpaidRemindersStatusColor } from "../../modules/leadModule";
import moment from "moment";
import * as socketModule from "../../modules/socketModule";

import AudioPlayer from "../customComponents/AudioPlayer";
import CustomButtonSmall from "../customComponents/Button";
import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import Dropdown from "../customComponents/Dropdown";
import { FilteredCustomTable } from "../customComponents/Table";

import DealShipmentHistory from "../../components/DealShipmentHistory";
import * as MessageTypes from "../Chat/Content/MessageTypes";

const SendMessage = (props) => {
    const chatRoomsSelector = useSelector(state => state.chatRooms ?? []);
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {})
    const curChatsSelector = useSelector(state => state.chats ?? []);
    const [addChatActive, setAddChatActive] = React.useState(false);
    const [activeChat, setActiveChat] = React.useState();
    const [search, setSearch] = React.useState("");
    const generateChatRoomName = IDs => {
        let tmp = IDs.filter(ID => ID !== curUserSelector.ID).map(ID => {
            let found = chatUsersSelector.find(cu => cu.ID === ID);
            if (found) {
                if (IDs.length > 2) {
                    return found.FirstName;
                } else {
                    return `${found.FirstName} ${found.LastName}`;
                };
            }
            return "?";
        });
        if (tmp.length === 0) return <span style={{ color: "gray" }}>[Nema sudionika]</span>;

        let final = tmp.join(", ");
        if (final.length >= 50) final.substring(0, 47);

        return final;
    };

    const getUserImage = IDs => {
        if (IDs.find(ID => ID < 0)) return "/images/robo.png";
        if (IDs.length > 2) return "/images/chat_userGroup.svg";

        let curID = IDs.find(ID => ID !== curUserSelector.ID);
        if (!curID) return "/images/undefined.png";

        let finalUser = chatUsersSelector.find(cu => cu.ID === curID);
        if (!finalUser) return "/images/undefined.png";

        return finalUser.Image ?? "#";
    };

    const getUserActivityStatus = ID => {
        if (ID === null || ID === undefined) return "red";
        if (ID < 0) return "#6664E5";

        let cUser = chatUsersSelector.find(cu => cu.ID === ID);
        if (!cUser) return "red";

        if (!cUser.LastSeen) return "gray";
        if (Date.now() - cUser.LastSeen <= 30000) return "green";

        return "gray";
    };
    const sendLeadDeal = (type, ID, chat) => {
        if (!type || !ID || !chat) return;
        socketModule.internal__sendMessage(chat.ID, curUserSelector.ID, type, ID);

        if (props.onClose) props.onClose();
    };
    return <div className="component__sendMessageModal">

        <div className="component__chat__wrap__sidebar__content">
            
            <div className="component__chat__wrap__sidebar__content__search">
                <img src="/images/search.svg" />
                <input type="text" placeholder="Traži.." value={search} onInput={e => setSearch(e.currentTarget.value)} />
            </div>
        </div>
        <div className="component__chat__wrap__sidebar__chats">
            {chatRoomsSelector.filter(cr => {
                if (cr?.ID < 0) return false;
                if (!search) return true;
                if (
                    String(cr.ID).includes(search.toLowerCase()) ||
                    String(cr.ChatRoomName).toLowerCase().includes(search.toLowerCase())
                ) {
                    return true;
                } else {
                    for (let usr of cr.Participants) {
                        let user = chatUsersSelector.find(u => u.ID === usr);
                        if (user) {
                            if (`${user.FirstName} ${user.LastName}`.toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            };
                        };
                    };
                    return false;
                };
            }).map((cr) => {
                return <div style={{gridTemplateColumns: "36px 1fr 24px 50px"}} className={`component__chat__wrap__sidebar__chats__chat ${activeChat === cr.ID ? "component__chat__wrap__sidebar__chats__chat--active" : ""}`} onClick={() => {

                }}>
                    <div className="component__chat__wrap__sidebar__chats__chat__user" style={{ gridRow: "1 / span 2", gridColumn: "1" }}>
                        <img onError={e => e.currentTarget.src = "/images/undefined.png"} src={getUserImage(cr.Participants)} />
                        <span style={{
                            backgroundColor: getUserActivityStatus(cr.Participants.find(ID => ID !== curUserSelector.ID))
                        }}></span>
                    </div>
                    <p className="component__chat__wrap__sidebar__chats__chat__chatName" style={{height:'100%'}} >
                      
                        {cr.Participants.length > 2 ? `[${cr.Participants.length - 1}] ` : ""}{cr.ChatRoomName ? cr.ChatRoomName : generateChatRoomName(cr.Participants)}
                    </p>
                    
                    <CustomButtonSmall theme='dark' accent='white'  value='Pošalji' onClick={()=>{sendLeadDeal(props.type, props.id, cr)}} />
                </div>
            })}
        </div>
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <CustomButtonSmall onClick={()=>{props.onClose()}} theme='dark' accent='red'  value='Zatvori' />
        </div>
    </div>
}


export default SendMessage