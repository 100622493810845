import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import CustomInput from "../../../components/customComponents/CustomInput";
import CustomButton from "../../../components/customComponents/ButtonSmall";

const ImportDocuments = (props) => {
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedDocumentType, setSelectedDocumentType] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState();

    const dealIDRef = React.useRef();
    const descriptionRef = React.useRef();

    const returnedDocumentTypesSelector = useSelector(state => state?.statusCodes?.returnedDocumentTypes ?? {});

    const saveData = () => {
        setInfoP();
        let data = {
            DealID: dealIDRef.current.value,
            DocumentType: selectedDocumentType,
            Description: descriptionRef.current.value,

            Document: selectedFile
        };

        if (!data.DealID) return setInfoP({status: "error", data: "Deal ID ne može biti prazan"});
        if (!data.DocumentType) return setInfoP({status: "error", data: "Tip dokumenta ne može biti prazan"});
        if (!data.Document) return setInfoP({status: "error", data: "Niste odabrali fajl"});

        let fd = new FormData();
        for (let key of Object.keys(data)) fd.append(key, data[key]);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/returnedDocuments/addReturnedDocument`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                "Content-Type": "application/form-data"
            }
        }).then(res => {
            if (res.data.status === "ok") {
                setSelectedFile();
                descriptionRef.current.value = "";
                setInfoP({status: "ok", data: `Fajl uspješno uvežen`});

                if (props.onChange) {
                    props.onChange?.();
                    props.onClose?.();
                }
            } else {
                setInfoP({status: "error", data: "Došlo je do greške prilikom uvoza!"});
            };
        }).catch(() => {
            setInfoP({status: "error", data: "Server ne reagira"});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__shippmentAgent__importDocuments">
        <div className="route__shippmentAgent__importDocuments__wrap">
            <h3>Uvoz povratne dokumentacije</h3>
            <p>U ovom prozoru uvozite svu skeniranu povratnu dokumentaciju</p>
            <p>Na primjer, vraćene potpisane ugovore od klijenata.</p>

            <br />
            <br />
            <div className="route__shippmentAgent__importDocuments__wrap__item">
                <span>1) DealID</span>
                <CustomInput
                    value={props.ID ?? null}
                    ref={dealIDRef}
                    theme="dark"
                    accent="#8B88FE"
                    placeholder="ID od deal-a za koji se veže ugovor"
                />
            </div>

            <div className="route__shippmentAgent__importDocuments__wrap__item">
                <span>2) Tip dokumentacije</span>
                <Dropdown
                    theme="dark"
                    accent="#8B88FE"
                    data={Object.keys(returnedDocumentTypesSelector).map(key => {
                        return {name: returnedDocumentTypesSelector[key], value: key}
                    })}
                    onChange={e => setSelectedDocumentType(e?.value)}
                />
            </div>

            <div className="route__shippmentAgent__importDocuments__wrap__item route__shippmentAgent__importDocuments__wrap__item--file">
                <span>3) Odaberite fajl</span>
                <CustomButton
                    style={{width: "max-content", padding: "0 10px"}}
                    value={selectedFile ? selectedFile.name : "Odaberite fajl"}
                    onClick={e => {
                        e.target.parentNode.querySelector("input").click();
                    }}
                />
                <input type="file" accept="*/*" style={{display: "none"}} onChange={e => {
                    if (e.target.files.length === 0) return;
                    setSelectedFile(e.target.files[0]);
                }} />
            </div>

            <div className="route__shippmentAgent__importDocuments__wrap__item route__shippmentAgent__importDocuments__wrap__item--file">
                <span>4) Opis (opcionalno)</span>
                <textarea placeholder="Opis dokumenta, opcionalno" ref={descriptionRef}></textarea>
            </div>

            <br />
            <br />

            {spinner ? <Spinner color="#8B88FE" style={{width: "40px", height: "40px"}} /> : <CustomButton onClick={saveData} style={{width: "max-content", padding: "0 10px"}} value="Uvezi dokumentaciju" />}
            {props.onChange && <CustomButton onClick={() => props.onClose()} style={{width: "max-content", padding: "0 10px", borderColor: "rgb(233, 86, 86)", marginLeft: "20px"}} value="Izlaz" />}

            {infoP && <p className={`route__shippmentAgent__importDocuments__wrap__infoP route__shippmentAgent__importDocuments__wrap__infoP--${infoP.status}`}>{infoP.data}</p>}
        </div>
    </div>
};

export default ImportDocuments;