import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";

import * as backendTasksActions from "../../actions/backendTasksAction";

import Progressbar from "../customComponents/Progressbar";
import Spinner from "../customComponents/Spinner";

let curInterval = null;
const BackendTasks = (props) => {
    const [allTasks, setAllTasks] = React.useState([]);
    const backendTasksSelector = useSelector(state => state.backendTasks ?? []);

    const curDispatch = useDispatch();

    const addTask = task => {
        setAllTasks(t => [...t, {...task, tID: `${Date.now()}-${Math.floor(Math.random() * 9999)}`}]);
    };

    const updateTask = task => {
        setAllTasks(t => t.map(item => {
            let tmp = {...item};
            if (tmp.ID === tmp.ID) {
                for (let key of Object.keys(task)) {
                    tmp[key] = task[key];
                };
            };
            return tmp;
        }));
    };

    const removeTask = task => {
        const finishRemove = () => {
            setAllTasks(tasks => tasks.filter(t => t.ID !== task.ID));
        };

        let item = document.querySelector(`.component__backendTasks__task--${task.ID}`);
        if (!item) return finishRemove();

        let computed = getComputedStyle(item);
        item.animate([
            {width: computed.width, paddingLeft: computed.paddingLeft, paddingRight: computed.paddingRight, boxShadow: computed.boxShadow},
            {width: 0, paddingLeft: 0, paddingRight: 0, boxShadow: "0px 0px 0px 0px transparent"}
        ], {
            duration: 300, iterations: 1, fill: "both", easing: "ease"
        }).onfinish = () => finishRemove();
    };

    const performUpdate = () => {
        for (let item of backendTasksSelector) {
            let curTask = allTasks.find(t => t.ID === item.ID);
            if (!curTask) {
                addTask(item);
            } else {
                updateTask(item);
            };
        };
        for (let item of allTasks) {
            if (!backendTasksSelector.find(t => t.ID === item.ID)) {
                removeTask(item);
            };
        };
    };

    React.useEffect(() => {
        performUpdate();
    }, [backendTasksSelector]);

    React.useEffect(() => {
        clearInterval(curInterval);
        curInterval = setInterval(() => {
            curDispatch(backendTasksActions.removeStaleBackendTasks());
            setAllTasks([]);
            performUpdate();
        }, 60000);

        return () => {
            clearInterval(curInterval);
            curInterval = null;
        }
    }, [backendTasksSelector]);

    return <div className={`component__backendTasks`} style={{...(props.style ?? {})}}>
        {(allTasks.length > 0 && props.showLine) && <div className="component__backendTasks__line"></div>}
        {allTasks.map((task, index) => {
            if(index===0){
                return <TaskItem hide={props.hide} task={task} key={`backendTask--${task.ID}-${task.tID ?? ""}`} taskKey={`backendTask--${task.ID}`} />
            }
        })}
    </div>
};

const TaskItem = props => {
    const internalRef = React.useRef();

    React.useEffect(() => {
        if (!internalRef?.current) return;

        internalRef.current.style.maxWidth = null;
        let computed = getComputedStyle(internalRef.current);

        internalRef.current.animate([
            {maxWidth: "0px", paddingLeft: 0, paddingRight: 0},
            {maxWidth: computed.width, paddingLeft: computed.paddingLeft, paddingRight: computed.paddingRight}
        ], {
            duration: 300, iterations: 1, fill: "both", easing: "ease"
        }).onfinish = () => {
            internalRef.current.animate([
                {maxWidth: "none"},
                {maxWidth: "none"},
            ], {duration: 0, iterations: 1, fill: "both", easing: "linear"});
        };
    }, [internalRef]);

    return <div ref={internalRef} className={`component__backendTasks__task component__backendTasks__task--${props.task.ID}`} style={{maxWidth: 0}} key={`${props.taskKey}-wrap`}>
        {props.task.type === "spinner" && <Spinner color="white" style={{width: "16px", height: "16px", marginRight: props.hide ? null : "10px"}} />}
        {props.task.type === "progress" && <Progressbar color="white" style={{width: "16px", height: "16px", marginRight: props.hide ? null : "10px"}} maximum={100} value={props.task.progress} />}
        {!props.hide && <p>{props.task.text}</p>}
    </div>
};

export default BackendTasks;