import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const CustomButtonSmall = React.forwardRef((props, ref) => {
    return <button className={`customComponents__buttonSmall ${props.className ? props.className : ""}`} style={{
        border: `1px solid ${props.accent ?? (props?.theme === "light" ? "black" : "white")}`,
        backgroundColor: props?.style?.backgroundColor ?? (props.theme === "light" ? "white" : "rgb(46, 46, 65)"),
        ...props.style ?? {}
    }} onClick={props.onClick}>
        {props.value}
    </button>
});

CustomButtonSmall.propTypes = {
    value: PropTypes.string,
    accent: PropTypes.string,
}

export default CustomButtonSmall