import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../components/customComponents/CustomInput";
import CustomCheckbox from "../../../components/customComponents/CustomCheckbox";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import Images from "../Images";
import scaleleadOffers from "../../../offers.json";


const Articles = (props) => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [countries, setCountries] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const changeQuantity = (ID, Quantity) => {
        return new Promise(resolve => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/addArticleQuantity`,
                data: {
                    ID,
                    newQuantity: Quantity
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === "ok") return resolve(true);
                resolve(false);
            }).catch(() => resolve(false));
        })
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getAllArticles`,
            data: {
                pagination: paginationOffset.current,
                filters: [
                    ...filters,
                    props.onChange ? {
                        name: "ArticleCompounds",
                        op: "eq",
                        value: null
                    } : null
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getAllArticles`,
            data: {
                pagination: paginationOffset.current,
                filters: [
                    ...filters,
                    props.onChange ? {
                        name: "ArticleCompounds",
                        op: "eq",
                        value: null
                    } : null
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return setCountries(res.data);
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        });
    }, []);

    const ArticleCompoundsPill = (props) => {
        const [oldCData, setOldCData] = React.useState();
        const [cData, setCData] = React.useState();

        React.useEffect(() => {
            if (oldCData) return;
            try {
                let tmp = JSON.parse(props.articleCompounds);
                setOldCData(tmp);
            } catch {
                setCData({ status: "ok", data: [] });
            };
        }, []);

        React.useEffect(() => {
            if (!oldCData) return;
            if (oldCData.length === 0) return setCData({ status: "ok", data: [] });

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/getAllArticles`,
                data: {
                    limit: null,
                    filters: [{
                        name: "ID",
                        op: "in",
                        value: oldCData.map(t => t.id)
                    }]
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === "ok") {
                    let final = oldCData.map(tmpOld => {
                        let curArt = res.data.data.find(t => t.ID === tmpOld.id);
                        if (!curArt) return null;
                        return {
                            ...curArt,
                            quantity: tmpOld.quantity
                        };
                    }).filter(t => t);
                    setCData({ status: "ok", data: final });
                } else {
                    setCData({ status: "error", data: "SERVER_ERROR" });
                }
            }).catch(() => {
                setCData({ status: "error", data: "SERVER_ERROR" });
            });
        }, [oldCData]);

        if (cData?.status === "ok") {
            if (cData.data.length === 0) return null;
        };
        return <div className="route__adminPanel__articles__compoundPills" style={{
            width: `${window.innerWidth - 100}px`
        }}>
            <p>Sastojci</p>
            {!cData ? <Spinner color="#6664E5" style={{
                width: "32px",
                heigth: "32px",
                marginLeft: "10px"
            }} /> : <>
                {cData.status === "error" ?
                    <span className="error-pill">Greška prilikom dohvatanja sastojaka</span> :
                    cData.data.map(cItem => {
                        return <span>[{cItem.ArticleCode}] {cItem.ArticleName} x{cItem.quantity}</span>
                    })
                }
            </>}
        </div>
    };

    const ArticleTypePill = props => {
        const parseType = type => {
            switch (type) {
                case "article": return "Artikal";
                case "sms": return "SMS usluga";
                default: return "?";
            };
        };
        return <div className="route__adminPanel__articles__compoundPills" style={{
            width: `${window.innerWidth - 100}px`
        }}>
            <p>Tip artikla</p>
            <span>{parseType(props.data.ArticleType)}</span>
        </div>
    };

    const ArticleOffersPill = props => {

        return <div className="route__adminPanel__articles__compoundPills" style={{
            width: `${window.innerWidth - 100}px`
        }}>
            <p>Offeri artikla</p>
            {props.data?.OfferIDs ? <>
                {props.data.OfferIDs.map(item => {
                    return <span>{(() => {
                        let curOff = scaleleadOffers.find(e => e[1] === item);
                        if (curOff) {
                            return curOff[0];
                        }

                    })()}</span>
                })}
            </> : <span>Nisu dodani offeri</span>}

        </div>
    };


    return <div className="route__adminPanel__articles">
        <div className="route__adminPanel__articles__buttons">
            <CustomButtonSmall accent="#2b2c3e" value="Prikaži artikle kao tabelu" style={{ width: "150px", border: "1px solid #999FAE" }} onClick={() => {
                window.open(
                    `${window.location.origin}/#/ArticlesPrint?filters=${JSON.stringify(filters)}`,
                    "_blank"
                )
            }} />
            {!props.onChange && <CustomButtonSmall style={{ width: "160px", border: "1px solid #999FAE" }} accent="#2b2c3e" value="Dodaj novi artikal" onClick={e => {
                animateBox(e, <AddArticle countries={countries} onChange={() => {
                    getData();
                }} />)
            }} />}
            <CustomButtonSmall accent="#2b2c3e" value="Osvježi" onClick={getData} style={{ border: "1px solid #E264E59E" }} />
            {props.onClose && <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />}
        </div>
        <FilteredCustomTable
            headers={["", "Šifra", "Naziv", "Cijena", "Na stanju", "Dužina trajanja", "Država"]}
            theme="dark"
            accent="#3D3D55"
            filterCB={fi => setFilters(fi)}
            filters={[
                { name: "ArticleCode", friendlyName: "Šifra", type: "string" },
                { name: "ArticleName", friendlyName: "Naziv artikla", type: "string" },
                { name: "ArticleQuantity", friendlyName: "Količina", type: "number" },
                {
                    name: "CountryID", friendlyName: "Država", type: "custom", varType: "string", data: (countries?.data ?? []).map(c => {
                        return { text: c.CountryName, value: c.ID }
                    })
                },
                { name: "ArticlePrice", friendlyName: "Cijena artikla", type: "number" },
                { name: "ArticleActive", friendlyName: "Artikal aktivan", type: "boolean" },
            ]}
            data={(() => {
                if (!data || !countries) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja artikala</p> }]]
                if (countries.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja država</p> }]]
                if (data.data.length === 0) return [[{ keyID: "noDataText", type: "custom", data: <p style={{ textAlign: "center" }}>Nema artikala za prikaz</p> }]];
                let tmp = data.data.map(elem => {
                    let CountryCurrency = "?";
                    let curCountry = countries.data.find(t => t.ID === elem.CountryID);
                    if (curCountry) CountryCurrency = curCountry.CountryCurrency;
                    return [
                        {
                            keyID: String(elem.ID), type: "custom", data: <div className="route__adminPanel__articles__articleImage">
                                {elem.ArticleImage && <img src={elem.ArticleImage} />}
                            </div>
                        },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleCode },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleName },
                        { keyID: String(elem.ID), type: "text", text: `${elem.ArticlePrice} ${CountryCurrency}` },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleCompounds ? "Paket" : elem.ArticleQuantity },
                        { keyID: String(elem.ID), type: "text", text: `${elem.ArticleDuration} dana` },
                        { keyID: String(elem.ID), type: "text", text: curCountry ? curCountry.CountryName : "?" },
                        elem.ArticleCompounds ? {
                            keyID: String(elem.ID), type: "groupNewline", group: [
                                { keyID: String(elem.ID), type: "custom", data: <ArticleCompoundsPill articleCompounds={elem.ArticleCompounds} /> }
                            ]
                        } : null,
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: [
                                { keyID: String(elem.ID), type: "custom", data: <ArticleTypePill data={elem} /> }
                            ]
                        },
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: [
                                { keyID: String(elem.ID), type: "custom", data: <ArticleOffersPill data={elem} /> }
                            ]
                        },
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: !props.onChange ? [
                                {
                                    keyID: String(elem.ID),
                                    type: "button",
                                    text: "Uredi",
                                    triggerDropdown: true,
                                    triggerData: c => <EditArticle c={c} data={elem} countries={countries} onChange={(newArticle) => {
                                        setData(d => {
                                            return {
                                                ...d,
                                                data: [
                                                    ...d.data.filter(t => t.ID < elem.ID),
                                                    newArticle,
                                                    ...d.data.filter(t => t.ID > elem.ID)
                                                ]
                                            };
                                        });
                                    }} />,
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid #999FAE"
                                    }
                                },
                                {
                                    keyID: String(elem.ID),
                                    type: "button",
                                    text: "Obriši",
                                    triggerDropdown: true,
                                    triggerData: c => <RemoveArticle c={c} data={elem} onChange={() => {
                                        setData(d => {
                                            return {
                                                ...d,
                                                data: d.data.filter(t => t.ID !== elem.ID)
                                            };
                                        });
                                    }} />,
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid rgb(233, 86, 86)"
                                    }
                                },
                                {
                                    keyID: String(elem.ID),
                                    type: "button",
                                    text: "Dodaj količinu",
                                    onClick: e => {
                                        animateBox(e, <QuantityModal onChange={qty => {
                                            changeQuantity(elem.ID, Number(qty)).then(f => {
                                                if (!f) {
                                                    alert("Greška prilikom dodavanja količine")
                                                } else {
                                                    elem.ArticleQuantity = Number(elem.ArticleQuantity) + qty;
                                                    setData(d => {
                                                        return {
                                                            ...d,
                                                            data: [
                                                                ...d.data.filter(t => t.ID < elem.ID),
                                                                elem,
                                                                ...d.data.filter(t => t.ID > elem.ID)
                                                            ]
                                                        };
                                                    });
                                                };
                                            });
                                        }} />)
                                    },
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid #999FAE"
                                    }
                                },
                                elem.ArticleCompounds ? null : {
                                    keyID: String(elem.ID),
                                    type: "button",
                                    text: "Pogledaj historiju",
                                    onClick: e => animateBox(e, <ArticleHistory data={elem} />),
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid #999FAE"
                                    }
                                }
                            ] : [
                                {
                                    keyID: String(elem.ID),
                                    type: "button",
                                    text: "Odaberi artikal",
                                    onClick: (e) => {
                                        animateBox(e, <QuantityModal onChange={qty => {
                                            if (qty > 0) {
                                                props.onChange({
                                                    ...elem,
                                                    quantity: qty
                                                });
                                                props.onClose();
                                            } else {
                                                props.onClose();
                                            };
                                        }} />);
                                    },
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid #D456E9"
                                    }
                                }
                            ]
                        },
                    ].filter(t => t);
                });
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);
                if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);

                return tmp;
            })()}
        />
    </div>
};

const ArticleCompounds = (props) => {
    const [articleCompounds, setArticleCompounds] = React.useState(props.articleCompounds ?? []);
    const [articles, setArticles] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const getArticles = async () => {
        setSpinner(true);
        let out = [];
        for (let art of articleCompounds) {
            let data = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/articles/getAllArticles`,
                data: {
                    limit: 1,
                    filters: [
                        {
                            name: "ID",
                            op: "eq",
                            value: art.id
                        }
                    ]
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === "ok") {
                    if (!res.data.data.length > 0) {
                        return { status: "error", data: "SERVER_ERROR" };
                    };
                    return {
                        status: "ok",
                        data: {
                            ...res.data.data[0],
                            quantity: art.quantity
                        }
                    };
                } else {
                    return res.data;
                };
            }).catch(() => {
                return { status: "error", data: "SERVER_ERROR" };
            });
            out.push(data);
        };
        setTimeout(() => setSpinner(false), 100);
        out = out.filter(t => t.status === "ok");
        setArticles({ status: "ok", data: out.map(t => t.data) });
    };

    React.useEffect(() => {
        if (!articleCompounds) return;
        getArticles();
    }, [articleCompounds]);

    return <div className="route__adminPanel__articles__articleCompounds">
        <div className="route__adminPanel__articles__articleCompounds__buttons">
            <CustomButtonSmall accent="#2b2c3e" value="Dodaj sastojke" onClick={(e) => {
                animateBox(e, <Articles onChange={data => {
                    setArticles(d => {
                        return {
                            ...d,
                            data: [
                                ...d.data,
                                data
                            ]
                        };
                    });
                }} />);
            }} style={{ width: "120px", border: "1px solid #999FAE" }} />
            <CustomButtonSmall accent="#2b2c3e" value="Spremi" onClick={() => {
                props.onChange(articles.data.map(art => {
                    return {
                        id: art.ID,
                        quantity: art.quantity
                    };
                }));
                props.onClose();
            }} style={{ border: "1px solid #999FAE" }} />
            {props.onClose && <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />}
        </div>
        <FilteredCustomTable
            headers={["", "Šifra", "Naziv", "Cijena", "Dužina trajanja", "Država", "Količina"]}
            theme="dark"
            style={{ display: "block" }}
            accent="#3D3D55"
            data={(() => {
                if (!articles) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                if (articles.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja artikala</p> }]]
                if (articles.data.length === 0) return [[{ keyID: "noDataText", type: "custom", data: <p style={{ textAlign: "center" }}>Nema artikala za prikaz</p> }]];
                let tmp = articles.data.map(elem => {
                    let CountryCurrency = "?";
                    let curCountry = props.countries.data.find(t => t.ID === elem.CountryID);
                    if (curCountry) CountryCurrency = curCountry.CountryCurrency;
                    return [
                        {
                            keyID: String(elem.ID), type: "custom", data: <div className="route__adminPanel__articles__articleImage">
                                {elem.ArticleImage && <img src={elem.ArticleImage} />}
                            </div>
                        },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleCode },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleName },
                        { keyID: String(elem.ID), type: "text", text: `${elem.ArticlePrice} ${CountryCurrency}` },
                        { keyID: String(elem.ID), type: "text", text: `${elem.ArticleDuration} dana` },
                        { keyID: String(elem.ID), type: "text", text: curCountry ? curCountry.CountryName : "?" },
                        { keyID: String(elem.ID), type: "text", text: elem.quantity },
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(elem.ID), type: "button", text: "Uredi količinu", onClick: e => {
                                        animateBox(e, <QuantityModal onChange={(qty) => {
                                            setArticles(d => {
                                                elem.quantity = qty;
                                                return {
                                                    ...d,
                                                    data: [
                                                        ...d.data.filter(t => t.ID < elem.ID),
                                                        elem,
                                                        ...d.data.filter(t => t.ID > elem.ID)
                                                    ]
                                                };
                                            });
                                        }} />)
                                    },
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid #999FAE"
                                    }
                                },
                                {
                                    keyID: String(elem.ID), type: "button", text: "Obriši", onClick: () => {
                                        setArticles(d => {
                                            return {
                                                ...d,
                                                data: [
                                                    ...d.data.filter(t => t.ID < elem.ID),
                                                    ...d.data.filter(t => t.ID > elem.ID)
                                                ]
                                            };
                                        });
                                    },
                                    style: {
                                        background: "#2E2E41",
                                        border: "1px solid rgb(233, 86, 86)"
                                    }
                                }
                            ]
                        },
                    ];
                });

                if (spinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);

                return tmp;
            })()}
        />
    </div>
};

const QuantityModal = (props) => {
    const quantityRef = React.useRef();

    const checkData = () => {
        if (!isNaN(Number(quantityRef.current.value))) {
            props.onChange(Number(quantityRef.current.value));
            props.onClose();
        };
    };

    return <div className="route__adminPanel__articles__addArticle">
        <p>Količina</p>
        <CustomInput ref={quantityRef} accent="#6664E5" theme="dark" type="number" defaultValue={1} />

        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Spremi" onClick={checkData} style={{ border: "1px solid #999FAE" }} />
        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
    </div>
};

const ArticleHistory = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef(0);
    const curPaginationTimestamp = React.useRef(Date.now());

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getArticleHistory`,
            data: {
                ID: props.data.ID,
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/getArticleHistory`,
            data: {
                ID: props.data.ID,
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    return <div className="route__adminPanel__articles__articleHistory">
        <div className="route__adminPanel__articles__articleHistory__buttons">
            <CustomButtonSmall accent="#2b2c3e" value="Osvježi" onClick={getData} style={{ border: "1px solid #E264E59E" }} />
            {props.onClose && <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />}
        </div>
        <FilteredCustomTable
            headers={["Promjena količine", "Ukupna količina", "Tip", "Datum"]}
            theme="dark"
            accent="#3D3D55"
            filterCB={fi => setFilters(fi)}
            data={(() => {
                if (!data) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja historije artikala</p> }]]
                if (data.data.length === 0) return [[{ keyID: "noDataText", type: "custom", data: <p style={{ textAlign: "center" }}>Nema artikala za prikaz</p> }]];
                let tmp = data.data.map(elem => {
                    return [
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleQuantity },
                        { keyID: String(elem.ID), type: "text", text: elem.ArticleTotal },
                        { keyID: String(elem.ID), type: "text", text: elem.Type },
                        { keyID: String(elem.ID), type: "text", text: new Date(elem.createdAt).toLocaleString() },
                    ];
                });
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);
                if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);

                return tmp;
            })()}
        />
    </div>
};

const ArticleAdditionalInfo = props => {
    const [rawMaterials, setRawMaterials] = React.useState(props.rawMaterials ?? []);
    const [description, setDescription] = React.useState(props.description ?? "");
    const [safeForKids, setSafeForKids] = React.useState(props.safeForKids ?? false);
    const [maxDailyIntake, setMaxDailyIntake] = React.useState(props.maxDailyIntake ?? 0);
    const [boundOfferIDs, setBoundOfferIDs] = React.useState(props.boundOfferIDs ?? []);

    const onChange = () => {
        if (props.setRawMaterials) props.setRawMaterials(rawMaterials);
        if (props.setDescription) props.setDescription(description);
        if (props.setSafeForKids) props.setSafeForKids(safeForKids);
        if (props.setMaxDailyIntake) props.setMaxDailyIntake(maxDailyIntake);
        if (props.setBoundOfferIDs) props.setBoundOfferIDs(boundOfferIDs);

        props.onClose();
    };

    return <div className="route__adminPanel__articles__addArticle route__adminPanel__articles__addArticle--info">
        <div className="route__adminPanel__articles__addArticle__wrap">
            <CustomInput value={maxDailyIntake} onChange={e => setMaxDailyIntake(e?.target?.value)} accent="#6664E5" theme="dark" type="text" placeholder="Maksimalni dnevni unos" />

            <CustomCheckbox accent="#6664E5" theme="dark" placeholder="Sigurno za djecu" defaultValue={safeForKids} onChange={e => setSafeForKids(!!e)} />

            <h3 style={{ marginTop: "20px" }}>Sirovine</h3>
            <div className="route__adminPanel__articles__addArticle__materials">
                {rawMaterials.map((m, mIdx) => {
                    return <div className="route__adminPanel__articles__addArticle__materials__item">
                        <CustomInput accent="#6664E5" theme="dark" value={m} onChange={e => {
                            setRawMaterials(rm => rm.map((rmm, rmmIdx) => {
                                if (rmmIdx === mIdx) return e?.target?.value;
                                return rmm;
                            }));
                        }} />
                        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Obriši" style={{ border: "1px solid rgb(233, 86, 86)", width: "70px", margin: 0 }} onClick={() => setRawMaterials(r => r.filter((_, rIdx) => rIdx !== mIdx))} />
                    </div>
                })}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj sirovinu" style={{ border: "1px solid rgb(102, 100, 229)", width: "130px", margin: "10px auto" }} onClick={() => setRawMaterials(r => [...r, ""])} />
            </div>

            <h3 style={{ marginTop: "20px" }}>OfferID-ovi (Scalelead)</h3>
            <div className="route__adminPanel__articles__addArticle__materials">
                {boundOfferIDs.map((m, mIdx) => {
                    return <div className="route__adminPanel__articles__addArticle__materials__item">
                        <CustomInput accent="#6664E5" theme="dark" value={m} onChange={e => {
                            setBoundOfferIDs(rm => rm.map((rmm, rmmIdx) => {
                                if (rmmIdx === mIdx) return e?.target?.value;
                                return rmm;
                            }));
                        }} />
                        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Obriši" style={{ border: "1px solid rgb(233, 86, 86)", width: "70px", margin: 0 }} onClick={() => setBoundOfferIDs(r => r.filter((_, rIdx) => rIdx !== mIdx))} />
                    </div>
                })}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj offer" style={{ border: "1px solid rgb(102, 100, 229)", width: "130px", margin: "10px auto" }} onClick={() => setBoundOfferIDs(r => [...r, ""])} />
            </div>

            <h3 style={{ marginTop: "20px" }}>Kratki opis</h3>
            <textarea className="route__adminPanel__articles__addArticle__description" value={description} onChange={e => setDescription(e?.target?.value)}></textarea>

            <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Spremi" onClick={() => onChange()} style={{ border: "1px solid #999FAE" }} />
            <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};

const AddArticle = (props) => {
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [image, setImage] = React.useState("");
    const [articleCompounds, setArticleCompounds] = React.useState([]);
    const [articleType, setArticleType] = React.useState("article");
    const [articleActive, setArticleActive] = React.useState(true);

    const [rawMaterials, setRawMaterials] = React.useState([]);
    const [description, setDescription] = React.useState("");
    const [safeForKids, setSafeForKids] = React.useState(false);
    const [maxDailyIntake, setMaxDailyIntake] = React.useState(0);
    const [boundOfferIDs, setBoundOfferIDs] = React.useState([]);
    const [offerIDs, setOfferIDs] = React.useState([]);

    const nameRef = React.useRef();
    const valueRef = React.useRef();
    const durationRef = React.useRef();
    const countryIDRef = React.useRef();
    const codeRef = React.useRef();
    const articleContentsRef = React.useRef();
    const integrationIDRef = React.useRef();

    const checkData = () => {
        setInfoP("");
        const data = {
            ArticleName: nameRef.current.value,
            ArticlePrice: valueRef.current.value,
            ArticleImage: image,
            CountryID: countryIDRef.current,
            ArticleDuration: durationRef.current.value,
            ArticleCode: codeRef.current.value,
            ArticleCompounds: articleCompounds.length > 0 ? articleCompounds : null,
            ArticleType: articleType,
            ArticleActive: !!articleActive,
            IntegrationID: integrationIDRef.current.value,

            ArticleRawMaterials: rawMaterials,
            ArticleDescription: description,
            ArticleSafeForKids: safeForKids,
            ArticleMaxDailyIntake: maxDailyIntake,
            ArticleBoundOfferIDs: boundOfferIDs,
            OfferIDs: offerIDs
        };

        if (!data.ArticleName || !data.ArticlePrice) {
            return setInfoP("Polja ne mogu biti prazna");
        };
        if (!data.CountryID) {
            return setInfoP("Država ne može biti prazna");
        };
        if (!data.ArticleCode) {
            return setInfoP("Šifra ne može biti prazna");
        };
        if (data.ArticleType === "sms") {
            if (!articleContentsRef?.current?.value) {
                return setInfoP("SMS poruka ne može biti prazna!");
            } else {
                data.ArticleContents = articleContentsRef.current.value;
            };
        };

        data.ArticlePrice = Number(data.ArticlePrice);
        data.ArticleDuration = Number(data.ArticleDuration);
        if (isNaN(data.ArticlePrice)) {
            return setInfoP("Cijena mora biti broj");
        };
        if (isNaN(data.ArticleDuration)) {
            return setInfoP("Dužina trajanja artikla mora biti broj");
        };


        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/addArticle`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Došlo je do greške prilikom dodavnja artikla");
            } else {
                props.onClose();
                props.onChange();
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };


    return <div className="route__adminPanel__articles__addArticle">
        {!props.countries ? <Spinner color="#6664E5" /> : <>
            {props.countries.status === "error" ? <p>Greška prilikom dohvatanja država</p> : <>
                <div className="route__adminPanel__articles__addArticle__spinner" style={{
                    opacity: spinner ? 1 : 0,
                    pointerEvents: spinner ? "all" : "none"
                }}>
                    <Spinner color="#6664E5" />
                </div>
                <Dropdown theme="dark" accent="#3D3D55" data={props.countries.data.map(elem => {
                    return {
                        name: `${elem.CountryName} (${elem.CountryCurrency})`,
                        value: elem.ID
                    }
                })} selected={0} onChange={e => countryIDRef.current = e?.value} />
                <Dropdown theme="dark" accent="#3D3D55" data={[
                    { name: "Artikal", value: "article" },
                    { name: "SMS usluga", value: "sms" },
                ]} selected={0} onChange={e => setArticleType(e?.value)} />
                <CustomInput ref={nameRef} accent="#6664E5" theme="dark" type="text" placeholder="Naziv" />
                <CustomInput ref={valueRef} accent="#6664E5" theme="dark" type="text" placeholder="Cijena" />
                <CustomInput ref={durationRef} accent="#6664E5" theme="dark" type="text" placeholder="Dužina trajanja artikla (dana)" />
                <CustomInput ref={codeRef} accent="#6664E5" theme="dark" type="text" placeholder="Šifra artikla" />
                <CustomInput ref={integrationIDRef} accent="#6664E5" theme="dark" type="text" placeholder="SKU / Eksterni ID" />
                <CustomCheckbox accent="rgb(102, 100, 229)" theme="dark" defaultValue={articleActive} onChange={e => setArticleActive(e)} placeholder="Artikal aktivan" />
                {articleType === "sms" && <textarea className="route__adminPanel__articles__addArticle__articleContents" placeholder="SMS Poruka" ref={articleContentsRef}>
                </textarea>}

                <div className="route__adminPanel__articles__addArticle__btnWrap">
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj sastojke" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <ArticleCompounds countries={props.countries} articleCompounds={articleCompounds} onChange={ac => {
                            setArticleCompounds(ac);
                        }} />);
                    }} />
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodatne informacije" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <ArticleAdditionalInfo
                            {...{ rawMaterials, setRawMaterials, description, setDescription, safeForKids, setSafeForKids, maxDailyIntake, setMaxDailyIntake, boundOfferIDs, setBoundOfferIDs }}
                        />);
                    }} />
                </div>

                <div className="route__adminPanel__articles__addArticle__btnWrap">
                    {!image ?
                        <CustomButtonSmall style={{ width: "120px", border: "1px solid #999FAE" }} accent="#2b2c3e" theme="dark" value="Odaberi sliku" onClick={(e) => {
                            animateBox(e, <Images selectMode={true} onChange={d => setImage(d)} />);
                        }} /> :
                        <div style={{ backgroundImage: `url(${image})` }}>
                            <p style={{
                                color: "white",
                                backgroundColor: "#0000009c",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} onClick={e => {
                                e.stopPropagation();
                                animateBox(e, <Images selectMode={true} onChange={d => setImage(d)} />);
                            }}>Promijeni sliku</p>
                        </div>
                    }
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj scale-lead offere" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <AddScaleleadOffers
                            setData={setOfferIDs}
                        />);
                    }} />
                </div>
                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Spremi" onClick={checkData} style={{ border: "1px solid #999FAE" }} />
                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
                <p className="route__adminPanel__articles__addArticle__infoP">{infoP}</p>
            </>}
        </>}
    </div>
};

const EditArticle = (props) => {
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [image, setImage] = React.useState(props.data.ArticleImage);
    const [articleCompounds, setArticleCompounds] = React.useState((() => {
        try {
            return JSON.parse(props?.data?.ArticleCompounds) ?? []
        } catch {
            return [];
        };
    })());
    const [articleType, setArticleType] = React.useState(props.data.ArticleType);
    const [articleActive, setArticleActive] = React.useState(props.data.ArticleActive);

    const [rawMaterials, setRawMaterials] = React.useState((() => {
        try {
            return JSON.parse(props?.data?.ArticleRawMaterials);
        } catch {
            return [];
        };
    })());
    const [description, setDescription] = React.useState(props.data.ArticleDescription);
    const [safeForKids, setSafeForKids] = React.useState(props.data.ArticleSafeForKids);
    const [maxDailyIntake, setMaxDailyIntake] = React.useState(props.data.ArticleMaxDailyIntake);
    const [boundOfferIDs, setBoundOfferIDs] = React.useState((() => {
        try {
            return JSON.parse(props?.data?.ArticleBoundOfferIDs);
        } catch {
            return [];
        };
    })());

    const [offerIDs, setOfferIDs] = React.useState(props.data?.OfferIDs ?? []);

    const nameRef = React.useRef();
    const valueRef = React.useRef();
    const durationRef = React.useRef();
    const countryIDRef = React.useRef();
    const codeRef = React.useRef();
    const articleContentsRef = React.useRef();
    const integrationIDRef = React.useRef();

    const checkData = () => {
        setInfoP("");

        const data = {
            ID: props.data.ID,
            ArticleName: nameRef.current.value,
            ArticlePrice: valueRef.current.value,
            CountryID: countryIDRef.current,
            ArticleDuration: durationRef.current.value,
            ArticleCode: codeRef.current.value,
            ArticleCompounds: articleCompounds.length > 0 ? articleCompounds : null,
            ArticleType: articleType,
            ArticleActive: !!articleActive,
            IntegrationID: integrationIDRef.current.value,

            ArticleRawMaterials: rawMaterials,
            ArticleDescription: description,
            ArticleSafeForKids: safeForKids,
            ArticleMaxDailyIntake: maxDailyIntake,
            ArticleBoundOfferIDs: boundOfferIDs,
            OfferIDs: offerIDs
        };

        if (!data.ArticleName || !data.ArticlePrice) {
            return setInfoP("Polja ne mogu biti prazna");
        };
        if (!data.CountryID) {
            return setInfoP("Država ne može biti prazna");
        };
        if (!data.ArticleCode) {
            return setInfoP("šifra artikla ne može biti prazna");
        };
        if (data.ArticleType === "sms") {
            if (!articleContentsRef?.current?.value) {
                return setInfoP("SMS poruka ne može biti prazna!");
            } else {
                data.ArticleContents = articleContentsRef.current.value;
            };
        };

        data.ArticlePrice = Number(data.ArticlePrice);
        data.ArticleDuration = Number(data.ArticleDuration);
        if (isNaN(data.ArticlePrice)) {
            return setInfoP("Cijena mora biti broj");
        };
        if (isNaN(data.ArticleDuration)) {
            return setInfoP("Dužina trajanja artikla mora biti broj");
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/editArticle`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "error") {
                setInfoP("Došlo je do greške prilikom uređivanja artikla");
            } else {
                props.c().then(() => {
                    props.onChange(data);
                });
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (articleType === "sms") {
            if (articleContentsRef?.current) {
                articleContentsRef.current.value = props.data.ArticleContents;
            };
        };
    }, [articleContentsRef.current, articleType]);


    return <div className="route__adminPanel__articles__editArticle">
        {!props.countries ? <Spinner color="#6664E5" /> : <>
            {props.countries.status === "error" ? <p>Greška prilikom dohvatanja država</p> : <>
                <div className="route__adminPanel__articles__editArticle__spinner" style={{
                    opacity: spinner ? 1 : 0,
                    pointerEvents: spinner ? "all" : "none"
                }}>
                    <Spinner color="#6664E5" />
                </div>
                <Dropdown theme="dark" accent="#3D3D55" data={props.countries.data.map(elem => {
                    return {
                        name: `${elem.CountryName} (${elem.CountryCurrency})`,
                        value: elem.ID
                    }
                })} selected={(() => {
                    let id = null;
                    props.countries.data.forEach((item, index) => {
                        if (item.ID === props.data.CountryID) id = index;
                    });
                    return id;
                })()} onChange={e => countryIDRef.current = e?.value} />
                <Dropdown theme="dark" accent="#3D3D55" data={[
                    { name: "Artikal", value: "article" },
                    { name: "SMS usluga", value: "sms" }
                ]} selected={(() => {
                    switch (articleType) {
                        case "article": return 0;
                        case "sms": return 1;
                        default: return 0;
                    };
                })()} onChange={e => setArticleType(e?.value)} />

                <CustomInput defaultValue={props.data?.ArticleName} ref={nameRef} accent="#6664E5" theme="dark" type="text" placeholder="Naziv" />
                <CustomInput defaultValue={props.data?.ArticlePrice} ref={valueRef} accent="#6664E5" theme="dark" type="text" placeholder="Cijena" />
                <CustomInput defaultValue={props.data?.ArticleDuration} ref={durationRef} accent="#6664E5" theme="dark" type="text" placeholder="Dužina trajanja artikla (dana)" />
                <CustomInput defaultValue={props.data?.ArticleCode} ref={codeRef} accent="#6664E5" theme="dark" type="text" placeholder="Šifra artikla" />
                <CustomInput defaultValue={props.data?.IntegrationID} ref={integrationIDRef} accent="#6664E5" theme="dark" type="text" placeholder="SKU / Eksterni ID" />
                <CustomCheckbox accent="rgb(102, 100, 229)" theme="dark" defaultValue={articleActive} onChange={e => setArticleActive(e)} placeholder="Artikal aktivan" />
                {articleType === "sms" && <textarea className="route__adminPanel__articles__addArticle__articleContents" placeholder="SMS Poruka" ref={articleContentsRef}>
                </textarea>}

                <div className="route__adminPanel__articles__editArticle__btnWrap">
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj sastojke" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <ArticleCompounds countries={props.countries} articleCompounds={articleCompounds} onChange={ac => {
                            setArticleCompounds(ac);
                        }} />);
                    }} />
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodatne informacije" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <ArticleAdditionalInfo
                            {...{ rawMaterials, setRawMaterials, description, setDescription, safeForKids, setSafeForKids, maxDailyIntake, setMaxDailyIntake, boundOfferIDs, setBoundOfferIDs }}
                        />);
                    }} />
                </div>

                <div className="route__adminPanel__articles__addArticle__btnWrap">
                    {!image ?
                        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Odaberi sliku" onClick={(e) => {
                            animateBox(e, <Images selectMode={true} onChange={d => setImage(d)} />);
                        }} style={{ border: "1px solid #999FAE" }} /> :
                        <div style={{ backgroundImage: `url(${image})` }}>
                            <p style={{
                                color: "white",
                                backgroundColor: "#0000009c",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }} onClick={e => {
                                e.stopPropagation();
                                animateBox(e, <Images selectMode={true} onChange={d => setImage(d)} />);
                            }}>Promijeni sliku</p>
                        </div>
                    }
                    <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Dodaj scale-lead offere" style={{ width: "120px", border: "1px solid #999FAE" }} onClick={e => {
                        animateBox(e, <AddScaleleadOffers data={props.data?.OfferIDs ?? []}
                            setData={setOfferIDs}
                        />);
                    }} />
                </div>

                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Spremi" onClick={checkData} style={{ border: "1px solid #999FAE" }} />
                <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.c()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
                <p className="route__adminPanel__articles__editArticle__infoP">{infoP}</p>
            </>}
        </>}
    </div>
};

const RemoveArticle = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeArticle = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/articles/removeArticle`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.c().then(() => {
                    props.onChange()
                })
            } else {
                setInfoP("Greška prilikom brisanja artikla")
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        });
    };

    if (infoP) return <div className="route__adminPanel__countries__removeCountry">
        <p>Došlo je do greške</p>
        <p>{infoP}</p>
        <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.c()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
    </div>
    return <div className="route__adminPanel__countries__removeCountry">
        {spinner ? <Spinner color="#6664E5" /> : <>
            <p>Da li ste sigurni?</p>
            <p>Brisanje artikla <span style={{ color: "#6664E5" }}>{props.data.ArticleName}</span> je trajno!</p>
            <p>Reference na ovaj artikal u prijašnjim leadovima neće biti vidljivi</p>
            <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Obriši" onClick={removeArticle} style={{ border: "1px solid #999FAE" }} />
            <CustomButtonSmall accent="#2b2c3e" theme="dark" value="Izlaz" onClick={() => props.c()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </>}
    </div>
};

const AddScaleleadOffers = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [offers, setOffers] = React.useState(props.data ?? []);




    return <div className="route__adminPanel__articles__scaleleadOffers">
        {scaleleadOffers ? <div className="route__adminPanel__articles__scaleleadOffers__container">
            <p>Dodaj novi offer</p>
            <div className="route__adminPanel__articles__scaleleadOffers__container__head">
                <Dropdown theme='dark' accent="#6664E5" data={scaleleadOffers.map(offer => {
                    return { name: offer[0], value: offer[1] }
                })} onChange={(e) => {
                    if (!offers.includes(e.value)) {
                        setOffers([...offers, e.value])
                    }

                }}
                />
            </div>
            <div className="route__adminPanel__articles__scaleleadOffers__container__offers">
                {offers.map(offer => {
                    let curOffer = scaleleadOffers.find(off => off[1] === offer);
                    if (!curOffer) return null;
                    return <div>
                        <p>{curOffer[0]}</p>
                        <p>{curOffer[1]}</p>
                        <button onClick={() => { setOffers(offers.filter(elem => elem !== offer)) }}>Ukloni</button>
                    </div>
                })}
            </div>
            <div className="route__adminPanel__articles__scaleleadOffers__container__buttons">
                <button onClick={() => {
                    props.setData(offers);
                    props.onClose();
                }}>Spremi</button>
                <button onClick={() => {
                    props.onClose();
                }}>Zatvori</button>
            </div>
        </div> : <Spinner />}

    </div>
};

export default Articles;