import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { getDealShipColor } from "../../modules/leadModule";
import { animateBox } from "../../modules/componentAnimation";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import { FilteredCustomTable } from "../customComponents/Table";
import Dropdown from "../customComponents/Dropdown";

const DealShipmentHistory = (props) => {
    // getShipmentHistoryByDealID
    // getShippmentByDealID
    const [data, setData] = React.useState();
    const [sData, setSData] = React.useState();
    const [countries, setCountries] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const curPaginationTimestamp = React.useRef();

    const curUserFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});
    const shipmentStatusCodes = useSelector(state => state?.statusCodes?.shipment ?? null);
    const userSelector = useSelector((state) => state.userData.userData.UserInfo);

    const fillString = (inp) => {
        inp = String(inp);
        while (inp.length < 10) inp = `0${inp}`;
        return inp;
    };

    const getShipmentData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getShippmentByDealID`,
            data: {
                DealID: props.id,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/countries/getAllCountries`,
                    data: {
                        filters: [{ name: "CountryCode", op: "eq", value: res.data.data.CountryCode }]
                    },
                    ...backendModule.axiosConfig
                }).then(res2 => {
                    if (res2.data.status === "ok") return setCountries(res2.data);
                    return setCountries({ status: "error", data: "SERVER_ERROR" });
                }).catch(() => {
                    return setCountries({ status: "error", data: "SERVER_ERROR" });
                });
                return setSData(res.data);
            } else {
                return setSData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            return setSData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const getData = () => {
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getShipmentHistoryByDealID`,
            data: {
                DealID: props.id,
                limit: 20,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setTimeout(() => setCanPaginate(true), 500);
                return setData(res.data);
            } else {
                setTimeout(() => setCanPaginate(false), 500);
                return setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setTimeout(() => setCanPaginate(false), 500);
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const continueData = (timestamp) => {
        if (timestamp !== curPaginationTimestamp.current) {
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getShipmentHistoryByDealID`,
            data: {
                DealID: props.id,
                limit: 20,
                filters,
                pagination: data?.data?.length
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    React.useEffect(() => {
        if (!props?.id) return;
        getData();
    }, [filters]);

    React.useEffect(() => {
        if (!props?.id) return;
        getShipmentData();
    }, []);

    return <div className="component__dealShipmentHistory">
        <div className="component__dealShipmentHistory__buttons">
            {props.onClose && <CustomButtonSmall accent="#d82626" value="X" onClick={() => props.onClose()} />}
        </div>

        <div className="component__dealShipmentHistory__shipment">
            {userSelector?.Flags?.isAdmin && data ? <div className="component__dealShipmentHistory__shipment__editBtn" onClick={(e) => {
                animateBox(e, <EditDealShipmentModal data={data} updateData={getData} setData={setData} />)
            }} >
                <img src="/images/editPen.png" />
                <p>{"Edit"}</p>
            </div> : <></>}
            <FilteredCustomTable
                theme="dark"
                accent="#6664E5"
                headers={(() => {
                    let out = [];

                    if (!sData) return ["Učitavam podatke..."];
                    if (sData.status === "error") return ["Greška!"];

                    out.push("Država");
                    out.push("ID Pošiljke");
                    if (sData.data.ShippmentLabel) out.push("Naljepnica");
                    if (sData.data.ShippmentTrackingCode) out.push("Kod za praćenje");
                    out.push("Datum");

                    return out;
                })()}
                data={(() => {
                    if (!sData || !countries) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                    if (sData.status === "error" || countries.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja artikala</p> }]]
                    if (countries.data.length === 0) return [[{ keyID: "noDataText", type: "custom", data: <p style={{ textAlign: "center" }}>Nema artikala za prikaz</p> }]];

                    let foundCountry = countries.data.find(c => c.CountryCode === sData.data.CountryCode);
                    if (!foundCountry.CountryName) foundCountry = { CountryName: "Nepoznata država" };

                    let tmp = [];

                    let sIDSplit = [];
                    if (sData.data.ShippmentID) {
                        sIDSplit = sData.data.ShippmentID.split(":");
                        sIDSplit = [
                            sIDSplit[0],
                            sIDSplit.filter((_, idx) => idx !== 0).join(":")
                        ];
                    } else {
                        sIDSplit = ["-", "-"];
                    };

                    tmp.push([
                        { keyID: String(sData.data.ID), type: "text", text: foundCountry.CountryName },
                        {
                            keyID: String(sData.data.ID), type: "custom", data: <p style={{ textAlign: "center" }}>
                                {(curUserFlagsSelector?.isAdmin || curUserFlagsSelector?.isCCManager) && <span>[{sIDSplit[0]}] </span>}
                                <span style={{ color: "rgb(170, 169, 251)" }}>{sIDSplit[1]}</span>
                            </p>
                        },
                        (sData.data.ShippmentLabel ? { keyID: String(sData.data.ID), type: "text", text: `${sData.data.ShippmentLabelPrefix} ${fillString(sData.data.ShippmentLabel)}` } : null),
                        (sData.data.ShippmentTrackingCode ? { keyID: String(sData.data.ID), type: "text", text: sData.data.ShippmentTrackingCode } : null),
                        { keyID: String(sData.data.ID), type: "text", text: new Date(sData.data.createdAt).toLocaleString() }
                    ].filter(t => t));

                    return tmp;
                })()}
            />
            <FilteredCustomTable
                theme="dark"
                accent="#6664E5"
                filterCB={d => setFilters(d)}
                filters={[
                    { name: "updatedAt", friendlyName: "Datum", type: "date" },
                    {
                        name: "ShippmentStatus", friendlyName: "Status", type: "custom", varType: "number", data: Object.keys(shipmentStatusCodes).map(key => {
                            return { text: shipmentStatusCodes[key], value: String(key) }
                        })
                    }
                ]}
                headers={curUserFlagsSelector?.isAdmin ? ["Datum", "Status", "Eksterni status"] : ["Datum", "Status"]}
                data={(() => {
                    if (!data) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                    if (data.status === "error") return [[{ keyID: "dataErrorText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške prilikom dohvatanja statusa</p> }]]
                    if (data.data.length === 0) return [[{ keyID: "noDataText", type: "custom", data: <p style={{ textAlign: "center" }}>Nema statusa za prikaz</p> }]];
                    let tmp = data.data.map(item => {
                        let dealShipColor = null;
                        if (item.ShippmentStatus !== null && item.ShippmentStatus !== undefined) {
                            dealShipColor = getDealShipColor(Number(item.ShippmentStatus));
                        };
                        return [
                            { keyID: String(item.ID), type: "text", text: new Date(item.updatedAt).toLocaleString() },
                            (curUserFlagsSelector?.isAdmin ? {
                                keyID: String(item.ID), type: "custom", data: <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                    {(item.ShippmentStatus !== null && item.ShippmentStatus !== undefined) && <div className="route__adminPanel__leads__statusCodes">
                                        <p>{shipmentStatusCodes[item.ShippmentStatus]}</p>
                                        <div className="route__adminPanel__leads__statusCodes__lines" style={{
                                            gridTemplateColumns: `repeat(${Object.keys(shipmentStatusCodes).length},1fr)`
                                        }}>
                                            {Object.keys(shipmentStatusCodes).map(key => {
                                                return <span style={{
                                                    backgroundColor: key <= item.ShippmentStatus ? dealShipColor : "gray"
                                                }}></span>
                                            })}
                                        </div>
                                    </div>}
                                </div>
                            } : null),
                            { keyID: String(item.ID), type: "text", text: item.ExternalShippmentStatus }
                        ].filter(t => t);
                    });

                    if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
                    if (canPaginate) tmp.push([{
                        keyID: "paginationData",
                        type: "custom",
                        data: <PaginationData />
                    }]);

                    return tmp;
                })()}
            />
        </div>
    </div>
};

const EditDealShipmentModal = (props) => {
    const statusCodesSelector = useSelector(state => state.statusCodes.shipment ?? {});
    const [selectedStatus, setSelectedStatus] = React.useState();
    //updateShippmentStatusAdmin
    const updateStatus = () => {
        if (!props?.data.data[0]) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/updateShippmentHistoryStatusAdmin`,
            data: {
                ID: props?.data.data[0].ID,
                ShipmentStatus: selectedStatus
            },
            ...backendModule.axiosConfig
        }).then(res => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/deals/updateShippmentStatusAdmin`,
                data: {
                    ID: props?.data.data[0].ShippmentID,
                    ShipmentStatus: selectedStatus
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    props.onClose();
                    props.updateData();
                    
                }
            })
        })
    }
    return <div className="modal__editDealShipment">
        <div className="modal__editDealShipment__container">
            <p>Izaberite status posiljke</p>
            <Dropdown theme="dark" accent="#3D3D55" data={Object.keys(statusCodesSelector).map(elem => {
                return {
                    name: `[${elem}] ${statusCodesSelector[elem]}`,
                    value: elem
                }
            })} selected={props?.data.data[0]?.ShippmentStatus} onChange={e => setSelectedStatus(e.value)} />
            <div className="modal__editDealShipment__container__buttons">
                <p onClick={() => { updateStatus() }}>Save</p>
                <p onClick={() => { props.onClose() }}>Cancel</p>
            </div>
        </div>

    </div>
}

export default DealShipmentHistory;