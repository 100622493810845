import React from "react";
import "./index.scss";

import moment from "moment";
import "moment/locale/bs";
import { useSelector, useDispatch } from "react-redux";
import emojiToolkit from "emoji-toolkit";

import * as socketModule from "../../../modules/socketModule";
import * as chatActions from "../../../actions/chatAction";
import { animateBox } from "../../../modules/componentAnimation";
import * as backendModule from "../../../modules/backendModule";

import * as MessageTypes from "./MessageTypes";
import { addChangedHandlers, removeChangedHandlers } from "../Sidebar";
import CustomInput from "../../customComponents/CustomInput";
import Dropdown from "../../customComponents/Dropdown";
import Leads from "../../../routes/CCManager/Leads";
import Deals from "../../../routes/CCManager/Deals";
import Tasks from "../../Tasks";

const Content = props => {
    return <div className="component__chat__wrap__content" style={{ backgroundImage: "url(/images/chatBackground.png)" }}>
        <ContentHeader
            activeChat={props.activeChat}
            sidebar={props.sidebar}
            onClose={props.onClose}
        />
        <ContentChat activeChat={props.activeChat} />
        <ContentSendMessage activeChat={props.activeChat} />
    </div>
};

const ContentHeader = props => {
    const [editNameVisible, setEditNameVisible] = React.useState(false);

    const chatRoomsSelector = useSelector(state => state.chatRooms ?? []);
    const chatRoomSelector = chatRoomsSelector?.find(cu => cu.ID === props?.activeChat) ?? null;
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const getUserActivityStatus = ID => {
        if (ID === null || ID === undefined) return "red";
        if (ID < 0) return "#6664E5";

        let cUser = chatUsersSelector.find(cu => cu.ID === ID);
        if (!cUser) return "red";

        if (!cUser.LastSeen) return "gray";
        if (Date.now() - cUser.LastSeen <= 30000) return "green";

        return "gray";
    };

    const generateChatRoomName = IDs => {
        let tmp = IDs.filter(ID => ID !== curUserSelector.ID).map(ID => {
            let found = chatUsersSelector.find(cu => cu.ID === ID);
            if (found) {
                if (IDs.length > 2) {
                    return found.FirstName;
                } else {
                    return `${found.FirstName} ${found.LastName}`;
                };
            }
            return "?";
        });

        if (tmp.length === 0) return <span style={{ color: "gray" }}>[Nema sudionika]</span>;
        let final = tmp.join(", ");
        if (final.length >= 50) final.substring(0, 47);

        return final;
    };

    const getUserImage = (IDs, excludeMe = true) => {
        if (IDs.find(ID => ID < 0)) return "/images/robo.png";
        if (IDs.length > 2) return "/images/chat_userGroup.svg";

        let curID = IDs;
        if (excludeMe) {
            curID = IDs.find(ID => ID !== curUserSelector.ID);
            if (!curID) return "/images/undefined.png";
        };

        let finalUser = chatUsersSelector.find(cu => cu.ID === curID);
        if (!finalUser) return "/images/undefined.png";

        return finalUser.Image ?? "#";
    };

    return <div className="component__chat__wrap__content__header">
        <RenameChat isVisible={editNameVisible} chatName={chatRoomSelector?.ChatRoomName} onClose={() => {
            setEditNameVisible(null);
        }} roomID={chatRoomSelector?.ID} />
        <div className="component__chat__wrap__content__header__left">
            {!props.sidebar.sidebarOpen && <img src="/images/chat_hamburger.svg" className="component__chat__wrap__content__header__left__sidebar" onClick={() => {
                props.sidebar.setSidebarOpen(true);
            }} />}
            {chatRoomSelector && <>
                <div className="component__chat__wrap__content__header__left__img">
                    <img onError={e => e.currentTarget.src = "/images/undefined.png"} src={getUserImage(chatRoomSelector.Participants)} />
                    <span className="component__chat__wrap__content__header__left__img__activity" style={{ backgroundColor: getUserActivityStatus(chatRoomSelector.Participants.find(ID => ID !== curUserSelector.ID)) }}></span>
                </div>
                <div className="component__chat__wrap__content__header__left__info">
                    <p className="component__chat__wrap__content__header__left__info__user">
                        {chatRoomSelector.ChatRoomName ? chatRoomSelector.ChatRoomName : generateChatRoomName(chatRoomSelector.Participants)}
                        {chatRoomSelector?.CreatedBy === curUserSelector.ID && <img src="/images/chat_edit.svg" className="component__chat__wrap__content__header__left__info__user__edit" onClick={(e) => {
                            setEditNameVisible(t => {
                                if (t) return null;
                                return {
                                    x: e.pageX,
                                    y: e.pageY
                                };
                            });
                        }} />}
                    </p>
                    {chatRoomSelector.Participants.length <= 2 ? <p className="component__chat__wrap__content__header__left__info__status">
                        {(() => {
                            let otherParticipant = chatRoomSelector.Participants.filter(p => p !== curUserSelector.ID);
                            if (otherParticipant.length !== 1) return "?";
                            let cu = chatUsersSelector.find(cu => cu.ID === otherParticipant[0]);
                            if (!cu) return "?";
                            if (cu.ID < 0) cu.LastSeen = moment();

                            if (moment(cu.LastSeen).isAfter(moment().add("-30", "seconds"))) return "Aktivan";
                            let tmp = moment(cu.LastSeen).locale("bs").fromNow().split("");
                            return [tmp.shift().toUpperCase(), ...tmp].join("");
                        })()}
                        <p className="component__chat__wrap__content__header__left__info__userCount" style={{ marginLeft: "10px" }} onClick={e => {
                            animateBox(e, <AllUsers room={chatRoomSelector} />);
                        }}>Prikaži sve ({chatRoomSelector.Participants.length})</p>
                    </p> : <div className="component__chat__wrap__content__header__left__info__statusMultiple">
                        {chatRoomSelector.Participants.filter((_, idx) => idx <= 3).map((p, index) => {
                            if (index < 3) {
                                return <div className="component__chat__wrap__content__header__left__info__statusMultiple__usr">
                                    <img src={getUserImage([p]) ?? "#"} onError={e => e.currentTarget.src = "/images/undefined.png"} />
                                    <span style={{
                                        backgroundColor: getUserActivityStatus(p)
                                    }} className="component__chat__wrap__content__header__left__info__statusMultiple__usr__activity"></span>
                                </div>
                            }
                        })}
                        <p className="component__chat__wrap__content__header__left__info__userCount" onClick={e => {
                            animateBox(e, <AllUsers room={chatRoomSelector} />);
                        }}>Prikaži sve ({chatRoomSelector.Participants.length})</p>
                    </div>}
                </div>
            </>}
        </div>

        <div className="component__chat__wrap__content__header__right">
            <img src="/images/chat_close.svg" onClick={props.onClose} />
        </div>
    </div>
};

const RenameChat = props => {
    const mainRef = React.useRef();
    const chatNameRef = React.useRef();

    React.useEffect(() => {
        if (props.isVisible) {
            if (mainRef.current) {
                mainRef.current.style.top = (props.isVisible?.y + 50) + "px";
                mainRef.current.style.left = (props.isVisible?.x - 160) + "px";
            };
        } else {
            setTimeout(() => chatNameRef.current.value = props.chatName, 200);
        };
    }, [props.isVisible]);

    React.useEffect(() => {
        if (chatNameRef.current) chatNameRef.current.value = props.chatName;
    }, [props.chatName]);

    const renameChat = () => {
        if (props.roomID === null || props.roomID === undefined) return props.onClose();

        socketModule.internal__changeChatRoomName(props.roomID, chatNameRef.current.value);
        setTimeout(() => socketModule.getSocket().emit("getAllChatRooms"), 200);
        props.onClose();
    };

    return <div className={`component__chat__wrap__content__header__rename ${props.isVisible ? "component__chat__wrap__content__header__rename--active" : ""}`} ref={mainRef} >
        <CustomInput ref={chatNameRef} theme="dark" accent="#969698" placeholder="Naziv razgovora" style={{
            marginTop: "10px"
        }} />
        <div className="component__chat__wrap__content__header__rename__buttons">
            <div className="component__chat__wrap__content__header__rename__buttons__button" onClick={renameChat}>Spremi</div>
            <div className="component__chat__wrap__content__header__rename__buttons__button" onClick={props.onClose}>Zatvori</div>
        </div>
    </div>
};

const ContentChat = props => {
    const [animated, setAnimated] = React.useState(false);
    const [autoScroll, setAutoScroll] = React.useState(null);

    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const chatRoomSelector = useSelector(state => state?.chatRooms?.filter(t => t.ID === props.activeChat)?.[0] ?? null);
    const chatSelector = useSelector(state => state?.chats?.[chatRoomSelector?.ID] ?? null);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {})

    const mainRef = React.useRef();

    const getUserImage = (ID) => {
        let curUser = chatUsersSelector.find(usr => usr.ID === ID);
        if (!curUser) return "/images/udefined.png";

        return curUser.Image ?? "/images/undefined.png";
    };

    const getUserData = ID => {
        let curUser = chatUsersSelector.find(usr => usr.ID === ID);
        if (!curUser) return null;

        return curUser;
    };

    React.useEffect(() => {
        if (props.activeChat < 0) {
            setAnimated(false);
        };

        if (!mainRef.current) return;

        let handler = e => {
            let curHeight = e.currentTarget.getBoundingClientRect().height;
            let curScrollBottom = (e.currentTarget.scrollHeight - curHeight) - e.currentTarget.scrollTop;
            if (curScrollBottom < 20) {
                setAutoScroll(null);
            } else {
                setAutoScroll(e.currentTarget.scrollHeight);
            };
        };

        mainRef.current.addEventListener("scroll", handler);

        return () => {
            try {
                mainRef.current.removeEventListener("scroll", handler);
            } catch { };
        };
    }, [props.activeChat]);

    React.useLayoutEffect(() => {
        if (!autoScroll) return;

        let newHeight = mainRef.current.scrollHeight - autoScroll;

        mainRef.current.scrollTo({
            top: (mainRef.current.scrollTop + newHeight) ?? 0,
            behavior: "auto"
        });
    }, [chatSelector]);

    React.useEffect(() => {
        let handler = () => {
            setAutoScroll(null);
        };

        addChangedHandlers(handler);

        return () => removeChangedHandlers(handler);
    }, []);

    return <div className="component__chat__wrap__content__chat" ref={mainRef}>
        <div className="component__chat__wrap__content__chat__noChat" style={{
            opacity: props.activeChat ? 0 : 1,
            pointerEvents: props.activeChat ? "none" : "all"
        }}>
            <p>Kliknite na razgovor sa lijeve strane</p>
            <p>ili kreirajte novi razgovor</p>
        </div>

        {chatSelector && chatSelector.map((chat, chatID) => {
            let out = [];

            let childProps = {
                key: `chat-${chatRoomSelector?.ID}-${chat.ID}`,
                chatRoomID: chatRoomSelector?.ID,
                messageID: chatID,
                message: chat,
                previous: chatID === 0 ? null : chatSelector[chatID - 1],
                userImage: getUserImage(chat.From),
                userData: getUserData(chat.From),
                curUser: curUserSelector,
                scrollIntoView: (autoScroll === null && chatID === chatSelector.length - 1),
                animated: { animated, setAnimated },
                pingNotifications: (chatID === chatSelector.length - 1) && chatRoomSelector?.UnreadCount > 0,
            }

            if (chat.hasSpinner) {
                out.push(<MessageTypes.PaginateSpinner key={`chatPagination-${chat.ID}`} room={chatRoomSelector?.ID} chat={chat.ID} />);
            };
            if (chat.Type === "text") {
                out.push(<MessageTypes.TextMessage {...childProps} />)
            } else if (["video", "image", "file"].includes(chat.Type) && !chat.Content) {
                out.push(<MessageTypes.SpinnerMessage {...childProps} />);
            } else if (chat.Type === "image") {
                out.push(<MessageTypes.ImageMessage {...childProps} />);
            } else if (chat.Type === "file") {
                out.push(<MessageTypes.FileMessage {...childProps} />);
            } else if (chat.Type === "video") {
                out.push(<MessageTypes.VideoMessage {...childProps} />);
            } else if (chat.Type === "lead" || chat.Type === "deal") {
                out.push(<MessageTypes.LeadDealMessage {...childProps} type={chat.Type} />);
            } else if (chat.Type === "note") {
                out.push(<MessageTypes.NoteMessage {...childProps} />)
            } else if (chat.Type === "task") {
                out.push(<MessageTypes.TaskMessage {...childProps} />)
            } else if (chat.Type === "recording") {
                out.push(<MessageTypes.RecordingMessage {...childProps} />)
            };
            //
            return out;
        })}
    </div>
};

const ContentSendMessage = props => {
    const chatDispatch = useDispatch();
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const chatRoomsSelector = useSelector(state => state?.chatRooms ?? []);
    const chatUsersSelector = useSelector(state => state?.chatUsers ?? []);


    const textRef = React.useRef();

    const fileInputRef = React.useRef();
    const imageInputRef = React.useRef();
    const videoInputRef = React.useRef();

    const sendMessage = () => {
        if (!props.activeChat) return;
        if (!textRef?.current) return;
        if (!textRef.current.value.trim()) return;

        let filtered = textRef.current.value.trim().split(" ");
        let curRoom = chatRoomsSelector.find(cu => cu.ID === props.activeChat);

        let skipNext = false;

        filtered = filtered.map(item => {
            let tmp = item.split("\n");
            let out = [];
            tmp.forEach((f, fIndex) => {
                if (fIndex === tmp.length - 1) {
                    out.push(f);
                } else {
                    out.push(f, "\n");
                };
            });
            return out;
        }).flat();

        filtered = filtered.map((item, index) => {
            if (skipNext) {
                skipNext = false;
                return null;
            };
            if (!curRoom) return item;

            if (item.startsWith("@")) {
                let tmp = item.substring(1, item.length).toLowerCase();
                if (!tmp) return item;

                if (filtered.length - 1 >= index + 1) {
                    let tmp2 = `${tmp} ${filtered[index + 1]}`.toLowerCase();
                    for (let usr of chatUsersSelector.filter(u => curRoom.Participants.includes(u.ID))) {
                        if (tmp2 === `${usr.FirstName ?? ""} ${usr.LastName ?? ""}`.toLowerCase()) {
                            skipNext = true;
                            return `<#mention:${usr.ID}>`;
                        };
                    };
                    for (let usr of chatUsersSelector.filter(u => curRoom.Participants.includes(u.ID))) {
                        if (`${usr.FirstName ?? ""} ${usr.LastName ?? ""}`.toLowerCase().includes(tmp2)) {
                            skipNext = true;
                            return `<#mention:${usr.ID}>`;
                        };
                    };
                };

                for (let usr of chatUsersSelector.filter(u => curRoom.Participants.includes(u.ID))) {
                    if (tmp === `${usr.FirstName ?? ""} ${usr.LastName ?? ""}`.toLowerCase()) {
                        return `<#mention:${usr.ID}>`;
                    };
                };
                for (let usr of chatUsersSelector.filter(u => curRoom.Participants.includes(u.ID))) {
                    if (`${usr.FirstName ?? ""} ${usr.LastName ?? ""}`.toLowerCase().includes(tmp)) {
                        return `<#mention:${usr.ID}>`;
                    };
                };
                if (tmp === "svi") {
                    return `<#mention:all>`;
                };
            };
            return item;
        });

        let finalChatText = "";
        filtered.forEach((fc, fIdx) => {
            if (fc === null) return;
            finalChatText += fc;
            if (fIdx !== filtered.length - 1 && fc !== "\n") finalChatText += " ";
        });
        finalChatText = finalChatText.split("\n").map(t => t.trim()).join("\n")

        socketModule.internal__sendMessage(props.activeChat, curUserSelector.ID, "text", emojiToolkit.toShort(finalChatText));
        textRef.current.value = null;
    };

    const sendLeadDeal = (type, ID) => {
        if (!type || !ID) return;
        if (type !== "lead" && type !== "deal") return;

        socketModule.internal__sendMessage(props.activeChat, curUserSelector.ID, type, ID);
    };

    const sendTaskNote = (type, data) => {
        if (!type) return;
        if (type !== "task" && type !== "note") return;

        socketModule.internal__sendMessage(props.activeChat, curUserSelector.ID, type, data);
    };

    const sendFile = (files, type) => {
        if (!files || !type) return;

        const curChat = backendModule.getStore()?.getState()?.chatRooms?.find(t => t.ID === props.activeChat);
        if (!curChat) return;

        let lastMessageID = backendModule.getStore()?.getState()?.chats?.[curChat.ID];
        if (lastMessageID && lastMessageID[lastMessageID.length - 1]) {
            lastMessageID = lastMessageID[lastMessageID.length - 1].ID;
        } else {
            lastMessageID = 1;
        };
        let curID = lastMessageID;

        for (let file of files) {
            let roundNumber = `${Math.floor(Math.random() * 500000)}-${Date.now()}`;
            curID += 0.00001;
            chatDispatch(chatActions.addChat({
                [curChat.ID]: [{
                    ID: curID,
                    rnd: roundNumber,
                    From: curUserSelector.ID,
                    Type: type,
                    Content: null
                }]
            }));
            socketModule.internal__sendMessage(curChat.ID, curUserSelector.ID, type, {
                file: file,
                name: file.name,
                rnd: roundNumber
            });
        };
    };

    React.useEffect(() => {
        if (!textRef?.current) return;
        textRef.current.value = "";

        const handler = e => {
            e.currentTarget.value = emojiToolkit.shortnameToUnicode(e.currentTarget.value);
            if (
                e.shiftKey ||
                e.ctrlKey ||
                e.altKey
            ) return;
            if (e.keyCode === 13) {
                e.preventDefault();
                sendMessage();
            };
        };

        textRef.current.addEventListener("keydown", handler);

        return () => {
            try {
                textRef.current.removeEventListener("keydown", handler);
            } catch { };
        };
    }, [props.activeChat]);

    if(!curUserSelector?.Flags?.isAdmin && !curUserSelector?.Flags?.isCCManager && !curUserSelector?.Flags?.isShippmentAgent && !curUserSelector?.Flags?.isLegal && !curUserSelector?.Flags?.isAdministration && !curUserSelector?.Flags?.isDemo && !curUserSelector?.Flags?.isMarketing){
        return <></>
    }

    return <div className={`component__chat__wrap__content__sendMessage ${(props.activeChat && (props.activeChat > 0)) ? "component__chat__wrap__content__sendMessage--active" : ""}`}>
        <textarea lines="3" ref={textRef} type="text" placeholder="Unesite poruku..." className="component__chat__wrap__content__sendMessage__input" />
        <div className="component__chat__wrap__content__sendMessage__sendBtn" onClick={sendMessage}>Pošalji</div>
        <div className="component__chat__wrap__content__sendMessage__uploads">
            <img title="Pošalji fajl" src="/images/chat_attachmentUpload.svg" onClick={() => fileInputRef?.current?.click()} />
            <input ref={fileInputRef} onChange={e => sendFile(e.target.files, "file")} type="file" style={{ 'display': 'none' }} accept="application/*, xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf" multiple />

            <img title="Pošalji sliku" src="/images/chat_imageUpload.svg" onClick={() => imageInputRef?.current?.click()} />
            <input ref={imageInputRef} onChange={e => sendFile(e.target.files, "image")} type="file" style={{ 'display': 'none' }} accept="image/*" multiple />

            <img title="Pošalji video" src="/images/chat_videoUpload.svg" onClick={() => videoInputRef?.current?.click()} />
            <input ref={videoInputRef} onChange={e => sendFile(e.target.files, "video")} type="file" style={{ 'display': 'none' }} accept="video/*" multiple />

            {(curUserSelector?.Flags?.isAdmin || curUserSelector?.Flags?.isCCManager || curUserSelector?.Flags?.isShippmentAgent || curUserSelector?.Flags?.isCCTeamLeader) && <>
                <div className="component__chat__wrap__content__sendMessage__kanban" onClick={(e) => {
                    animateBox(e, <Leads
                        onSelect={lead => {
                            sendLeadDeal("lead", lead.ID);
                        }} />);
                }}>
                    <img title="Pošalji lead" src="/images/chat_lead.svg" />
                </div>
            </>}
            {(curUserSelector?.Flags?.isAdmin || curUserSelector?.Flags?.isCCManager || curUserSelector?.Flags?.isShippmentAgent) && <>
                <div className="component__chat__wrap__content__sendMessage__kanban" onClick={(e) => {
                    animateBox(e, <Deals
                        onSelect={deal => {
                            sendLeadDeal("deal", deal.ID);
                        }} />);
                }}>
                    <img title="Pošalji deal" src="/images/chat_deal.svg" />
                </div>
            </>}

            <div className="component__chat__wrap__content__sendMessage__kanban" onClick={(e) => {
                animateBox(e, <Tasks
                    onSelect={(task) => {
                        sendTaskNote(task.type, task.data);
                    }} />);
            }}>
                <img title="Pošalji zadatak / bilješku" src="/images/taskAdd.svg" />
            </div>
        </div>
    </div>
};


const AllUsers = props => {
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const chatRoomsSelector = useSelector(state => state.chatRooms ?? []);

    const data = chatRoomsSelector?.find(cu => cu.ID === props?.room?.ID) ?? {};

    const getUserActivityStatus = ID => {
        if (ID === null || ID === undefined) return "red";
        if (ID < 0) return "#6664E5";

        let cUser = chatUsersSelector.find(cu => cu.ID === ID);
        if (!cUser) return "red";

        if (!cUser.LastSeen) return "gray";
        if (Date.now() - cUser.LastSeen <= 30000) return "green";

        return "gray";
    };

    React.useEffect(() => {
        if (!data) props.onClose();
    }, [data]);

    const removeUser = (uID = null) => {
        if (uID === null || uID === undefined) return;

        if (uID === curUserSelector.ID) {
            props.onClose().then(() => {
                socketModule.internal__removeUserFromChatRoom(data.ID, uID);
            });
        } else {
            socketModule.internal__removeUserFromChatRoom(data.ID, uID);
        };

        socketModule.internal__removeUserFromChatRoom(data.ID, uID);
        if (uID === curUserSelector.ID) props.onClose();
    };

    if (!data) return null;
    return <div className="component__chat__wrap__content__allUsers">
        <div className="component__chat__wrap__content__allUsers__wrap">
            <div className="component__chat__wrap__content__allUsers__wrap__head">
                <span>Sudionici</span>
                <div className="component__chat__wrap__content__allUsers__wrap__head__btns">
                    {data.CreatedBy === curUserSelector.ID && <div onClick={e => {
                        animateBox(e, <AddUser existing={data.Participants} roomID={data.ID} />);
                    }}>Dodaj korisnika</div>}
                    <img src="/images/closeImg.svg" onClick={props.onClose} />
                </div>
            </div>

            <div className="component__chat__wrap__content__allUsers__wrap__users">
                {(data?.Participants ?? []).map(usr => {
                    let curUser = chatUsersSelector.find(u => u.ID === usr);

                    return <div className="component__chat__wrap__content__allUsers__wrap__users__user">
                        <div className="component__chat__wrap__content__allUsers__wrap__users__user__left">
                            <span className="component__chat__wrap__content__allUsers__wrap__users__user__left__activity" style={{
                                backgroundColor: getUserActivityStatus(curUser?.ID)
                            }}></span>
                            <img src={curUser?.Image ?? "#"} onError={e => e.currentTarget.src = "/images/undefined.png"} />
                            <div className="component__chat__wrap__content__allUsers__wrap__users__user__left__wrap">
                                <p className="component__chat__wrap__content__allUsers__wrap__users__user__left__wrap__name">{curUser?.FirstName ?? "?"} {curUser?.LastName}</p>
                                <p className="component__chat__wrap__content__allUsers__wrap__users__user__left__wrap__status">{(() => {
                                    if (curUser?.ID < 0) curUser.LastSeen = moment();
                                    if (!curUser?.LastSeen) return "?";

                                    if (moment(curUser.LastSeen).isAfter(moment().add("-30", "second"))) return "Aktivan";
                                    let tmp = moment(curUser?.LastSeen).locale("bs").fromNow().split("");
                                    return [tmp.shift().toUpperCase(), ...tmp].join("");
                                })()}</p>
                            </div>
                        </div>



                        <div className="component__chat__wrap__content__allUsers__wrap__users__user__right">
                            {(
                                (
                                    (
                                        data.CreatedBy === curUserSelector.ID &&
                                        usr !== curUserSelector.ID
                                    ) ||
                                    (
                                        data.CreatedBy !== curUserSelector.ID &&
                                        usr === curUserSelector.ID
                                    )
                                ) && (
                                    data.CreatedBy !== -1
                                )
                            ) && <img src="/images/chat_removeUser.svg" onClick={() => removeUser(usr)} />}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
};

const AddUser = props => {
    const [selectedUser, setSelectedUser] = React.useState();

    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);

    const addUser = () => {
        if (!selectedUser) return;

        socketModule.internal__addNewUserInChatRoom(props.roomID, [selectedUser]);
        props.onClose();
    };

    return <div className="component__chat__wrap__content__addUser">
        <div className="component__chat__wrap__content__addUser__wrap">
            <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={chatUsersSelector.filter(usr => {
                return !props.existing.includes(usr.ID);
            }).map(usr => {
                return {
                    name: <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <img src={usr.Image ?? "#"} onError={e => e.currentTarget.src = "/images/undefined.png"} style={{
                            borderRadius: "50%",
                            marginRight: "10px",
                            width: "24px",
                            height: "24px"
                        }} />
                        <span>{usr.FirstName ?? "?"} {usr.LastName ?? ""}</span>
                    </div>,
                    value: usr.ID,
                    search: `${usr.FirstName} ${usr.LastName}`
                }
            })} onChange={e => setSelectedUser(e?.value)} inlinePlaceholder="Odaberite korisnika..." />
            <div className="component__chat__wrap__content__addUser__wrap__buttons">
                <div onClick={addUser}>Dodaj</div>
                <div onClick={props.onClose}>Izlaz</div>
            </div>
        </div>
    </div>
};

export default Content;