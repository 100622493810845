let initialState = {leads:{}, deals:{}}

export default (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_CODES": return {
            ...state,
            ...action.payload.codes
        }
        default: return state;
        
    }
};