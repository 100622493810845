import React, { useCallback } from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useSelector } from "react-redux";
import Dropdown from "../customComponents/Dropdown";
import CustomInput from "../customComponents/CustomInput";
import CustomButtonSmall from "../customComponents/Button";
import translate from "./translate.json"


const WarningInfo = (props) => {
    const warningReactions = useSelector((state) => state.statusCodes.warningReactions);
    const [selectedReaction, setSelectedReaction] = React.useState();
    const comment = React.useRef();
    const [saved, setSaved] = React.useState(true);
    const [data, setData] = React.useState();
    let language = useSelector(state => state.language);


    const saveData = () => {

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/addWarningReaction`,
            data: {
                DealID: props.deal.ID,
                ReactionType: selectedReaction,
                Comment: comment.current.value
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            setSaved(true)
        });
    }
    React.useEffect(() => {
        if (!selectedReaction) return;
        setSaved(false)
    }, [selectedReaction])

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getWarningReactionByDealID`,
            data: {
                DealID: props.deal.ID
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setData(res.data.data)
            }
        }).catch(() => null).finally(() => {
            setSaved(true)
        });
    }, [])

    const translateWarnings=(status)=>{
        if (language === "BA" || language === "SI") {
            return status
        } else if (language === "RO") {
            return {
                "Klijent prihvata pošiljku": "Clientul acceptă expedierea",
                "Klijent prihvata pošiljku uz popust": "Clientul acceptă expedierea cu reducere",
                "Klijent plaća troškove dostave": "Clientul plătește costurile de livrare",
                "Klijent odbija opomenu": "Clientul refuză notificarea",

                "Klijent prihvata platiti 2 ratu": "Clientul este de acord să plătească în 2 rate",
                "Klijent odbija platiti 2 ratu": "Clientul refuză să plătească a doua rată"
            }[status]
        }
    }

    return <div className="component__warning">
        {!data ? <><p>{translate[language].Unesiteradnjukojeklijentizvrsionakondobijeneopomene}</p>
            <Dropdown data={Object.keys(warningReactions)?.map(key => {
                return { name: translateWarnings(warningReactions[key]), value: key }
            })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedReaction(e?.value)} selected={(() => {
                let idx = Object.keys(warningReactions).indexOf(Object.keys(warningReactions).find(ct => String(ct) === String(data?.ReactionType)));
                if (idx < 0) return null; else return idx;
            })()} />
            <p>{translate[language].Unesitekomentarnaklijentovureakciju}: </p>
            <textarea ref={comment} defaultValue={data?.Comment} onChange={() => {
                setSaved(false)
            }}>
            </textarea>
            {!saved && <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />}
        </> : <>
            <p>{translate[language].Datumkreiranja}: {(new Date(data?.createdAt)).toLocaleString()}</p>
            <p>{translate[language].Radnjakojueklijentizvršionakondobijeneopomene}:</p>
            <p>{translateWarnings(warningReactions[data?.ReactionType])}</p>
            <p>{translate[language].Komentarnaklijentovureakciju}: </p>
            <p>{data?.Comment}</p>
        </>
        }
    </div>

}

export default WarningInfo