import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {setAxiosDataHandler} from "./modules/backendModule";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import {HashRouter as  Router} from 'react-router-dom'
import * as backendModule from "./modules/backendModule";
import ModalController from "./components/ModalController";

import { createRoot } from "react-dom/client";
import Snowflakes from 'magic-snowflakes';
import moment from 'moment';

let greetingsItem = window.sessionStorage.getItem("greetings");
if (greetingsItem === null || greetingsItem === undefined) window.sessionStorage.setItem("greetings", "true");
if (window.innerWidth < 800) window.sessionStorage.setItem("greetings", "false");

if (window.Notification?.permission !== "granted") {
  window.Notification?.requestPermission();
};

setAxiosDataHandler();
let store = createStore(allReducers, composeWithDevTools());
backendModule.setStore(store);
const rootElem = createRoot(document.querySelector("#root"));

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

if (moment().get("month") >= 11 || moment().get("month") <= 1) {
  let snowflakes = new Snowflakes({
    color: "white",
    container: document.querySelector("#rootImg"),
    count: 100,
    minOpacity: 0.2,
    maxOpacity: 0.7
  });
};

rootElem.render(
  <Provider store={store}>
  <ModalController />
  <Router>
    <App />
    </Router>
  </Provider>,
);