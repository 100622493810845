import React from "react";

export default () => {
    const curRef = React.useRef();
    const curTimer = React.useRef();

    const runTimer = () => {
        if (typeof(curRef.current) === "function") {
            curRef.current();

            clearTimeout(curTimer.current);
            curTimer.current = null;
        };
    };

    const cb = React.useCallback((fn, delay) => {
        curRef.current = fn;
        if (delay === 0) {
            clearTimeout(curTimer.current);
            curTimer.current = null;
        };
        if (!curTimer.current) curTimer.current = setTimeout(runTimer, delay);
    });

    return cb;
};