import * as backendModule from "./backendModule";
import { animateBox } from "./componentAnimation";

import Chat from "../components/Chat";

export const sendInactivityNotification = (FromID) => {
    if (window.Notification?.permission !== "granted") return;
    let allUsers = backendModule.getStore()?.getState()?.chatUsers ?? [];

    let curUser = allUsers?.find(u => u.ID === FromID);
    new Notification(`[Scale-CRM] Management`, {
        icon: curUser.Image ?? "https://scale-crm.com/images/undefined.png",
        body: `${curUser.FirstName} ${curUser.LastName}: Agent je neaktivan duže od 60 sekundi...`,
        renotify: true,
        requireInteraction: true,
        tag: "ScaleCRM"
    });
};

export const createNewNotification = async (FromID, Type, Content, RoomID) => {
    if (document?.hasFocus()) return;
    if (window.Notification?.permission !== "granted") return;
    if (String(RoomID) === "-1") return;

    let allUsers = backendModule.getStore()?.getState()?.chatUsers ?? [];
    let allRooms = backendModule.getStore()?.getState()?.chatRooms ?? [];

    let curUser = allUsers?.find(u => u.ID === FromID);
    let curRoom = allRooms?.find(r => r.ID === +RoomID);

    const n = new Notification(`[Scale-CRM] ${curRoom?.ChatRoomName}` ?? "[Scale-CRM] Poruka", {
        icon: curUser.Image ?? "https://scale-crm.com/images/undefined.png",
        body: `${curUser.FirstName} ${curUser.LastName}: ${(()=>{
            if (Type === "text") {
                return `${String(Content).substring(0, 100)}...`
            } else {
                return "[Privitak]";
            };
        })()}`,
        renotify: true,
        requireInteraction: true,
        tag: "ScaleCRM"
    });
    n.onclick = () => {
        let chatBtn = document.querySelector(".component__header__user__chat--main");
        let chat = document.querySelector(".component__chat");

        if (!chat && chatBtn) {
            animateBox({currentTarget: chatBtn}, <Chat activeChat={RoomID} />);
        };
    };

    const visibilityHandler = () => {
        if (document.visibilityState === 'visible') {
          n.close();
          removeEvents();
        };
    };
    const closeHandler = () => {
        n.close();
        removeEvents();
    };
    const removeEvents = () => {
        document.removeEventListener("visibilitychange", visibilityHandler);
        document.removeEventListener("focus", closeHandler);
    };

    document.addEventListener('visibilitychange', visibilityHandler);
    document.addEventListener("focus", closeHandler);
};