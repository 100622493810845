import React from "react";
import "./index.scss";

const YesNoModal = (props) => {
    const internalRef = React.useRef();

    const closeModal = () => {
        if (!internalRef.current) return props.onClose();
        internalRef.current.style.pointerEvents = "none";
        internalRef.current.animate([
            {opacity: getComputedStyle(internalRef.current).opacity},
            {opacity: 0}
        ], {
            duration: 300,
            iterations: 1,
            easing: "ease-in-out",
            fill: "both"
        }).onfinish = props.onClose;
    };

    return <div className={`modal__yesNo ${props.theme === "dark" ? "modal__yesNo--dark" : ""}`} ref={internalRef}>
        <p className="modal__yesNo__heading">{props.heading ?? ""}</p>
        <p className="modal__yesNo__text">{props.text ?? ""}</p>
        <div className="modal__yesNo__wrap">
            {!props.hideYes && <div onClick={() => {
                if (props.onYes) props.onYes();
                closeModal();
            }} style={{
                gridColumn: props.hideNo ? "1 / span all" : null
            }} className="modal__yesNo__wrap__button modal__yesNo__wrap__button--green">{props.textYes ?? "Da"}</div>}
            {!props.hideNo && <div onClick={() => {
                if (props.onNo) props.onNo();
                closeModal();
            }} style={{
                gridColumn: props.hideYes ? "1 / span all" : null
            }} className="modal__yesNo__wrap__button modal__yesNo__wrap__button--gray">{props.textNo ?? "Ne"}</div>}
        </div>
    </div>
};

export default YesNoModal;