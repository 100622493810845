import { combineReducers } from "redux";
import timeStampReducer from "./timestampReducer";
import userDataReducer from "./userReducer"
import statusCodesReducer from "./statusCodesReducer";
import chatUsersReducer from "./chatUsersReducer";
import chatRoomsReducer from "./chatRoomsReducer";
import chatReducer from "./chatReducer";
import sipReducer from "./sipReducer";
import modalReducer from "./modalReducer";
import activityReducer from "./activityReducer";
import languageReducer from "./languageReducer";
import notificationsReducer from "./notificationsReducer";
import backendTasksReducer from "./backendTasksReducer";
import refreshReducer from "./refreshReducer";

export default combineReducers({
    timeStamp: timeStampReducer,
    userData: userDataReducer,
    statusCodes: statusCodesReducer,
    chatUsers: chatUsersReducer,
    chatRooms: chatRoomsReducer,
    chats: chatReducer,
    sip: sipReducer,
    modal: modalReducer,
    activity: activityReducer,
    language: languageReducer,
    notifications: notificationsReducer,
    refresh: refreshReducer,

    backendTasks: backendTasksReducer
});