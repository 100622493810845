import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { on as socketOn, off as socketOff } from "../../modules/socketModule";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import Dropdown from "../customComponents/Dropdown";

const AgentSector = (props) => {
    const userData = useSelector((state) => state.userData);
    return <div className="component__agentSector">
        <img className="route__crm__agentStatistic__close" src="/images/close.png" onClick={() => { props.onClose() }} />
        <div className="component__agentSector__head">
            <p>Agent panel 	&#62;</p>
            <div className="component__header__user__image" onClick={(e) => {props.onClose() }}>
                <img alt='' style={{borderRadius:'50%'}} src={userData?.userData?.UserInfo?.UserImage !== null ? userData?.userData?.UserInfo?.UserImage : '/images/undefined.png'}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/undefined.png";
                    }}
                />
                <img alt="" src={userData?.userData?.AgentInfo.ActiveSector === 'leadPartner' ? '/images/mainPartner.png' :
                    userData?.userData?.AgentInfo.ActiveSector === 'seniorPartner' ? '/images/seniorAgent.png' :
                        userData?.userData?.AgentInfo.ActiveSector === 'juniorPartner' ? '/images/juniorAgent.png' : '/images/agent.png'} />
            </div>
        </div>
        <div className="component__agentSector__container">
            <div className="component__agentSector__container__head">
                <img alt="" src="/images/agent.png" />
                <p>Agent</p>
                <div>
                    {(userData?.userData?.AgentInfo.ActiveSector === 'agent' || userData?.userData?.AgentInfo.ActiveSector === null) ?
                        <div className="component__agentSector__container__progres">
                            <div></div>
                        </div> : <div className="component__agentSector__container__border"></div>}
                </div>
                <img alt="" src="/images/juniorAgent.png" />
                <p>Junior partner</p>
                <div>
                    {(userData?.userData?.AgentInfo.ActiveSector === 'juniorPartner') ?
                        <div className="component__agentSector__container__progres">
                            <div></div>
                        </div> : <div className="component__agentSector__container__border"></div>}
                </div>
                <img alt="" src="/images/seniorAgent.png" />
                <p>Senior partner</p>
                <div>
                    {(userData?.userData?.AgentInfo.ActiveSector === 'seniorPartner') ?
                        <div className="component__agentSector__container__progres">
                            <div></div>
                        </div> : <div className="component__agentSector__container__border"></div>}
                </div>
                <img alt="" src="/images/mainPartner.png" />
                <p>Main partner</p>
            </div>
            <div className="component__agentSector__container__single">
                <img alt="" src="/images/juniorAgent.png" />
                <p>Junior partner</p>
                <div>
                    <h4>Uslovi za Junior Partner agenta:</h4>
                    <div>
                        <div>
                            <p>Uslovi: </p>
                            <ul>
                                <li>AR 65%</li>
                                <li>Vrijednost 65 € + </li>
                                <li>Promet 35,000 €</li>
                                <li>U firmi min 3 mjeseca</li>
                            </ul>
                        </div>
                        <div>
                            <p>Beneficije:</p>
                            <ul>
                                <li>Plata 1250 KM</li>
                                <li>Bonuse do 250 €</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="component__agentSector__container__single">
                <img alt="" src="/images/seniorAgent.png" />
                <p>Senior partner</p>
                <div>
                    <h4>Uslovi za Senior Partner agenta:</h4>
                    <div>
                        <div>
                            <p>Uslovi: </p>
                            <ul>
                                <li>AR 65%</li>
                                <li>Vrijednost 75 € + </li>
                                <li>Promet 50,000 €</li>
                                <li>U firmi min 6 mjeseci</li>
                            </ul>
                        </div>
                        <div>
                            <p>Beneficije:</p>
                            <ul>
                                <li>Plata 1500 KM</li>
                                <li>Bonuse do 500 €</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="component__agentSector__container__single">
                <img alt="" src="/images/mainPartner.png" />
                <p>Main partner</p>
                <div>
                    <h4>Uslovi za Main Partner agenta:</h4>
                    <div>
                        <div>
                            <p>Uslovi: </p>
                            <ul>
                                <li>AR 80%</li>
                                <li>Vrijednost 80 € + </li>
                                <li>Promet 70,000 €</li>
                                <li>U firmi min 12 mjeseci</li>
                            </ul>
                        </div>
                        <div>
                            <p>Beneficije:</p>
                            <ul>
                                <li>Plata 2000 KM</li>
                                <li>Bonuse do 1000 €</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

}

export default AgentSector