import React, { useCallback, useState } from "react";
import "./index.scss";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import * as modalActions from "../../actions/modalActions";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";
import { on as socketOn, off as socketOff } from "../../modules/socketModule";
import { getDealStatusColor2, getLeadStatusColor2, getDealShipColor, getDealSaveShipColor, getUnpaidRemindersStatusColor, getRecurringPaymentColor } from "../../modules/leadModule";
import AudioPlayer from "../customComponents/AudioPlayer";
import CustomButtonSmall from "../customComponents/Button";
import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import Dropdown from "../customComponents/Dropdown";
import { FilteredCustomTable } from "../customComponents/Table";
import SendMessage from "./sendMessage";
import WarningInfo from "./warningInfo";
import ImportDocument from "../../routes/ShippmentAgent/ImportDocuments";
import SingleClient from "../SingleClient";

import DealShipmentHistory from "../../components/DealShipmentHistory";
import YesNoModal from "../ModalController/modals/YesNoModal";
import translate from "./translate.json";

const SingleLead = (props) => {
    const [data, setData] = React.useState(props.data);
    const [leadInfo, setLeadInfo] = React.useState([]);
    const [currency, setCurency] = React.useState();
    const [countryName, setCountryName] = React.useState("?");
    const [allCities, setAllCities] = React.useState(['Nepoznato']);
    const [activeCity, setActiveCity] = React.useState();
    const [activeTab, setActiveTab] = React.useState(0);
    const [showHistory, setShowHistory] = React.useState(false);
    const [ccUsers, setCCusers] = React.useState([]);
    const userData = useSelector((state) => state.userData.userData.UserInfo.Flags.isAdmin);
    const isMarketing = useSelector((state) => state.userData.userData.UserInfo.Flags.isMarketing);
    const isCCManager = useSelector((state) => state.userData.userData.UserInfo.Flags.isCCManager);
    const isShippmentAgent = useSelector((state) => state.userData.userData.UserInfo.Flags.isShippmentAgent);
    let userInfo = useSelector((state) => state.userData.userData.UserInfo.Flags);
    let userCC = useSelector((state) => state.userData.userData.UserInfo.CC);
    let language = useSelector(state => state.language);

    const mainRef = React.useRef();

    const userFlags = useSelector((state) => state?.userData?.userData?.UserInfo?.Flags ?? {});

    React.useEffect(() => {
        if (!userCC) return;
        getAllUsersForCC();
    }, [])
    const getAllUsersForCC = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersForCC`,
            data: { cc: userCC },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCCusers(res.data.data)
            };
        }).catch(() => {
        });
    }

    let canEdit = false;

    if (props.type === "deal") {
        if (
            userFlags["isAdmin"] === true ||
            userFlags["isCCManager"] === true
        ) canEdit = true;
    } else {
        if (
            userFlags["isAdmin"] === true ||
            userFlags["isCCManager"] === true
        ) {
            canEdit = true;
        };
    };

    const onClose = () => {
        if (!mainRef?.current) return props.onClose();

        mainRef.current.animate([
            { opacity: getComputedStyle(mainRef.current).opacity },
            { opacity: 0 }
        ], {
            duration: 200,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
        props.onClose();
    };

    React.useEffect(() => {
        sendOpenInfo();
    }, [])
    const sendOpenInfo = () => {
        let url = props.type === "deal" ? "deals/openInfo" : "leads/openInfo";
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/${url}`,
            data: {
                Deal: props.data
            },
            ...backendModule.axiosConfig
        }).then((res) => null).catch(() => null);
    }

    const finalizeData = () => {
        return (newData, AdditionalDataChanged) => new Promise(resolve => {
            let oldStatus = data.Status;
            let url = props.type === "deal" ? "deals/editDeal" : "leads/editLead";
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/${url}`,
                data: { ...newData, AdditionalDataChanged },
                ...backendModule.axiosConfig
            }).then((res) => {
                // if (props.type === "deal") {
                //     console.log(Number(newData.Status) === 50 && Number(oldStatus) > 2 && Number(oldStatus) !== 50)
                //     if (Number(newData.Status) === 50 && Number(oldStatus) > 2 && Number(oldStatus) !== 50) {
                //         let text = `Posiljka je ponistena molimo ponistite istu i u vasem sistemu:\n DealID:  ${newData.ID},\n Ime i prezime: ${newData.FirstLastName},\n  Adresa: ${newData.Address},\n  Broj telefona: ${newData.PhoneNumber},\n  Drzava: ${newData.Country},\n  Molimo provjerite inbox za informacije o posiljci `;
                //         let user;
                //         switch (newData.Country) {
                //             case "BA":
                //                 user = 2;
                //                 break;
                //             case "RS":
                //                 user = 2;
                //                 break;
                //             default: user=2
                //         }
                //         socketModule.internal__sendPrivateNotification(user, text);
                //     }
                // }
            }).catch(() => null).finally(() => {
                resetData().then(() => {
                    getDealFromLead();
                    resolve();
                });
            });
        });
    };

    const resetData = () => {
        let url = props.type === "deal" ? "deals/getAllDealsDashboard" : "leads/getAllLeadsDashboard";
        return new Promise(resolve => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/${url}`,
                data: {
                    pagination: 0,
                    filters: [{ name: "ID", op: "eq", value: props.data.ID }],
                    smsReminders: true
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    if (res.data.data.length > 0) setData(res.data.data[0]);
                }
            }).catch((err) => { }).finally(resolve);
        });
    };

    const getDealFromLead = () => {
        if (props.type === 'deal') {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/leads/getAllLeadsDashboard`,
                data: {
                    pagination: 0,
                    filters: [{ name: "ID", op: "eq", value: props.data.LeadID }]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setLeadInfo(res.data.data[0])
                }
            }).catch((err) => { })
        }
    };

    React.useEffect(() => {
        getDealFromLead();
    }, [props])

    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            data: {
                filters: [
                    { name: "CountryCode", op: "eq", value: data.Country }
                ]
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                res.data.data.map(item => {
                    if (!data?.Country) {
                        setCurency('?');
                        setCountryName("?");
                    }
                    if (!data?.Country) {
                        setCurency('?');
                        setCountryName("?");
                    }
                    if (item.CountryCode === data?.Country) {
                        setCurency(item.CountryCurrency);
                        setCountryName(item.CountryName);
                    }
                })
            } else {
                setCurency("?");
                setCountryName("?");
            };
        }).catch(() => {
            setCurency("?");
            setCountryName("?");
        });
    };

    React.useEffect(() => {
        getCountries()
    }, [data])

    const getCities = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: data?.Country,
                filters: [
                    {
                        name: "CityID",
                        op: "eq",
                        value: data.City

                    }
                ]
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllCities(res.data.data)
            };
        }).catch(() => null);
    };

    React.useEffect(() => {
        getCities()
    }, [data?.Country, data?.City])
    React.useEffect(() => {
        if (!data) return;
        if (!allCities) return
        for (let i = 0; i < allCities.length; i++) {
            if (data.City === allCities[i].CityID) {
                setActiveCity(allCities[i].Name)
            }
        }
    }, [allCities])
    React.useEffect(() => {
        if (data.Status === 50 || data.Status === 49) {
            setActiveTab(0)
        }

    }, [data])

    React.useEffect(() => {
        let handlerName = props.type === "deal" ? "pingDealChanges" : "pingLeadChanges";

        let handler = hData => {
            if (data.ID === hData.ID) {
                resetData().then(() => {
                    getDealFromLead();
                });
            };
        };
        socketOn(handlerName, handler);
        return () => socketOff(handlerName, handler);
    }, []);

    React.useEffect(() => {
        if (props.prefetch === true) {
            if (!props?.data?.ID) return;
            setData({ ...data, internal_spinner: true });
            resetData().then(() => {
                if (props.activeTab) setActiveTab(props.activeTab);
            });
        } else {
            if (props.activeTab) setActiveTab(props.activeTab);
        };

        if (props.expand) setShowHistory(true);
    }, []);


    return <div className="ccmanager__singleLead" style={{ backgroundColor: "#272737" }} ref={mainRef}>
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: data?.internal_spinner ? 1 : 0,
            pointerEvents: data?.internal_spinner ? "all" : "none"
        }}>
            <Spinner color="white" />
        </div>
        <div className="ccmanager__singleLead__container" style={{ backgroundColor: "#272737" }}>
            <div style={{ width: (!userInfo.isAdmin && !userInfo.isSalesDirector && !userInfo.isMarketing && !userInfo.isCCManager && !userInfo.isShippmentAgent) ? "100%" : showHistory ? '0%' : '45%' }} className='ccmanager__singleLead__container__info' >
                {(userInfo.isAdmin || userInfo.isMarketing || userInfo.isSalesDirector || userInfo.isCCManager || userInfo.isShippmentAgent) ? <></> : <div className="ccmanager__singleLead__buttons__exitBtn" style={{ display: "flex", width: "100%", alignItems: "flex-end", zIndex: 99, justifyContent: "flex-end" }}>
                    <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
                </div>}
                <LeadDealStatus lead={leadInfo} canEdit={canEdit} data={data} allCities={allCities} type={props.type} onChange={finalizeData()} />
                {data.isRecurringPayment && <RejectedPayment lead={leadInfo} canEdit={userInfo.isShippmentAgent ? true : canEdit} data={data} allCities={allCities} type={props.type} onChange={finalizeData()} />}
                {(data.ShippmentStatus !== null && data.ShippmentStatus !== undefined) && <DealShipmentStatus canEdit={canEdit} data={data} allCities={allCities} type={props.type} onChange={finalizeData()} />}
                <CustomerInfo canEdit={canEdit} data={data} allCities={allCities} type={props.type} country={countryName} onChange={finalizeData()} />
                <LeadDealInfo canEdit={canEdit} data={data} allCities={allCities} type={props.type} onChange={finalizeData()} />
                <ArticlesInfo canEdit={canEdit} data={data} allCities={allCities} type={props.type} currency={currency} onChange={finalizeData()} />
                {(userData || isMarketing) && <AdditionalDataInfo canEdit={canEdit} data={data} allCities={allCities} type={props.type} currency={currency} onChange={finalizeData()} />}
                {(isCCManager) && <AdditionalDataInfoManager data={data} type={props.type} currency={currency} />}
                {props.type === "deal" && <SmsReminderInfo data={data} canEdit={canEdit || userFlags["isShippmentAgent"] === true} onChange={finalizeData()} />}
                {(props.type === "deal" && (userData || isShippmentAgent)) && <SetAgentMistake data={data} canEdit={canEdit || userFlags["isShippmentAgent"] === true} onChange={finalizeData()} />}

            </div>
            <div style={{ width: showHistory ? '100%' : '55%', overflow: 'hidden' }} className='ccmanager__singleLead__container__history'>
                {(!userInfo.isAdmin && !userInfo.isSalesDirector && !userInfo.isMarketing && !userInfo.isCCManager && !userInfo.isShippmentAgent) ? <></> : <>  <div className="ccmanager__singleLead__container__history__button" onClick={() => { setShowHistory(!showHistory) }}>{!showHistory ? translate[language].Prikazinaekranu : translate[language].Prikažiinformacije}</div>
                    <div className="ccmanager__singleLead__buttons" style={{ justifyContent: 'flex-start', marginTop: "10px", flexWrap: "wrap", gap: "5px" }}>
                        <CustomButtonSmall accent="transparent" className={activeTab === 0 ? "active" : null} value={translate[language].Historija} onClick={(e) => setActiveTab(0)} />
                        <CustomButtonSmall accent="transparent" className={activeTab === 1 ? "active" : null} value={translate[language].Aktivnost} onClick={(e) => setActiveTab(1)} />
                        {((props.type === "deal" && (data.WarningSent || data.isRecurringWithGuarantee || (data.isRecurringPayment && data.isRecurringPaymentJMBG)))) && <CustomButtonSmall accent="transparent" className={activeTab === 2 ? "active" : null} value={translate[language].Dokumenti} onClick={(e) => {
                            setActiveTab(2)
                        }} />}
                        {((props.type === "deal" && (userInfo.isAdmin || userInfo.isLegal || userInfo.isCCManager || userInfo.isShippmentAgent))) && <CustomButtonSmall accent="transparent" className={activeTab === 3 ? "active" : null} value={translate[language].UvezeniDokumenti} onClick={(e) => {
                            setActiveTab(3)
                        }} />}
                        {((data.Status === 100 || data.Status === 3 || data.Status === 0 || data.Status === 5000) && props.type === 'deal') && <CustomButtonSmall accent="transparent" className={activeTab === 4 ? "active" : null} value={translate[language].Storniraj} onClick={(e) => {
                            setActiveTab(4)
                        }} />}
                        {(data?.ShippmentStatus !== null && data?.ShippmentStatus !== undefined) ? <CustomButtonSmall accent="transparent" className={activeTab === 5 ? "active" : null} value={translate[language].Informacijeopošiljci} onClick={e => {
                            setActiveTab(5)
                        }} /> : null}
                        {((data.Status === 0 || data.Status === 1 || data.Status === 98 || data.Status === 99) && props.type === 'deal') && <CustomButtonSmall accent="transparent" className={activeTab === 6 ? "active" : null} value={translate[language].ZamijeniDupliraj} onClick={(e) => {
                            setActiveTab(6)
                        }} />}
                        {((data.Status === 3 && data.ShippmentStatus) && props.type === 'deal') && <CustomButtonSmall accent="transparent" className={activeTab === 7 ? "active" : null} value={translate[language].Spasipošiljku} onClick={(e) => {
                            setActiveTab(7)
                        }} />}
                        {(props.type === "deal" && (userInfo.isAdmin || userInfo.isLegal || userInfo.isCCManager)) && <CustomButtonSmall accent="transparent" className={activeTab === 8 ? "active" : null} value={translate[language].Upozorenje} onClick={(e) => {
                            setActiveTab(8)
                        }} />}
                        {(props.type === "deal" && (userInfo.isAdmin || userInfo.isShippmentAgent) && data.Status === 100) && <CustomButtonSmall accent="transparent" className={activeTab === 9 ? "active" : null} value={"Kreiraj reklamaciju"} onClick={(e) => {
                            setActiveTab(9)
                        }} />}
                        {(props.type === "deal" && (userInfo.isAdmin || userInfo.isShippmentAgent) && data.Status > 2 && data.Status !== 100) && <CustomButtonSmall accent="transparent" className={activeTab === 9 ? "active" : null} value={"Posalji e-mail posti"} onClick={(e) => {
                            setActiveTab(10)
                        }} />}
                        <div className="ccmanager__singleLead__buttons__exitBtn" style={{ zIndex: 99 }}>
                            <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => onClose()} style={{ border: "1px solid rgb(233, 86, 86)" }} />
                        </div>
                    </div>
                </>}
                {(!userInfo.isAdmin && !userInfo.isSalesDirector && !userInfo.isMarketing && !userInfo.isCCManager && !userInfo.isShippmentAgent) ? <></> : <div className="ccmanager__singleLead__buttons__activeTabContainer">
                    {activeTab === 0 && <Historija type={props.type} ID={data.ID} data={data} cc={ccUsers} />}
                    {activeTab === 1 && <Comments type={props.type} ID={data.ID} data={data} cc={ccUsers} />}
                    {activeTab === 2 && <Documents data={data} key="lead-documents" />}
                    {activeTab === 3 && <ImportedDocuments ID={data.ID} onOpen={() => setShowHistory(true)} onClose={() => setShowHistory(false)} key="lead-importedDocuments" />}
                    {activeTab === 4 && <Storn deal={data} />}
                    {activeTab === 5 && <DealShipmentHistory id={data?.ID} />}
                    {activeTab === 6 && <ChangeArticles deal={data} />}
                    {activeTab === 7 && <SaveDeal deal={data} changeTab={() => setActiveTab(0)} />}
                    {activeTab === 7 && <SaveDeal deal={data} changeTab={() => setActiveTab(0)} />}
                    {activeTab === 8 && <WarningInfo deal={data} />}
                    {activeTab === 9 && <CreateReclamation deal={data} />}
                    {activeTab === 10 && <SendEmailToExpress deal={data} />}
                </div>}

            </div>
        </div>
    </div>
};

const LeadDealStatus = (props) => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [createdDeal, setCreatedDeal] = React.useState();
    const [recurredDeal, setRecurredDeal] = React.useState();
    const [selectedRecurringPaid, setSelectedRecurringPaid] = React.useState(props.data.isRecurringPaymentPaid);
    const [selectedDuplicateDeal, setSelectedDuplicateDeal] = React.useState(props.data.PotentialDuplicateDeal);
    const [selectedWrongProduct, setSelectedWrongProduct] = React.useState(props.data.PotentialWrongProduct);
    let language = useSelector(state => state.language);
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const statusSelector = useSelector(state => {
        if (props.type === "deal") {
            return state?.statusCodes?.deals;
        } else {
            return state?.statusCodes?.leads;
        };
    })
    const translateStatusCodesLeads = (status) => {
        if (language === "BA" || language === "SI") {
            return status
        } else if (language === "RO") {
            return {
                "Obrada": "Procesare",
                "Pozvati ponovo": "Reapelati",
                "Loš lead": "Prospect slab",
                "Odbijen lead": "Prospect respins",
                "Dobar lead": "Prospect bun",
                "Zadnja šansa": "Ultima șansă",
                "Novi upit": "Cerere nouă"
            }[status]
        }

    }
    const statusColorFn = useCallback(props.type === "deal" ? getDealStatusColor2 : getLeadStatusColor2);

    const [selectedStatus, setSelectedStatus] = React.useState(props?.data?.Status);
    const selectedNameRef = React.useRef();
    const selectedDateRef = React.useRef();
    const selectedTimeRef = React.useRef();

    let recurringPaymentColor = "";
    let recurringPaymentCode = 1;
    let recurringPaymentStatus = "Nije preuzeto";
    if (props.data.isRecurringPayment) {
        if (props.type !== "deal") {
            recurringPaymentColor = getRecurringPaymentColor(null);
        } else {
            if (props.data.Status !== 100) {
                recurringPaymentColor = getRecurringPaymentColor(null);
            } else {
                recurringPaymentColor = getRecurringPaymentColor(props.data.isRecurringPaymentPaid);
                recurringPaymentCode = props.data.isRecurringPaymentPaid ? 3 : 2;
                recurringPaymentStatus = props.data.isRecurringPaymentPaid ? "2 rata uplaćena" : "1 rata uplaćena";
            };
        };
    };

    const saveData = () => {
        let data = {
            ...props.data,
            Status: Number(selectedStatus),
            Name: selectedNameRef.current.value,
            isRecurringPaymentPaid: selectedRecurringPaid,
        };
        if (String(data.Status) === "2" && props.type !== "deal") {
            let callTime = moment(`${selectedDateRef.current.value} ${selectedTimeRef.current.value}`);
            data.ScheduledCallTime = callTime.toDate();
        };
        if (String(data.Status) === "5000" && props.type === "deal") {
            let callTime = moment(`${selectedDateRef.current.value}`).startOf("day");
            data.ScheduledDelivery = callTime.toDate();
            data.Status = props.data.Status;
        } else {
            data.ScheduledDelivery = moment().startOf("day").toDate();
        };
        if (props.type === "deal") {
            data.PotentialDuplicateDeal = selectedDuplicateDeal;
            data.PotentialWrongProduct = selectedWrongProduct;
        };

        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    const checkDeal = () => {
        setSpinner(true);
        setCreatedDeal();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                filters: [{
                    name: "LeadID", op: "eq", value: props?.data?.ID
                }],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) setCreatedDeal(res.data.data[0]);
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const checkRecurredDeal = () => {
        if (props.type === "deal") return;
        setSpinner(true);
        setRecurredDeal();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                filters: [{
                    name: "RescheduledLeadID", op: "eq", value: props?.data?.ID
                }],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) setRecurredDeal(res.data.data[0]);
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const getRescheduledLead = (leadID, e) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeadsDashboard`,
            data: {
                filters: [{
                    name: "ID", op: "eq", value: leadID
                }],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    animateBox({ ...e, currentTarget: e.target }, <SingleLead data={res.data.data[0]} />);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        })
    };

    const getReplacementDeal = (dealID, e) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                filters: [{
                    name: "ID", op: "eq", value: dealID
                }],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    animateBox({ ...e, currentTarget: e.target }, <SingleLead type="deal" data={res.data.data[0]} />);
                };
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        })
    };

    React.useEffect(() => {
        if (props.type === "deal") return;

        checkDeal();
        checkRecurredDeal();
    }, [props?.data?.ID]);

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>

        {props.data.isSpam && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: '#fd8f8f', marginBottom: '10px' }}>Dupli/Spam detektovan!</small>}
        {(props.type === "deal" && props.data.PotentialDuplicateDeal) && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: '#fd8f8f', marginBottom: '10px' }}>Potencijalni dupli deal!</small>}
        {(props.type === "deal" && props.data.PotentialWrongProduct) && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: '#fd8f8f', marginBottom: '10px' }}>Potencijalni pogresan proizvod!</small>}
        {props.data.isPlarityBrand && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: 'rgb(229, 143, 253)', marginBottom: '10px' }}>Brend: Plarity</small>}
        {props.data.SubTypeName && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: 'rgb(229, 143, 253)', marginBottom: '10px' }}>Prilagođeni tip: {props.data.SubTypeName}</small>}

        <small>{props.type === "deal" ? "Deal ID" : "Lead ID"}</small>
        <p style={{
            display: 'flex',
            alignItems: "center"
        }}>{props.data.ID}
            <CustomButtonSmall style={{
                marginLeft: '20px',
                width: '150px',
                height: '30px'
            }} accent="white" value="Pošalji u poruci" onClick={(e) => animateBox(e, <SendMessage type={props.type === 'deal' ? 'deal' : 'lead'} id={props.data.ID} />)} />
        </p>

        {(props.type === "deal" && props.data.LeadID && !editMode) && <>
            <small>Kreiran od Lead ID-a:</small>
            <p>
                {props.data.LeadID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži lead" onClick={e => animateBox(e, <SingleLead data={props.lead} />)} />
            </p>

        </>}

        {(props.type === "deal" && props.data.DealReplacemenetID && !editMode) && <>
            <small>Deal zamijenjen za ID</small>
            <p>
                {props.data.DealReplacemenetID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži lead" onClick={e => getReplacementDeal(props.data.DealReplacemenetID, e)} />
            </p>
        </>}

        {(props.type === "deal" && props.data.RescheduledLeadID && !editMode) && <>
            <small>Lead ID produženja terapije</small>
            <p>
                {props.data.RescheduledLeadID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži lead" onClick={e => getRescheduledLead(props.data.RescheduledLeadID, e)} />
            </p>
        </>}

        {(props.type !== "deal" && props.data.isRecurring === true && recurredDeal && !editMode) && <>
            <small>Deal ID od kojeg je kreirano produženje</small>
            <p>
                {recurredDeal?.ID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži deal" onClick={e => animateBox(e, <SingleLead type="deal" data={recurredDeal} />)} />
            </p>
        </>}
        {(props.data.ClientID && userSelector?.Flags?.isAdmin && !editMode) && <>
            <small>Klijent</small>
            <p>
                {props.data.ClientID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    width: "150px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži klijenta" onClick={e => animateBox(e, <SingleClient type="deal" data={props.data.ClientID} />)} />
            </p>
        </>}

        {(props.type !== "deal" && createdDeal && !editMode) && <>
            <small>Deal ID kreiran od ovog leada</small>
            <p>
                {createdDeal?.ID}
                <CustomButtonSmall accent="#2b2c3e" theme="dark" style={{
                    height: "30px",
                    marginLeft: "10px",
                    border: "1px solid rgb(102, 100, 229)"
                }} value="Prikaži deal" onClick={e => animateBox(e, <SingleLead data={createdDeal} type="deal" />)} />
            </p>
        </>}

        <small>{translate[language].Naziv}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.Name} ref={selectedNameRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p>{props.data?.Name ? props.data?.Name : 'Nepoznato'}</p>
        }

        {!editMode && <>
            <small>{translate[language].Brojpoziva}</small>
            <p>{props.data?.ScheduledCallTimeAttempts !== null ? props.data?.ScheduledCallTimeAttempts : 'Nepoznato'}</p>
        </>}

        {(!editMode && moment(props.data?.ScheduledCallTime).isAfter()) && <>
            <small>Poziv odgođen za</small>
            <p style={{ color: "rgb(59, 166, 147)" }}>{moment(props.data?.ScheduledCallTime).toDate().toLocaleString()}</p>
        </>}

        <small>{translate[language].Status}</small>
        {
            editMode ?
                <>
                    <Dropdown data={Object.keys(statusSelector)?.map(key => {
                        return { name: translateStatusCodesLeads(statusSelector[key]), value: key }
                    })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedStatus(e?.value)} selected={(() => {
                        let idx = Object.keys(statusSelector).indexOf(Object.keys(statusSelector).find(ct => String(ct) === String(selectedStatus)));
                        if (idx < 0) return null; else return idx;
                    })()} />
                    {(props.type !== "deal" && String(selectedStatus) === "2") && <div className="ccmanager__singleLead__box__dateTimePicker">
                        <CustomInput ref={selectedDateRef} theme="dark" accent="rgb(102, 100, 229)" type="date" defaultValue={moment(props.data.ScheduledCallTime ?? Date.now()).format("YYYY-MM-DD")} />
                        <CustomInput ref={selectedTimeRef} theme="dark" accent="rgb(102, 100, 229)" type="time" defaultValue={moment(props.data.ScheduledCallTime ?? Date.now()).format("HH:mm")} />
                    </div>}
                    {(props.type === "deal" && String(selectedStatus) === "5000") && <div className="ccmanager__singleLead__box__dateTimePicker">
                        <CustomInput ref={selectedDateRef} theme="dark" accent="rgb(102, 100, 229)" type="date" style={{ width: "100%" }} defaultValue={moment(props.data.ScheduledDelivery ?? Date.now()).format("YYYY-MM-DD")} />
                    </div>}
                </> :
                <>
                    <p>{translateStatusCodesLeads(statusSelector[props.data.Status])}</p>
                    {(props.type !== "deal" && String(props.data.Status) === "2") && <p style={{ fontSize: "16px" }}>{new Date(props.data.ScheduledCallTime).toLocaleString()}</p>}
                    {(props.type === "deal" && String(props.data.Status) === "5000") && <p style={{ fontSize: "16px" }}>{moment(props.data.ScheduledDelivery).toDate().toLocaleDateString()}</p>}
                    <div className="ccmanager__singleLead__box__statusDisplay" style={{ gridTemplateColumns: `repeat(${Object.keys(statusSelector).length}, 1fr)` }}>
                        {Object.keys(statusSelector).map(key => {
                            return <span style={{
                                backgroundColor: key <= props.data.Status ? statusColorFn(props.data.Status) : null
                            }}></span>
                        })}
                    </div>
                </>
        }

        {
            (props.data.isRecurringPayment && props.data.isRecurringPaymentJMBG) && <>
                {
                    (editMode) ?
                        ((props.type === "deal" && props.data.Status === 100) && <>
                            <small style={{ marginTop: "20px" }}>Odgođeno plaćanje</small>
                            <Dropdown data={[
                                { name: "2 rata uplaćena", value: true },
                                { name: "1 rata uplaćena", value: false }
                            ]} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedRecurringPaid(e?.value)} selected={(() => {
                                if (selectedRecurringPaid) return 0;
                                return 1;
                            })()} />
                        </>) :
                        <>
                            <small style={{ marginTop: "20px" }}>Odgođeno plaćanje</small>
                            <p>{recurringPaymentStatus}</p>
                            <div className="ccmanager__singleLead__box__statusDisplay" style={{ gridTemplateColumns: `repeat(3, 1fr)` }}>
                                {[1, 2, 3].map(key => {
                                    return <span style={{
                                        backgroundColor: (recurringPaymentCode >= key) ? recurringPaymentColor : null
                                    }}></span>
                                })}
                            </div>
                        </>
                }
            </>
        }

        {(props.type === "deal" && typeof props.data?.DealRejectReasons !== "string" && props.data?.DealRejectReasons?.length > 0) && <>
            <small style={{ marginTop: "20px" }}>Razlog lošeg deala</small>
            <p className="ccmanager__singleLead__box__pill">{(props.data?.DealRejectReasons ?? []).map(rr => {
                return <span className="ccmanager__singleLead__box__pill__item">{rr}</span>
            })}</p>
        </>}

        {props.type === "deal" && <>
            <small style={{ marginTop: "20px" }}>Potencijalni dupli deal</small>
            {editMode ? <>
                <Dropdown
                    theme="dark"
                    accent="rgb(102, 100, 229)"
                    data={[
                        { name: "Da", value: true },
                        { name: "Ne", value: false }

                    ]}
                    selected={(() => {
                        switch (props.data.PotentialDuplicateDeal) {
                            case true: return 0;
                            default: return 1;
                        };
                    })()}
                    onChange={e => setSelectedDuplicateDeal(e?.value)}
                />

            </> : <p>{props.data.PotentialDuplicateDeal ? <span style={{ color: "rgb(149, 255, 205)" }}>Da</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>
            }
        </>}
        {props.type === "deal" && <>
            <small style={{ marginTop: "20px" }}>Potencijalni pogresan  proizvod</small>
            {editMode ? <>
                <Dropdown
                    theme="dark"
                    accent="rgb(102, 100, 229)"
                    data={[
                        { name: "Da", value: true },
                        { name: "Ne", value: false }

                    ]}
                    selected={(() => {
                        switch (props.data.PotentialWrongProduct) {
                            case true: return 0;
                            default: return 1;
                        };
                    })()}
                    onChange={e => setSelectedWrongProduct(e?.value)}
                />
            </> : <p>{props.data.PotentialWrongProduct ? <span style={{ color: "rgb(149, 255, 205)" }}>Da</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>
            }
        </>}


        {(props.type === "deal" && props.data.isRecurringPayment && moment(props.data.createdAt).isBefore(moment().add(-35, "days"))) && <>
            <small style={{ marginTop: "20px" }}>Opomena poslana</small>
            <p>{props.data.UnpaidNoticeSentDate ? <span style={{ color: "rgb(149, 255, 205)" }}>{moment(props.data.UnpaidNoticeSentDate).toDate().toLocaleString()}</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>
        </>}

        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setSelectedStatus(props.data.Status);
                setSelectedRecurringPaid(props.data.isRecurringPaymentPaid);
                setSelectedDuplicateDeal(props.data.PotentialDuplicateDeal);
                setSelectedDuplicateDeal(props.data.PotentiWrongProduct);
                setEditMode(false)
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};

const RejectedPayment = (props) => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedRecurringPaid, setSelectedRecurringPaid] = React.useState(props.data.isRecurringPaymentPaid);
    const [rejectedPayment, setRejectedPayment] = React.useState(props.data.isRecurringRejected);
    let language = useSelector(state => state.language);
    const statusSelector = useSelector(state => {
        if (props.type === "deal") {
            return state?.statusCodes?.deals;
        } else {
            return state?.statusCodes?.leads;
        };
    })



    let recurringPaymentColor = "";
    let recurringPaymentCode = 1;
    let recurringPaymentStatus = "Nije preuzeto";
    if (props.data.isRecurringPayment) {
        if (props.type !== "deal") {
            recurringPaymentColor = getRecurringPaymentColor(null);
        } else {
            if (props.data.Status !== 100) {
                recurringPaymentColor = getRecurringPaymentColor(null);
            } else {
                recurringPaymentColor = getRecurringPaymentColor(props.data.isRecurringPaymentPaid);
                recurringPaymentCode = props.data.isRecurringPaymentPaid ? 3 : 2;
                recurringPaymentStatus = props.data.isRecurringPaymentPaid ? "2 rata uplaćena" : "1 rata uplaćena";
            };
        };
    };

    const saveData = () => {
        let data = {
            ...props.data,
        };
        if (props.type === "deal") {
            data.isRecurringRejected = rejectedPayment;
        };

        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>

        {(props.type === "deal" && props.data.isRecurringRejected) && <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: '#fd8f8f', marginBottom: '10px' }}>Odbija placanje druge rate!</small>}







        {props.type === "deal" && props.data.isRecurringPayment && <>
            <small style={{ marginTop: "20px" }}>Klijent odbija placanje druge rate:</small>
            {editMode ? <>
                <Dropdown
                    theme="dark"
                    accent="rgb(102, 100, 229)"
                    data={[
                        { name: "Da", value: true },
                        { name: "Ne", value: false }

                    ]}
                    selected={(() => {
                        switch (props.data.isRecurringRejected) {
                            case true: return 0;
                            default: return 1;
                        };
                    })()}
                    onChange={e => setRejectedPayment(e?.value)}
                />
            </> : <p>{props.data.isRecurringRejected ? <span style={{ color: "rgb(149, 255, 205)" }}>Da</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>}
        </>}



        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setRejectedPayment(props.data.isRecurringRejected);
                setEditMode(false)
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};

const DealShipmentStatus = props => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [dealMoneyReceived, setDealMoneyReceived] = React.useState(props.data.DealMoneyReceived);

    const shipmentStatusSelector = useSelector(state => state?.statusCodes?.shipment ?? {});
    const savesStatusSelector = useSelector(state => state?.statusCodes?.dealSaves ?? {});
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);

    const [selectedResponsiblePerson, setSelectedResponsiblePerson] = React.useState(props.data.DealResponsiblePerson);
    const [selectedStatus, setSelectedStatus] = React.useState(props.data.DealSaveStatus);
    const selectedDateRef = React.useRef();
    const selectedTimeRef = React.useRef();
    let language = useSelector(state => state.language);

    const saveData = () => {
        let data = {
            ...props.data,
            DealResponsiblePerson: selectedResponsiblePerson,
            DealSaveStatus: selectedStatus,
            DealMoneyReceived: dealMoneyReceived
        };
        if (String(data.DealSaveStatus) === "2") {
            let callTime = moment(`${selectedDateRef.current.value} ${selectedTimeRef.current.value}`);
            data.ScheduledCallTime = callTime.toDate();
        };

        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>

        <small>Status pošiljke</small>
        <p>{shipmentStatusSelector[props.data.ShippmentStatus]}</p>
        <div className="ccmanager__singleLead__box__statusDisplay" style={{ gridTemplateColumns: `repeat(${Object.keys(shipmentStatusSelector).length}, 1fr)` }}>
            {Object.keys(shipmentStatusSelector).map(key => {
                return <span style={{
                    backgroundColor: key <= props.data.ShippmentStatus ? getDealShipColor(props.data.ShippmentStatus) : null
                }}></span>
            })}
        </div>

        {(props.data.DealResponsiblePerson !== null && props.data.DealResponsiblePerson !== undefined) && <>
            <small>Odgovorna problematika</small>
            {
                editMode ?
                    <Dropdown data={chatUsersSelector?.map(usr => {
                        return { name: `(${usr.ID}) ${usr.FirstName} ${usr.LastName}`, value: usr.ID }
                    })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedResponsiblePerson(e?.value)} selected={(() => {
                        let idx = chatUsersSelector.indexOf(chatUsersSelector.find(cu => cu.ID === selectedResponsiblePerson));
                        if (idx < 0) return null; else return idx;
                    })()} /> :
                    <p>{(() => {
                        let curPerson = chatUsersSelector.find(usr => usr.ID === props.data.DealResponsiblePerson);

                        if (!curPerson) return "Agent nepoznat";
                        return <p style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <img style={{
                                width: "32px", height: "32px", borderRadius: "50%", marginRight: "15px"
                            }} src={curPerson.Image} />
                            <span>{curPerson.FirstName} {curPerson.LastName}</span>
                        </p>
                    })()}</p>
            }
        </>}

        {
            (props.data.DealSaveStatus !== null && props.data.DealSaveStatus !== undefined) && <>
                <small>Status problematike</small>
                {
                    editMode ?
                        <>
                            <Dropdown data={Object.keys(savesStatusSelector)?.map(key => {
                                return { name: savesStatusSelector[key], value: key }
                            })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedStatus(e?.value)} selected={(() => {
                                let idx = Object.keys(savesStatusSelector).indexOf(Object.keys(savesStatusSelector).find(ct => String(ct) === String(props.data.DealSaveStatus)));
                                if (idx < 0) return null; else return idx;
                            })()} />
                            {String(selectedStatus) === "2" && <div className="ccmanager__singleLead__box__dateTimePicker">
                                <CustomInput ref={selectedDateRef} theme="dark" accent="rgb(102, 100, 229)" type="date" defaultValue={moment(props.data.ScheduledCallTime ?? Date.now()).format("YYYY-MM-DD")} />
                                <CustomInput ref={selectedTimeRef} theme="dark" accent="rgb(102, 100, 229)" type="time" defaultValue={moment(props.data.ScheduledCallTime ?? Date.now()).format("HH:mm")} />
                            </div>}
                        </> :
                        <>
                            <p>{savesStatusSelector[props.data.DealSaveStatus]}</p>
                            {String(props.data.DealSaveStatus) === "2" && <p style={{ fontSize: "16px" }}>{new Date(props.data.ScheduledCallTime).toLocaleString()}</p>}
                            <div className="ccmanager__singleLead__box__statusDisplay" style={{ gridTemplateColumns: `repeat(${Object.keys(savesStatusSelector).length}, 1fr)` }}>
                                {Object.keys(savesStatusSelector).map(key => {
                                    return <span style={{
                                        backgroundColor: key <= props.data.DealSaveStatus ? getDealSaveShipColor(props.data.DealSaveStatus) : null
                                    }}></span>
                                })}
                            </div>
                        </>
                }
            </>
        }

        <small>Novac uručen od pošte</small>
        {editMode ? <>
            <Dropdown data={[
                { name: "Da", value: true },
                { name: "Ne", value: false }
            ]} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setDealMoneyReceived(e?.value)} selected={(() => {
                if (dealMoneyReceived) return 0; else return 1;
            })()} />
        </> : <>
            <p>{dealMoneyReceived ? <span style={{ color: "rgb(149, 255, 205)" }}>Da</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>
        </>}

        {(!editMode && props.type === "deal" && props.data.isDispatched && props.data.DealSaveStatus > 0) && <>
            <small>Predan problematici (~{props.data.WasInProblemCount} puta | {props.data.WasInProblemEmergencyCount} hitno)</small>
            <p>{props.data.WasInProblemAt ? moment(props.data.WasInProblemAt).toDate().toLocaleString() : "-"}</p>
        </>}
        {(!editMode && props.type === "deal" && props.data.isDispatched && props.data.DealSaveStatus > 0) && <>
            <small>Izašao iz problematike</small>
            <p>{props.data.WasInProblemResolvedAt ? moment(props.data.WasInProblemResolvedAt).toDate().toLocaleString() : "-"}</p>
        </>}

        {(!editMode && props.data?.DispatcherComment) ? <>
            <small>Komentar</small>
            <p>{props.data.DispatcherComment}</p>
        </> : null}

        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setSelectedStatus(props.data.DealSaveStatus);
                setSelectedResponsiblePerson(props.data.DealResponsiblePerson);
                setDealMoneyReceived(props.data.DealMoneyReceived);
                setEditMode(false);
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};

const CustomerInfo = (props) => {
    const [editMode, setEditMode] = React.useState(false);
    const [cities, setCities] = React.useState();
    const [countries, setCountries] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const [selectedCountry, setSelectedCountry] = React.useState(props.data.Country);
    const [selectedCity, setSelectedCity] = React.useState(props.data.City);

    const selectedFirstLastNameRef = React.useRef();
    const [selectedGender, setSelectedGender] = React.useState(props.data.Gender)
    const selectedBirthDate = React.useRef();
    const selectedAddressRef = React.useRef();
    const selectedPhoneNumberRef = React.useRef();
    const selectedHouseNumberRef = React.useRef();
    const selectedEmailRef = React.useRef();
    let language = useSelector(state => state.language);
    const illnessesSelector = useSelector(state => state?.statusCodes?.illnesses ?? {});

    const saveData = () => {
        let data = {
            ...props.data,
            FirstLastName: selectedFirstLastNameRef.current.value,
            Email: selectedEmailRef.current.value,
            Country: selectedCountry,
            City: selectedCity,
            Address: selectedAddressRef.current.value,
            PhoneNumber: selectedPhoneNumberRef.current.value,
            HouseNumber: selectedHouseNumberRef.current.value,
            BirthYear: selectedBirthDate.current.value,
            Gender: selectedGender
        };
        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    const getCities = () => {
        if (!selectedCountry) return;
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: selectedCountry
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCities(res.data);
            } else {
                setCities({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setCities({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getCountries = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setCountries(res.data);
            } else {
                setCountries({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setCountries({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getCountries();
    }, []);

    React.useEffect(() => {
        if (editMode) {
            if (selectedCountry) {
                getCities();
            } else {
                setCities();
            };
        } else {
            setCities();
        };
    }, [editMode, selectedCountry]);

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>
        <small>{translate[language].Imekupca}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.FirstLastName} ref={selectedFirstLastNameRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.FirstLastName ? props.data?.FirstLastName : 'Nepoznato'}</p>
        }
        <small>{translate[language].Email}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.Email} ref={selectedEmailRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.Email ? props.data?.Email : 'Nepoznato'}</p>
        }
        {
            editMode ?
                <></> :
                <>
                    <small>Zanimanje</small>
                    <p> {props.data?.Profession ? props.data?.Profession : 'Nepoznato'}</p>
                </>
        }
        <small>{translate[language].Godinarođenja}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.BirthYear} ref={selectedBirthDate} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.BirthYear ? props.data?.BirthYear : 'Nepoznato'}</p>
        }
        <small>{translate[language].Pol}</small>
        {
            editMode ?
                <Dropdown selected={selectedGender === null ? undefined : (selectedGender ? 0 : 1)}
                    data={[{ name: 'Muški', value: true }, { name: 'Ženski', value: false }]} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => {
                        setSelectedGender(e.value)
                    }} /> :
                <p> {selectedGender === null ? 'Nepoznato' : selectedGender === true ? 'Muški' : 'Ženski'}</p>
        }
        <small>{translate[language].Država}</small>

        {
            editMode ?
                <Dropdown data={(countries?.status === "ok" ? countries.data : [])?.map(country => {
                    return { name: country.CountryName, value: country.CountryCode }
                })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedCountry(e?.value)} selected={(() => {
                    if (!countries) return;
                    if (countries?.status !== "ok") return;
                    let idx = countries.data.indexOf(countries.data.find(ct => ct.CountryCode === selectedCountry));
                    if (idx < 0) return null; else return idx;
                })()} /> :
                <p> {props.country ? props.country : 'Nepoznato'}</p>
        }
        <small>{translate[language].Grad}</small>
        {
            editMode ?
                <Dropdown limit={500} data={(cities?.status === "ok" ? cities.data : [])?.map(city => {
                    return { name: `${city.Name} (${city.PttNo})`, value: city.CityID }
                })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedCity(e?.value)} selected={(() => {
                    if (!cities) return;
                    if (cities?.status !== "ok") return;
                    let idx = cities.data.indexOf(cities.data.find(ct => ct.CityID === selectedCity));
                    if (idx < 0) return null; else return idx;
                })()} /> :
                <p> {props.allCities[0] && props.allCities[0].Name ? props.allCities[0].Name : 'Nepoznato'}</p>
        }
        <small>{translate[language].Adresa}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.Address} ref={selectedAddressRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.Address ? props.data?.Address : 'Nepoznato'}</p>
        }
        <small>{translate[language].Brojkuće}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.HouseNumber} ref={selectedHouseNumberRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.HouseNumber ? props.data?.HouseNumber : 'Nepoznato'}</p>
        }
        <small>{translate[language].Brojtelefona}</small>
        {
            editMode ?
                <CustomInput defaultValue={props?.data?.PhoneNumber} ref={selectedPhoneNumberRef} placeholder="" theme="dark" accent="rgb(102, 100, 229)" /> :
                <p> {props.data?.PhoneNumber ? props.data?.PhoneNumber : 'Nepoznato'}</p>
        }

        {(!editMode && props.data?.Illnesses?.length > 0) && <>
            <small style={{ marginTop: "20px" }}>{translate[language].Oboljenje}</small>
            <p className="ccmanager__singleLead__box__pill">{(props.data?.Illnesses ?? [])?.map?.(ill => {
                return <span className="ccmanager__singleLead__box__pill__item">{illnessesSelector[ill] ? illnessesSelector[ill][language] ?? illnessesSelector[ill]["BA"] : ill}</span>
            })}</p>
        </>}

        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setSelectedCity(props.data.City);
                setSelectedCountry(props.data.Country);
                setSelectedGender(props.data.Gender);
                setEditMode(false)
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
}

const LeadDealInfo = props => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);

    const [selectedResponsiblePerson, setSelectedResponsiblePerson] = React.useState(props?.data?.ResponsiblePerson);
    const [selectedRejectPerson, setSelectedRejectPerson] = React.useState(props?.data?.ResponsiblePersonBeforeReject);
    const [selectedUserID, setSelectedUserID] = React.useState(props?.data?.UserID);

    let language = useSelector(state => state.language);
    const userSelector = useSelector((state) => state.userData.userData.UserInfo);
    const saveData = () => {
        let data = {
            ...props.data,
            ResponsiblePerson: selectedResponsiblePerson,
            ResponsiblePersonBeforeReject: selectedRejectPerson,
            UserID: selectedUserID
        };
        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>
        {(userSelector?.Flags?.isAdmin || userSelector?.Flags?.isCCManager) && <> <small>{translate[language].Odgovorniagent}:</small>
            {
                (editMode) ?
                    <Dropdown data={chatUsersSelector?.map(usr => {
                        return { name: `(${usr.ID}) ${usr.FirstName} ${usr.LastName}`, value: usr.ID }
                    })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedResponsiblePerson(e?.value)} selected={(() => {
                        let idx = chatUsersSelector.indexOf(chatUsersSelector.find(cu => cu.ID === selectedResponsiblePerson));
                        if (idx < 0) return null; else return idx;
                    })()} /> :
                    <p>{(() => {
                        let curUser = chatUsersSelector.find(cu => cu.ID === props.data.ResponsiblePerson);
                        if (!curUser) return <p>{`${props.data.ResponsiblePersonName} (${props.data.ResponsiblePerson})` ?? "Nema odgovorne osobe"}</p>
                        return <p style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <img style={{
                                width: "32px", height: "32px", borderRadius: "50%", marginRight: "15px"
                            }} src={curUser.Image ? curUser.Image : '/images/undefined.png'} />
                            <span>[{props.data.ResponsiblePersonCC ?? "-"}] {curUser.FirstName} {curUser.LastName}</span>
                        </p>
                    })()}</p>
            }</>}
        {props.type !== "deal" && (userSelector?.Flags?.isAdmin || userSelector?.Flags?.isCCManager) && <>
            {
                editMode ? <>
                    <small>{translate[language].Odgovorniagentprijezadnješanse}:</small>
                    <Dropdown data={chatUsersSelector?.map(usr => {
                        return { name: `(${usr.ID}) ${usr.FirstName} ${usr.LastName}`, value: usr.ID }
                    })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedRejectPerson(e?.value)} selected={(() => {
                        let idx = chatUsersSelector.indexOf(chatUsersSelector.find(cu => cu.ID === selectedRejectPerson));
                        if (idx < 0) return null; else return idx;
                    })()} /></> :
                    <small>{translate[language].Odgovorniagentprijezadnješanse}:</small>
            }
            {
                editMode ?
                    null :
                    <p>{(() => {
                        let curUser = chatUsersSelector.find(cu => cu.ID === props.data.ResponsiblePersonBeforeReject);

                        if (!curUser) return <p>{`${props.data.ResponsiblePersonBeforeRejectName} (${props.data.ResponsiblePersonBeforeReject})` ?? "Nema odgovorne osobe"}</p>
                        return <p style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <img style={{
                                width: "32px", height: "32px", borderRadius: "50%", marginRight: "15px"
                            }} src={curUser.Image ? curUser.Image : '/images/undefined.png'} />
                            <span>[{props.data.ResponsiblePersonBeforeRejectCC ?? "-"}] {curUser.FirstName} {curUser.LastName}</span>
                        </p>
                    })()}</p>
            }
        </>}
        {props.data.UserID && <>
            <small>{translate[language].OdgovorniUserID}:</small>
            {
                editMode ?
                    <Dropdown data={chatUsersSelector?.map(usr => {
                        return { name: `(${usr.ID}) ${usr.FirstName} ${usr.LastName}`, value: usr.ID }
                    })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setSelectedUserID(e?.value)} selected={(() => {
                        let idx = chatUsersSelector.indexOf(chatUsersSelector.find(cu => cu.ID === selectedUserID));
                        if (idx < 0) return null; else return idx;
                    })()} /> :
                    <p>{(() => {
                        let curUser = chatUsersSelector.find(cu => cu.ID === props.data.UserID);
                        if (!curUser) return <p>{`Nepoznata osoba (${props.data.UserID})`}</p>
                        return <p style={{
                            display: "flex", alignItems: "center"
                        }}>
                            <img style={{
                                width: "32px", height: "32px", borderRadius: "50%", marginRight: "15px"
                            }} src={curUser.Image ? curUser.Image : '/images/undefined.png'} />
                            <span>{curUser.FirstName} {curUser.LastName}</span>
                        </p>
                    })()}</p>
            }
        </>}
        <small>{translate[language].Datumkreiranja}:</small>
        <p> {props.data?.createdAt ? new Date(props.data?.createdAt).toLocaleString() : 'Nepoznato'}</p>
        <small>Datum prve obrade:</small>
        <p> {props.data?.firstAgentUpdatedAt ? new Date(props.data?.firstAgentUpdatedAt).toLocaleString() : 'Nepoznato'}</p>
        <small>{translate[language].Datumposljednjeizmjene}:</small>
        <p> {props.data?.updatedAt ? new Date(props.data?.updatedAt).toLocaleString() : 'Nepoznato'}</p>
        <small>{translate[language].Datumzadnjeobradeagenta}:</small>
        <p> {props.data?.agentUpdatedAt ? new Date(props.data?.agentUpdatedAt).toLocaleString() : 'Nepoznato'}</p>
        {props.type === "deal" && <>
            <small>Razdužen:</small>
            {props.data.DealMoneyReceived ? <p style={{ color: "rgb(149, 255, 205)" }}>Da</p> : <p style={{ color: "rgb(255, 149, 149)" }}>Ne</p>}
        </>}
        {(props.type === "deal" && props.data.Status === 98 && props.data?.DealRejectReasons?.includes("Kupac ne zeli posiljku")) && <>
            <small>Opomena poslana:</small>
            {props.data.WarningSent ? <p style={{ color: "rgb(149, 255, 205)" }}>Da</p> : <p style={{ color: "rgb(255, 149, 149)" }}>Ne</p>}
        </>}
        {(props.type !== 'deal' && props.data.Status === 2) && <p>Pozvati ponovo: {moment(props.data.ScheduledCallTime).format('DD-MM-YYYY hh:mm')}</p>}
        {(props.data.Status === 5000 && props.type === "deal") && <>
            <p>Datum dostave: {new Date(props.data.ScheduledDelivery).toLocaleDateString()}</p>
        </>}
        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>

            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setSelectedUserID(props.data.UserID);
                setEditMode(false);
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};
const SetAgentMistake = props => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [newValue, setNewValue] = React.useState(props.data.DealRejectReasons);
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);


    let language = useSelector(state => state.language);
    const saveData = () => {
        let data = {
            ...props.data,
            DealRejectReasons: newValue
        };
        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>
        {editMode ? <>
            <p>Agent je napravio gresku koja je zahtijevala ispravljanje:</p>
            <Dropdown
                theme="dark"
                accent="rgb(102, 100, 229)"
                data={[
                    { name: "Da", value: true },
                    { name: "Ne", value: false }

                ]}
                selected={(() => {
                    if (props?.data?.DealRejectReasons?.includes("Greska agenta") ||
                        props?.data?.DealRejectReasons?.includes("Greska agenta - nije utvrdjena cijena paketa") ||
                        props?.data?.DealRejectReasons?.includes("Greska agenta - paket poslan prije vremena") ||
                        props?.data?.DealRejectReasons?.includes("Greska agenta - klijent je 'primoran' da potvrdi narudzbu tj da da adresu")
                    ) {
                        return 1
                    } else {
                        return 0
                    }
                })()}
                onChange={e => {
                    let remove = [
                        "Greska agenta",
                        "Greska agenta - nije utvrdjena cijena paketa",
                        "Greska agenta - paket poslan prije vremena",
                        "Greska agenta - klijent je 'primoran' da potvrdi narudzbu tj da da adresu"
                    ]
                    let tmp = props.data.DealRejectReasons;
                    let filtered;
                    if (!e.value) {
                        filtered = tmp.filter(element => !remove.includes(element));
                    } else {
                        if (tmp.includes("Greska agenta")) {
                            filtered = tmp
                        } else {
                            filtered = [...tmp, "Greska agenta"]
                        }
                    }
                    setNewValue(filtered)
                }}
            />
        </> : <small style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '15px', color: '#fd8f8f', marginBottom: '10px' }}>{
            (props?.data?.DealRejectReasons?.includes("Greska agenta") ||
                props?.data?.DealRejectReasons?.includes("Greska agenta - nije utvrdjena cijena paketa") ||
                props?.data?.DealRejectReasons?.includes("Greska agenta - paket poslan prije vremena") ||
                props?.data?.DealRejectReasons?.includes("Greska agenta - klijent je 'primoran' da potvrdi narudzbu tj da da adresu")
            ) ? "Agent je napravio gresku na leadu koja je zahtijevala izmjene" : "Agent nije napravio gresku na leadu koja je zahtijevala izmjene"
        }</small>}





        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>

            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setEditMode(false);
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
};

const ArticlesInfo = props => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [allArticles, setAllArticles] = React.useState([]);
    const [country, setCountry] = React.useState(1)
    const [selectedArticles, setSelectedArticles] = React.useState(props.data.Articles ? JSON.parse(props.data.Articles) : []);
    const aditionalInfo = React.useRef(props.data.AdditionalShippmentInfo ? props.data.AdditionalShippmentInfo : 'Nema dodatnih informacija')
    const [print, setPrint] = React.useState(props.data.isRecurringWithGuarantee && props.data.isRecurring)
    const [shouldPrintGuarantee, setshouldPrintGuarantee] = React.useState(true);

    let language = useSelector(state => state.language);
    const saveData = () => {
        let data = {
            ...props.data,
            Articles: selectedArticles,
            AdditionalShippmentInfo: aditionalInfo.current.value,
            shouldPrintGuarantee: shouldPrintGuarantee,

        };
        setSpinner(true);
        props.onChange(data, false).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };
    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            data: {
                filters: [
                    { name: "CountryCode", op: "eq", value: props.data.Country }
                ]
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setCountry(res.data.data[0])
            } else {
            };
        }).catch(() => {
        });
    };
    const getArticles = async () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/articles/getAllArticlesAgent`,
            data: {
                CountryID: country.ID
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllArticles(res.data.data)
            };
        }).catch(() => null);
    };
    React.useEffect(() => {
        getCountries()
    }, [props.data])
    React.useEffect(() => {
        getArticles()
    }, [country])

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>

        <small style={{ marginBottom: '15px' }}>{translate[language].Artikli}</small>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>
        {!editMode ? <> {props.data?.Articles ? JSON.parse(props?.data?.Articles)?.map((item => {
            return <div className="ccmanager__singleLead__box__article" >
                <p style={{ borderRight: '1px solid white', borderLeft: '1px solid white' }}>{item.data.text.split('-')[0]} {item.data.quantity}x  </p>
                <p style={{ borderRight: '1px solid white' }}>{item.data.text.split('-')[1]}  </p>

            </div>
        })) : <p>Nepoznato</p>
        }
            <div className="ccmanager__singleLead__box__article" style={{ borderBottom: '1px solid white' }}>
                <p style={{ borderRight: '1px solid white', borderLeft: '1px solid white' }}>{translate[language].Ukupnacijena}:  </p>
                <p style={{ borderRight: '1px solid white' }}>{(() => {
                    if (editMode) return "Uređuje se...";

                    if (props.data.Amount) {
                        return `${props.data.Amount} ${props.currency} `
                    } else {
                        let total = 0;
                        for (let article of selectedArticles) {
                            let price = article.data.text.split("-").pop();
                            price = price.split(" ").shift();
                            price = Number(price);
                            if (!isNaN(price)) total += price
                        };
                        return `${total} ${props.currency}`;
                    };
                })()}
                    {/* //{props.data?.Amount ? props.data?.Amount + ' ' + props.currency : 'Nepoznato'}  */}
                </p>
            </div>
            <small>{translate[language].Dodatneinformacijezakurira}:</small>
            <p> {props.data?.AdditionalShippmentInfo ? props.data.AdditionalShippmentInfo : translate[language].Nemadodatnihinformacija}</p>
            {print === true && <>
                <small>{translate[language].Dalijepotrebnoprintatigaranciju}:</small>
                <p style={{ color: props.data.shouldPrintGuarantee ? "#95ffcd" : "#ff9595" }}>{props.data.shouldPrintGuarantee ? translate[language].Da : translate[language].Ne}</p>
            </>}

        </> : <>
            {allArticles && <Dropdown theme='dark' accent="#6664E5A1" style={{ marginBottom: '10px', width: '90%', marginTop: '15px' }}

                data={allArticles?.map((item, index) => {
                    return { name: `(${country.CountryCode}) ${item.ArticleName} - ${Number(item.ArticlePrice).toFixed(2)} ${country.CountryCurrency}`, value: { id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`, data: { id: item.ID, text: `(${country.CountryCode}) ` + item.ArticleName + ' -' + Number(item.ArticlePrice).toFixed(2) + ' ' + country.CountryCurrency, quantity: 1 } } }
                })} placeholder='Dodaj artikal' onChange={(e) => {
                    setSelectedArticles([...selectedArticles, e.value])
                }} />}

            {selectedArticles ? selectedArticles?.map((item => {
                let tmp = item.data.text.split("-");
                let artPrice = tmp.pop();
                let artName = tmp.join("-");

                return <div className="ccmanager__singleLead__box__article">
                    <p style={{ borderRight: '1px solid white', borderLeft: '1px solid white', width: '25%' }}>{artName}  </p>
                    <p style={{ borderRight: '1px solid white', width: '25%' }}>{artPrice}  </p>
                    <p style={{ borderRight: '1px solid white', width: '25%', display: 'flex', flexDirection: 'column' }}>Količina: <input defaultValue={item.data.quantity} type='number' onChange={(e) => {
                        item.data.quantity = Number(e.target.value)
                    }} style={{ backgroundColor: 'transparent', width: '80%', border: '1px solid white', color: 'white', textAlign: 'center' }} />   </p>
                    <p style={{ borderRight: '1px solid white', width: '25%', backgroundColor: 'red', cursor: 'pointer' }} onClick={() => {
                        setSelectedArticles(d => [
                            ...d.filter(dt => dt.id < item.id),
                            ...d.filter(dt => dt.id > item.id)
                        ]);
                    }} >Obriši artikal  </p>

                </div>
            })) : <p>Nepoznato</p>
            }
            {<div className="ccmanager__singleLead__box__article" style={{ borderBottom: '1px solid white' }}>
                <p style={{ borderRight: '1px solid white', borderLeft: '1px solid white' }}>Ukupna cijena:  </p>
                <p style={{ borderRight: '1px solid white' }}>{(() => {
                    if (editMode) return "Uređuje se...";

                    if (props.data.Amount) {
                        return `${props.data.Amount} ${props.currency} `
                    } else {
                        let total = 0;
                        for (let article of selectedArticles) {
                            let price = article.data.text.split("-").pop();
                            price = price.split(" ").shift();
                            price = Number(price);
                            if (!isNaN(price)) total += price
                        };
                        return `${total} ${props.currency}`;
                    };
                })()}</p>
            </div>}
            <small>{translate[language].Dodatneinformacijezakurira}:</small>
            <CustomInput defaultValue={props.data.AdditionalShippmentInfo ? props.data.AdditionalShippmentInfo : translate[language].Nemadodatnihinformacija} ref={aditionalInfo} placeholder="" theme="dark" accent="rgb(102, 100, 229)" />
            {print === true && <>
                <small>{translate[language].Dalijepotrebnoprintatigaranciju}</small>
                <p><CustomButtonSmall value={translate[language].Da} accent={shouldPrintGuarantee ? 'rgb(47, 136, 74)' : 'gray'} onClick={() => { setshouldPrintGuarantee(true) }} /> <CustomButtonSmall value={translate[language].Ne} accent={!shouldPrintGuarantee ? 'rgb(247, 87, 87)' : 'gray'} onClick={() => { setshouldPrintGuarantee(false) }} /> </p>
            </>}

            <div className="ccmanager__singleLead__box__editBtns" style={{
                marginTop: editMode ? "20px" : null,
                maxHeight: editMode ? "40px" : null,
                pointerEvents: editMode ? "all" : "none"
            }}>

                <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
                <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                    setSelectedArticles(JSON.parse(props.data.Articles));
                    setshouldPrintGuarantee(props.data.shouldPrintGuarantee);
                    setEditMode(false)
                }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
            </div>
        </>}


    </div>
}
const AdditionalDataInfo = (props) => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [additionalData, setAdditionalData] = React.useState({});
    const [newAdditionalData, setNewAdditionalData] = React.useState([]);
    let language = useSelector(state => state.language);
    const saveData = () => {
        let finalAdditionalData = {};

        for (let item of newAdditionalData) {
            finalAdditionalData[item.name] = item.value;
        };

        let data = {
            ...props.data,
            AdditionalData: finalAdditionalData
        };
        setSpinner(true);
        props.onChange(data, true).then(() => {
            setSpinner(false);
            setEditMode(false);
        });
    };

    React.useEffect(() => {
        let tmp = {};
        try {
            tmp = JSON.parse(props?.data?.AdditionalData);
        } catch (e) {
            return;
        };


        setAdditionalData(tmp);
    }, [props.data]);

    React.useEffect(() => {
        if (editMode) {
            let tmp = [];
            for (let key of Object.keys(additionalData)) {
                tmp.push({ name: key, value: additionalData[key] });
            };
            setNewAdditionalData(tmp);
        };
    }, [editMode]);

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>
        <div className="ccmanager__singleLead__box__editBtn" style={{
            right: (editMode || !props.canEdit) ? "-100%" : null,
            pointerEvents: (editMode || !props.canEdit) ? "none" : "all"
        }} onClick={() => setEditMode(true)}>
            <img src="/images/editPen.png" />
            <p>{translate[language].Uredi}</p>
        </div>
        <small style={{ marginBottom: '15px' }}>{translate[language].Dodatneinformacijeoleadu}</small>
        {!editMode ? <> {additionalData && Object.keys(additionalData).map((item, index) => {
            if (typeof (additionalData[item]) !== "object" || additionalData[item] === null) {
                return <p style={{ width: '100%' }} key={index}>
                    <strong>{item}:</strong>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ userSelect: 'auto' }}>{JSON.stringify(additionalData[item])}</span>

                </p>
            } else if (typeof (additionalData[item]) === "object") {

                return <p style={{ width: '100%' }} key={index}>
                    <strong>{item}:</strong>
                    &nbsp;&nbsp;&nbsp;
                    {
                        Object.keys(additionalData[item]).map(elem => {
                            return <p style={{ width: '100%' }} key={index}>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ userSelect: 'auto' }}>{JSON.stringify(elem)}</span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ userSelect: 'auto' }}>{(() => {
                                    let tmp = JSON.stringify(additionalData[item][elem]);
                                    try {
                                        tmp = decodeURI(JSON.stringify(additionalData[item][elem]))
                                    } catch {

                                    }
                                    return tmp;

                                })()}</span>

                            </p>
                        })
                    }


                </p>
            }

        })}</> : <>

            <div className="ccmanager__singleLead__box__additionalData">
                <div className="ccmanager__singleLead__box__additionalData__item">
                    <small>{translate[language].Naziv}</small>
                    <small>Valuta</small>
                </div>
                {newAdditionalData.map((nd, ndi) => {
                    return <div className="ccmanager__singleLead__box__additionalData__item" key={`${ndi}-${newAdditionalData.length}`}>
                        <CustomInput defaultValue={nd.name} theme="dark" accent="rgb(102, 100, 229)" style={{ width: "100%", minWidth: 0, fontSize: "14px" }} onChange={(e) => {
                            setNewAdditionalData(nda => nda.map((ndaf, ndafi) => {
                                let tmp = { ...ndaf };
                                if (ndafi === ndi) tmp.name = e?.target?.value
                                return tmp;
                            }));
                        }} />
                        <CustomInput defaultValue={nd.value} theme="dark" accent="rgb(102, 100, 229)" style={{ width: "100%", minWidth: 0, fontSize: "14px" }} onChange={e => {
                            setNewAdditionalData(nda => nda.map((ndaf, ndafi) => {
                                let tmp = { ...ndaf };
                                if (ndafi === ndi) tmp.value = e?.target?.value
                                return tmp;
                            }));
                        }} />
                        <CustomButtonSmall value="Obriši" accent="rgb(233, 86, 86)" style={{ height: "30px" }} onClick={() => {
                            setNewAdditionalData(nda => nda.filter((_, ndafi) => ndafi !== ndi));
                        }} />
                    </div>
                })}
            </div>

            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <CustomButtonSmall theme="dark" accent="#2b2c3e" value="Dodaj parametar" style={{ border: "1px solid #999FAE", width: "150px" }} onClick={() => {
                    setNewAdditionalData(ad => {
                        return [...ad, {
                            name: `prop-${Object.keys(ad).length}`,
                            value: ""
                        }]
                    });
                }} />
            </div>
        </>
        }
        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Spremi} style={{ marginRight: "10px", border: "1px solid #999FAE" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value={translate[language].Otkaži} onClick={() => {
                setEditMode(false);
                setNewAdditionalData([]);
            }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
    </div>
}

const AdditionalDataInfoManager = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [additionalData, setAdditionalData] = React.useState({});
    let language = useSelector(state => state.language);


    React.useEffect(() => {
        let tmp = {};
        try {
            tmp = JSON.parse(props?.data?.AdditionalData);
        } catch (e) {
            return;
        };


        setAdditionalData(tmp);
    }, [props.data]);



    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>

        <small style={{ marginBottom: '15px' }}>{translate[language].Dodatneinformacijeoleadu}</small>
        <> {additionalData && Object.keys(additionalData).map((item, index) => {
            if (typeof (additionalData[item]) !== "object" || additionalData[item] === null) {
                if (item === "STAngle") return <p style={{ width: '100%' }} key={index}>
                    <strong>{item}:</strong>
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ userSelect: 'auto' }}>{JSON.stringify(additionalData[item])}</span>

                </p>
            } else if (typeof (additionalData[item]) === "object") {

                if (item === "GenericData" || item === "CampaignDescriptors") return <p style={{ width: '100%' }} key={index}>
                    <strong>{item}:</strong>
                    &nbsp;&nbsp;&nbsp;
                    {
                        Object.keys(additionalData[item]).map(elem => {
                            return <p style={{ width: '100%' }} key={index}>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ userSelect: 'auto' }}>{JSON.stringify(elem)}</span>
                                &nbsp;&nbsp;&nbsp;
                                <span style={{ userSelect: 'auto' }}>{(() => {
                                    let tmp = JSON.stringify(additionalData[item][elem]);
                                    try {
                                        tmp = decodeURI(JSON.stringify(additionalData[item][elem]))
                                    } catch {

                                    }
                                    return tmp;

                                })()}</span>

                            </p>
                        })
                    }


                </p>
            }

        })}</>

    </div>
}

const Historija = (props) => {
    const [leadHistory, setLeadHistory] = React.useState()
    const statusSelector = useSelector(state => state?.statusCodes?.leads ?? {});

    let chatUsers = useSelector((state) => state.chatUsers);
    let userInfo = useSelector((state) => state.userData.userData.UserInfo);
    let ReferenceID
    if (props.type === 'deal') {
        ReferenceID = props.data.LeadID
    } else {
        ReferenceID = props.ID
    }
    const getLeadHistory = () => {
        setLeadHistory();
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllHistory`,
            data: {
                ReferenceName: 'lead',
                ReferenceID: ReferenceID
            },
            ...backendModule.axiosConfig
        }).then(async res => {
            let tmp
            if (res.data.status === 'ok') {
                tmp = res.data.data
                if (props.type === 'deal') {
                    await axios({
                        method: 'POST',
                        url: `${backendModule.backendURL}/common/getAllHistory`,
                        data: {
                            ReferenceName: 'deal',
                            ReferenceID: props.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(result => {
                        if (result.data.status === 'ok') {
                            tmp = [
                                ...result.data.data,
                                ...tmp
                            ];
                        }
                    }).catch(() => null);

                }
                setLeadHistory(tmp)
            }
        }).catch(() => null);
    };
    React.useEffect(() => {
        getLeadHistory()
    }, [])
    return <div className="ccmanager__history">

        {!leadHistory ? <Spinner align="center" color='#6664E5' style={{ marginTop: "20px" }} /> : leadHistory?.map((item, index) => {
            let mngCC = false;
            if (userInfo.Flags.isCCManager) {
                if (props.cc.includes(item.UserID)) {
                    mngCC = true;
                }
            }
            if (userInfo.Flags.isAdmin || userInfo.Flags.isMarketing || mngCC || userInfo.Flags.isSalesDirector) {
                if (item.type === "history") {
                    return <div key={index} className="component__agentCall__info__tabsContent__single__history leadMoreInfoHistory" >
                        {chatUsers.map((elem, eindex) => {
                            if (item.UserID === elem.ID) {
                                return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                    <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                        <p  >{elem?.FirstName ?? ""}  {elem?.LastName ?? ""}</p>
                                        <small className="component__agentCall__info__tabsContent__single__history__content__date">{moment(item?.createdAt).toDate().toLocaleString()}</small>
                                    </div>
                                    <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                                </div>
                            }
                        })}
                        <div className="component__agentCall__info__tabsContent__single__history__content">
                            <p style={{ textAlign: 'start' }}>{item?.Comment}</p>
                        </div>
                    </div>
                } else if (item.type === "activity") {
                    let elem = chatUsers.find(cUsr => cUsr.ID === item.UserID);
                    return <div key={index} className="component__agentCall__info__tabsContent__single__history leadMoreInfoHistory" style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        boxShadow: item?.WrongAgent ? "0px 0px 10px 0px rgb(234, 8, 8)" : null
                    }}>
                        <div className="component__agentCall__info__tabsContent__single__history__user">
                            <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                <p>{elem?.FirstName}  {elem?.LastName}</p>
                                <small className="component__agentCall__info__tabsContent__single__history__content__date">{moment(item?.createdAt).toDate().toLocaleString()}</small>
                            </div>
                            <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                        </div>
                        <div className="component__agentCall__info__tabsContent__single__history__content" style={{ width: "auto", flexDirection: "column", paddingLeft: 0, alignItems: "flex-end", rowGap: "10px" }}>
                            <div className="component__agentCall__info__tabsContent__single__history__content__activity">
                                <span>Agent: {item?.UserRole}</span>
                                <img src="/images/rightArrowBIg.svg" />
                                <span>Lead: {item?.LeadRole}</span>
                            </div>
                            <div className="component__agentCall__info__tabsContent__single__history__content__activity">
                                <span style={{
                                    color: getLeadStatusColor2(item.OldStatus)
                                }}>{statusSelector[item.OldStatus]}</span>
                                <img src="/images/rightArrowBIg.svg" />
                                <span style={{
                                    color: getLeadStatusColor2(item.Status)
                                }}>{statusSelector[item.Status]}</span>
                            </div>
                        </div>
                    </div>
                };
            }

        })}
    </div>
}
const Comments = (props) => {
    const [leadComments, setLeadComments] = React.useState()
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [filterTab, setFilterTab] = React.useState(0);
    const [infoP, setInfoP] = React.useState();
    let language = useSelector(state => state.language);

    let chatUsers = useSelector((state) => state.chatUsers);
    let curUserSelector = useSelector((state) => state.userData.userData.UserInfo);
    const curUserFlags = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    const comment = React.useRef('');

    let ReferenceID
    if (props.type === 'deal') {
        ReferenceID = props.data.LeadID
    } else {
        ReferenceID = props.ID
    }

    const getLeadComments = () => {
        const filters = filterTab === 0 ? null :
            (() => {
                switch (filterTab) {
                    default: return [];
                    case 1: return [{ name: "Type", op: "eq", value: "comment" }];
                    case 2: return [{ name: "Type", op: "eq", value: "audio" }];
                    case 3: return [{ name: "Type", op: "eq", value: "sms" }];
                };
            })()

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllComments`,
            data: {
                ReferenceName: 'lead',
                ReferenceID: ReferenceID,
                filters
            },
            ...backendModule.axiosConfig
        }).then(async res => {
            let tmp
            console.log(res.data.data)
            if (res.data.status === 'ok') {
                tmp = res.data.data
                if (props.type === 'deal') {
                    await axios({
                        method: 'POST',
                        url: `${backendModule.backendURL}/common/getAllComments`,
                        data: {
                            ReferenceName: 'deal',
                            ReferenceID: props.ID,
                            filters
                        },
                        ...backendModule.axiosConfig
                    }).then(result => {
                        if (result.data.status === 'ok') {
                            tmp = [
                                ...result.data.data,
                                ...tmp
                            ];
                        };
                    }).catch(() => null);

                }
                setLeadComments(tmp)
            }
        }).catch(() => null);
    };
    React.useEffect(() => {
        getLeadComments()
    }, [filterTab])

    const postComment = () => {
        if (!comment.current.value) return;
        setInfoP();

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/addComment`,
            data: {
                ReferenceName: props.type === "deal" ? "deal" : "lead",
                ReferenceID: props.ID,
                Comment: comment.current.value.trim()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                comment.current.value = "";
                getLeadComments();
            } else {
                setInfoP("Greška prilikom ostavljanja komentara!");
            };
        }).catch(() => setInfoP("Greška prilikom ostavljanja komentara!"));
    };

    const sendSMS = () => {
        if (!comment.current.value) return;
        setInfoP();

        let data = {
            Number: props.data.PhoneNumber,
            Text: comment.current.value.trim(),
            isPlarity: !!(props.data?.isPlarityBrand)
        };
        if (props.type === "deal") {
            data.DealID = props.data.ID
        } else {
            data.LeadID = props.data.ID
        };

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/sendSMS`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                comment.current.value = "";
                setLeadComments();
                setTimeout(() => getLeadComments(), 200);
            } else {
                setInfoP("Greška prilikom slanja SMS-a!");
            };
        }).catch(() => setInfoP("Greška prilikom slanja SMS-a!"));
    };

    const CallControl = props2 => {
        const curUser = chatUsers.find(u => u.ID === props2.data.ControlUserID);

        return <div className="ccmanager__history__callControl">
            <p></p>

            <div className="ccmanager__history__callControl__wrap">
                <div className="ccmanager__history__callControl__wrap__top">
                    {(curUserFlags?.isAdmin || curUserFlags?.isMarketing) ? <>
                        <img className="ccmanager__history__callControl__wrap__top__img" src={curUser?.Image ?? "/images/undefined.png"} onError={e => e.currentTarget.src = "/images/undefined.png"} />
                        <p>[Kontrola] {curUser?.FirstName} {curUser?.LastName}</p>
                    </> : <p>[Kontrola] Anonimna kontrola poziva</p>}
                </div>

                <div className="ccmanager__history__callControl__wrap__bottom">
                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Ljubaznost</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentLjubaznost_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentLjubaznost_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentLjubaznost_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentLjubaznost_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentLjubaznost_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Upitnik</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentUpitnik_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentUpitnik_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentUpitnik_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentUpitnik_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentUpitnik_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Benefiti</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentBenefiti_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentBenefiti_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentBenefiti_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentBenefiti_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentBenefiti_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Ponuda</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentPonuda_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentPonuda_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentPonuda_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentPonuda_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentPonuda_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Prodaja</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentProdaja_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentProdaja_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentProdaja_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentProdaja_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentProdaja_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Zahvalnost</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentZahvalnost_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentZahvalnost_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentZahvalnost_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentZahvalnost_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentZahvalnost_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Stručnost</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentStrucnost_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentStrucnost_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentStrucnost_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentStrucnost_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentStrucnost_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__rating">
                        <p>Ocjena klijenta</p>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentKlijent_stat > 0 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentKlijent_stat > 1 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentKlijent_stat > 2 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentKlijent_stat > 3 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                        <span className={`ccmanager__history__callControl__wrap__bottom__rating__item ${props2.data?.AgentKlijent_stat > 4 ? "ccmanager__history__callControl__wrap__bottom__rating__item--active" : ""}`}></span>
                    </div>

                    <div className="ccmanager__history__callControl__wrap__bottom__comment">
                        <p className="ccmanager__history__callControl__wrap__bottom__comment__head">Komentar</p>
                        <p>{props2.data?.Comment}</p>
                    </div>
                </div>
            </div>
        </div>
    };


    return <div className="ccmanager__history">
        <div className="ccmanager__singleLead__buttons" style={{ justifyContent: "flex-start", padding: "0" }}>
            <CustomButtonSmall value={translate[language].Komentar} className={selectedTab === 0 ? "active" : ""} onClick={() => setSelectedTab(0)} />
            {
                (curUserFlags.isAdmin || curUserFlags.isCCManager || curUserFlags.isShippmentAgent) &&
                <CustomButtonSmall value="✉️ SMS" className={selectedTab === 1 ? "active" : ""} onClick={() => setSelectedTab(1)} />
            }

            {selectedTab === 1 && <div className="ccmanager__history__SMSTemplate">
                <CustomButtonSmall value="SMS predlošci" accent="#2b2c3e" style={{ width: "150px", border: "1px solid #E264E59E" }} onClick={e => {
                    animateBox(e, <GetSMSTemplates component={comment.current} />)
                }} />
            </div>}
        </div>
        <div className="component__agentCall__info__tabsContent__single__addComment">
            <textarea placeholder={selectedTab === 0 ? `${translate[language].Komentar}...` : selectedTab === 1 ? "SMS..." : ""} ref={comment}></textarea>
            <button onClick={() => {
                switch (selectedTab) {
                    case 0: return postComment();
                    case 1: return sendSMS();
                    default: return;
                };
            }} style={{
                background: "#2b2c3e",
                border: "1px solid #6664e5",
                padding: "0 5px",
                height: "40px"
            }}>{(() => {
                switch (selectedTab) {
                    case 0: return translate[language].Ostavikomentar;
                    case 1: return "Pošalji SMS";
                    default: return "";
                };
            })()}</button>
        </div>
        {infoP && <p style={{ color: "#e89595" }}>{infoP}</p>}

        <div className="ccmanager__singleLead__buttons" style={{ justifyContent: "flex-start", padding: "0" }}>
            <CustomButtonSmall value="Svi" className={filterTab === 0 ? "active" : ""} onClick={() => setFilterTab(0)} />
            <CustomButtonSmall value="💬 Komentar" className={filterTab === 1 ? "active" : ""} onClick={() => setFilterTab(1)} />
            <CustomButtonSmall value="🔊 Poziv" className={filterTab === 2 ? "active" : ""} onClick={() => setFilterTab(2)} />
            <CustomButtonSmall value="✉️ SMS" className={filterTab === 3 ? "active" : ""} onClick={() => setFilterTab(3)} />
        </div>

        {leadComments?.length === 0 && <p>Trenutno nema komentara za prikaz</p>}
        {!leadComments ? <Spinner align="center" color='#6664E5' style={{ marginTop: "20px" }} /> : leadComments?.map((item, index) => {
            let mngCC = false;
            if (curUserSelector.Flags.isCCManager) {
                if (props.cc.includes(item.UserID)) {
                    mngCC = true;
                }
            }
            if (curUserSelector.Flags.isAdmin || curUserSelector.Flags.isMarketing || mngCC || curUserSelector.Flags.isShippmentAgent) {
                return <div key={index} className="component__agentCall__info__tabsContent__single__history leadMoreInfoHistory" style={{ 'backgroundColor': '#2b2c3e', position: "relative" }}>
                    {item.CallControl && <img src="/images/callQualityControl.png" className="ccmanager__history__callControl__badge" />}

                    {chatUsers.map((elem, eindex) => {
                        if (item.UserID === elem.ID) {
                            return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                    <p><span>{(() => {
                                        switch (item.Type) {
                                            case "comment": return "Komentar";
                                            case "audio": return "Poziv";
                                            case "sms": return "SMS";
                                            case "transcription": return "Transkripcija";
                                            default: return "?";
                                        }
                                    })()}</span><span>{elem.FirstName}  {elem.LastName}</span></p>
                                    <small className="component__agentCall__info__tabsContent__single__history__content__date">{moment(item?.createdAt).toDate().toLocaleString()}</small>
                                </div>
                                <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                            </div>
                        }
                    })}
                    {(item?.Type === 'comment' || item?.Type === "sms") && <div className="component__agentCall__info__tabsContent__single__history__content">
                        <p style={{ textAlign: "start" }}>{item?.Comment}</p>
                    </div>}
                    {(item?.Type === 'transcription') && <div className="component__agentCall__info__tabsContent__single__history__content">
                        {(() => {
                            let tmp = item?.Comment.split("/n");
                            return <p>
                                {
                                    tmp.map(e => {
                                        if(e!=="")
                                        return <> <p style={{ textAlign: "start" }}>-{e.replace("\n","")}</p> <br /></>
                                    })
                                }
                            </p>
                            
                        })()}

                    </div>}
                    {/* hereee */}
                    {item?.Type === 'audio' && <div className="component__agentCall__info__tabsContent__single__history__content">

                        {(curUserFlags?.isAdmin || curUserFlags?.isSalesDirector || curUserFlags?.isMarketing || (curUserFlags?.isCCManager && !curUserFlags?.CCManager_disallowAdvancedEdit) || curUserFlags.isShippmentAgent) ? <>
                            <AudioPlayer selectRecording={true} timestamps={item.Timestamps ?? []} src={`${backendModule.backendURL}/staticContent/audioStatic/${item.Comment}`} accent="#6664e5" theme='dark' />
                            {(() => {
                                axios({
                                    method: "POST",
                                    url: `${backendModule.backendURL}/genericTemplates/getAllTemplates`,
                                    data: {
                                        pagination: 0,
                                        limit: null,
                                        filters: [{ name: "For", op: "eq", value: "sms" }]
                                    },
                                    ...backendModule.axiosConfig
                                })
                            })()}

                        </>
                            :
                            <p>Nemate role potreban za ucitavanja snimka poziva</p>
                        }                </div>}

                    {item.CallControl && <CallControl data={item.CallControl} />}

                </div >
            }
        })}
    </div>
}

const GetSMSTemplates = props => {
    const [data, setData] = React.useState();
    let language = useSelector(state => state.language);
    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/genericTemplates/getAllTemplates`,
            data: {
                pagination: 0,
                limit: null,
                filters: [{ name: "For", op: "eq", value: "sms" }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="ccmanager__singleLead__smsTemplates">
        <div className="ccmanager__singleLead__smsTemplates__buttons">
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value="Osvježi" style={{ border: "1px solid #E264E59E" }} />
            <CustomButtonSmall theme="dark" accent="#2b2c3e" value="X" onClick={props.onClose} style={{ border: "1px solid rgb(233, 86, 86)" }} />
        </div>
        {data ? <>
            {data.status === "error" ? <p>Došlo je do greške</p> : <>
                <FilteredCustomTable
                    accent="#3D3D55"
                    theme="dark"
                    headers={[translate[language].Naziv]}
                    data={data.data.map(elem => {
                        return [
                            { keyID: String(elem.ID), type: "text", text: elem.Name },
                            {
                                keyID: String(elem.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(elem.ID), type: "button", text: "Odaberi", onClick: () => {
                                            try {
                                                props.component.value = elem.Template;
                                            } catch { };
                                            props.onClose();
                                        }, style: { border: "1px solid #E264E59E", background: "#2b2c3e" }
                                    }
                                ]
                            }
                        ];
                    })}
                />
            </>}
        </> : <Spinner align="center" color="white" />}
    </div>
};

const Storn = (props) => {
    let userData = useSelector((state) => state.userData)
    React.useEffect(() => {
        document.querySelector('.component__storn').style.opacity = 1
    }, [])
    let language = useSelector(state => state.language);
    const saveData = () => {
        let data = {
            ...props.deal,
            authUser: userData.userData.UserInfo.ID,
            Status: 50,
            ScheduledDelivery: moment(new Date()).startOf('day').toLocaleString()
        }
        if (props.onChange) {
            data.Articles = JSON.stringify(data.Articles);
            props.onChange(data, false);
            props.onClose();
        } else {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/deals/editDeal`,
                data,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    props.onClose()
                }
            }).catch((err) => { })
        };
    }
    return <div className="component__storn" >
        <p style={{ fontSize: '22px' }}>{translate[language].Jestedalisigurnidaželitestorniratiovajdeal} </p>
        <button style={{ 'backgroundColor': 'red' }} onClick={() => { saveData() }} >{translate[language].Storniraj}</button>
    </div>
}
const ChangeArticles = (props) => {
    const [selectedArticles, setSelectedArticles] = React.useState(props.deal.Articles ? JSON.parse(props.deal.Articles) : []);
    const [scheduledDelivery, setScheduledDelivery] = React.useState(moment(props.deal.ScheduledDelivery));
    const [add, setAdd] = React.useState(false)
    const [allArticles, setAllArticles] = React.useState([]);
    const [country, setCountry] = React.useState(1);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedCountry, setSelectedCountry] = React.useState(props.deal.Country);
    const [allCities, setAllCities] = React.useState();
    const [selectedCity, setSelectedCity] = React.useState();

    let language = useSelector(state => state.language);
    const nameRef = React.useRef();
    const phoneRef = React.useRef();
    const addressRef = React.useRef();
    const houseNumberRef = React.useRef();
    const postmanRef = React.useRef();

    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            data: {
                filters: [
                    { name: "CountryCode", op: "eq", value: props.deal.Country }
                ]
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setCountry(res.data.data[0])
            } else {
            };
        }).catch(() => {
        });
    };
    const getCities = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: selectedCountry
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllCities(res.data.data)
            };
        }).catch(() => null);
    };
    React.useEffect(() => {
        getCities()
    }, [selectedCountry])
    const getArticles = async () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/articles/getAllArticlesAgent`,
            data: {
                CountryID: country.ID
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllArticles(res.data.data)
            };
        }).catch(() => null);
    };
    React.useEffect(() => {
        getCountries()
    }, [props.data])
    React.useEffect(() => {
        if (!country.ID) return
        getArticles()
    }, [country])

    const saveData = () => {
        if (!nameRef.current.value) return;
        if (!phoneRef.current.value) return;
        if (!addressRef.current.value) return;
        if (!houseNumberRef.current.value) return;

        let newDelivery = moment(scheduledDelivery);
        if (!newDelivery.isValid()) newDelivery = moment();
        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/dealArticleChangeRequest`,
            data: {
                ID: props.deal.ID,
                Articles: selectedArticles,
                ScheduledDelivery: newDelivery.startOf("day"),
                City: selectedCity,
                FirstLastName: nameRef.current.value,
                PhoneNumber: phoneRef.current.value,
                Address: addressRef.current.value,
                HouseNumber: houseNumberRef.current.value,
                AdditionalShippmentInfo: postmanRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {

        }).catch((err) => { }).finally(() => { setSpinner(false) })
    }

    return <div className="changeArticles">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>

        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>{translate[language].Datumslanja} <CustomInput style={{ marginLeft: "10px" }} type="date" theme="dark" value={moment(scheduledDelivery).format("YYYY-MM-DD")} onChange={e => setScheduledDelivery(moment(e.target.value))} /></p>
        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Imeiprezime} <CustomInput ref={nameRef} defaultValue={props.deal.FirstLastName} style={{ marginLeft: "10px" }} theme="dark" placeholder="" /></p>
        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Brojtelefona} <CustomInput ref={phoneRef} defaultValue={props.deal.PhoneNumber} style={{ marginLeft: "10px" }} theme="dark" placeholder="" /></p>
        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Adresa} <CustomInput ref={addressRef} defaultValue={props.deal.Address} style={{ marginLeft: "10px" }} theme="dark" placeholder="" /></p>
        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Brojkuće} <CustomInput ref={houseNumberRef} defaultValue={props.deal.HouseNumber} style={{ marginLeft: "10px" }} theme="dark" placeholder="" /></p>
        {allCities && <>
            <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Grad}
                <Dropdown style={{ marginLeft: "10px" }} theme="dark" accent="#6664E5" data={allCities.map(elem => {
                    return {
                        name: `${elem.Name} (${elem.PttNo})`,
                        value: elem.CityID
                    }
                })} selected={allCities.indexOf(allCities.find(c => c.CityID === props.deal.City))} onChange={e => setSelectedCity(e.value)} /></p>

        </>}
        <p style={{ display: "flex", alignItems: "center", marginLeft: "20px", marginTop: "10px" }}>{translate[language].Dodatneinformacijekuriru} <CustomInput ref={postmanRef} defaultValue={props.deal.AdditionalShippmentInfo} style={{ marginLeft: "10px" }} theme="dark" placeholder="" /></p>

        <div style={{ height: 'auto', maxHeight: add ? '0px' : '100px', margin: '20px', overflow: 'hidden', transition: 'all 0.3s ease' }}>
            {allArticles && <Dropdown theme='dark' accent="#6664E5A1" style={{ width: '100%', }}

                data={allArticles?.map((item, index) => {
                    return { name: `(${country.CountryCode}) ${item.ArticleName} - ${Number(item.ArticlePrice).toFixed(2)} ${country.CountryCurrency}`, value: { id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`, data: { id: item.ID, text: `(${country.CountryCode}) ` + item.ArticleName + ' -' + Number(item.ArticlePrice).toFixed(2) + ' ' + country.CountryCurrency, quantity: 1 } } }
                })} onChange={(e) => {
                    setSelectedArticles([...selectedArticles, e.value])
                }} />}

        </div>
        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles" style={{ maxWidth: "90%" }}>
            {selectedArticles.map(art => {
                return <div key={art.id} className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle">
                    <CustomButtonSmall value={"Obrisi"} accent="red" style={{ width: "70px", height: "45px" }} onClick={() => {
                        setSelectedArticles(d => [
                            ...d.filter(dt => dt.id < art.id),
                            ...d.filter(dt => dt.id > art.id)
                        ]);
                    }} />

                    {art.data === null ? <Dropdown inlinePlaceholder="Odaberite artikal" theme={'dark'}
                        accent="#6664E5"
                        style={{ margin: "10px 0px", width: "calc(100% - 80px)" }}
                        data={(() => {
                            let tmpArray = [];
                            allArticles.map(curArticle => {
                                let CountryCurrency = "?";
                                let countryPrefix = "";
                                if (curArticle.ArticleName === "Gratis" && props.lead.isRecurring === false) {
                                } else {
                                    tmpArray.push({ name: `${countryPrefix}${curArticle.ArticleName} - ${curArticle.ArticlePrice} ${CountryCurrency}`, value: curArticle.ID })
                                }
                            })
                            return tmpArray;
                        })()}

                        onChange={e => {
                            if (!e) return;
                            setSelectedArticles(d => [
                                ...d.filter(t => t.id < art.id),
                                {
                                    id: art.id,
                                    data: {
                                        id: e.value,
                                        text: e.name,
                                        quantity: 1
                                    }
                                },
                                ...d.filter(t => t.id > art.id),
                            ])
                        }}
                    /> : <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle__selected">
                        <p>{art.data.text}</p>
                        <p> {translate[language].value}</p>
                        <CustomInput theme='dark' accent="#6664E5" type="number" value={art.data.quantity} onChange={e => {
                            let currInput = e.target.value;
                            setSelectedArticles(d => {
                                return d.map(dt => {
                                    if (dt.id === art.id) {
                                        let newVal = Number(currInput) ?? 0;
                                        dt.data.quantity = newVal > 0 ? newVal : 1;
                                    };
                                    return dt;
                                });
                            });
                        }} />
                    </div>}
                </div>
            })}
        </div>

        <CustomButtonSmall accent='#2b2c3e' value={translate[language].Spremi} style={{ marginTop: '10px', border: "1px solid #6664e5" }} onClick={() => { saveData() }} />
    </div>
}

const SaveDeal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    let language = useSelector(state => state.language);
    const saveDeal = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/agentSaveDeal`,
            data: {
                DealID: props.deal.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.changeTab();
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="ccmanager__singleLead__saveDeal">
        <div className="ccmanager__singleLead__saveDeal__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }} onClick={e => e.stopPropagation()}>
            <Spinner color="white" />
        </div>

        <p>Ovo će deal postaviti kao pošiljku koju treba hitno spasiti</p>
        <p>Status deal-a će preći u <span style={{ color: "rgb(163, 161, 253)" }}>problemi sa isporukom</span> i biti će postavljeno kao hitno spašavanje</p>
        <CustomButtonSmall theme="dark" value="Spasi pošiljku" style={{ marginTop: "20px", border: "1px solid #6664e5" }} accent="#2b2c3e" onClick={saveDeal} />
    </div>
};

const SmsReminderInfo = props => {
    const [editMode, setEditMode] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    let language = useSelector(state => state.language);
    const statusSelector = useSelector(state => state.statusCodes?.unpaidReminders ?? {});

    const saveData = (newState) => {
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/smsReminders/${newState ? "addReminder" : "removeReminderByDealID"}`,
            data: {
                DealID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            props.onChange();
        }).catch(() => {
            return null;
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="ccmanager__singleLead__box">
        <div className="ccmanager__singleLead__box__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="rgb(102, 100, 229)" />
        </div>

        <small>SMS upozorenja aktivna</small>
        <p style={{ color: (props.data.smsReminder !== null && props.data.smsReminder !== undefined) ? "#95ffcd" : "#ff9595" }}>
            {(props.data.smsReminder !== null && props.data.smsReminder !== undefined) ? "Da" : "Ne"}
            {props.canEdit && <CustomButtonSmall theme="dark" accent="#2b2c3e" value={(props.data.smsReminder !== null && props.data.smsReminder !== undefined) ? "Isključi" : "Uključi"} style={{
                height: "30px",
                marginLeft: "10px",
                border: "1px solid #6664e5"
            }} onClick={() => saveData((props.data.smsReminder !== null && props.data.smsReminder !== undefined) ? false : true)} />}
        </p>

        {(props.data.smsReminder !== null && props.data.smsReminder !== undefined) && <>
            <small>Trenutno stanje upozorenja</small>
            <p>{statusSelector[props.data.smsReminder]}</p>
            <div className="ccmanager__singleLead__box__statusDisplay" style={{ gridTemplateColumns: `repeat(${Object.keys(statusSelector).length}, 1fr)` }}>
                {Object.keys(statusSelector).map(key => {
                    return <span style={{
                        backgroundColor: key <= props.data.smsReminder ? getUnpaidRemindersStatusColor(props.data.smsReminder) : null
                    }}></span>
                })}
            </div>
        </>}

        <div className="ccmanager__singleLead__box__editBtns" style={{
            marginTop: editMode ? "20px" : null,
            maxHeight: editMode ? "40px" : null,
            pointerEvents: editMode ? "all" : "none"
        }}>
            <CustomButtonSmall theme="dark" accent="rgb(102, 100, 229)" value={translate[language].Spremi} style={{ marginRight: "10px" }} onClick={saveData} />
            <CustomButtonSmall theme="dark" accent="#fd4444" value={translate[language].Otkaži} onClick={() => {
                setEditMode(false)
            }} />
        </div>
    </div>
};



const Documents = props => {
    const [activeTab, setActiveTab] = React.useState();
    const printRef = React.useRef(() => null);

    React.useEffect(() => {
        if (props.data.isRecurringWithGuarantee && props.data.shouldPrintGuarantee) return setActiveTab(0);
        if (props.data.WarningSent) return setActiveTab(1);
        if (props.data.isRecurringPayment && props.data.isRecurringPaymentJMBG) return setActiveTab(2);
    }, []);

    React.useEffect(() => {
        printRef.current = () => null;
    }, [activeTab]);

    return <div className="ccmanager__singleLead__documents">
        <div className="ccmanager__singleLead__buttons">
            {(props.data.isRecurringWithGuarantee && props.data.shouldPrintGuarantee) && <CustomButtonSmall accent="transparent" className={activeTab === 0 ? "active" : null} value="Garancija" onClick={(e) => setActiveTab(0)} />}
            {props.data.WarningSent && <CustomButtonSmall accent="transparent" className={activeTab === 1 ? "active" : null} value="Opomena" onClick={(e) => setActiveTab(1)} />}
            {(props.data.isRecurringPayment && props.data.isRecurringPaymentJMBG) && <CustomButtonSmall accent="transparent" className={activeTab === 2 ? "active" : null} value="Odgođeno plaćanje" onClick={(e) => setActiveTab(2)} />}
            {(props.data.isRecurringPayment && props.data.isRecurringPaymentJMBG) && <CustomButtonSmall accent="transparent" className={activeTab === 3 ? "active" : null} value="Uplatnica odgođenog plaćanja" onClick={(e) => setActiveTab(3)} />}

            <div className="ccmanager__singleLead__buttons__exitBtn" style={{ zIndex: 99 }}>
                <CustomButtonSmall accent="#2b2c3e" value="Printaj..." style={{ border: "1px solid rgb(233, 169, 86)" }} onClick={() => {
                    printRef.current();
                }} />
            </div>
        </div>

        <DocumentFrame printCB={p => printRef.current = p} type={activeTab} ID={props.data.ID} key={`lead-documentID-${activeTab}`} />
    </div>
};

const DocumentFrame = (props2) => {
    const [spinner, setSpinner] = React.useState(true);

    const iframeRef = React.useRef();

    React.useEffect(() => {
        if (!iframeRef.current) return;

        let actualType = null;
        switch (props2.type) {
            case 0:
                actualType = "notice";
                break;
            case 1:
                actualType = "warning";
                break;
            case 2:
                actualType = "recurringPayment"
                break;
            case 3:
                actualType = "recurringPaymentSlip"
                break;
            default:
                break;
        };

        if (!actualType) {
            iframeRef.current.contentWindow.document.open();
            iframeRef.current.contentWindow.document.write("<p style='color: white;'>Tip dokumenta nije validan!</p>")
            iframeRef.current.contentWindow.document.close();
            return setSpinner(false);
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/renderer/html/${actualType}/${props2.ID}`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                iframeRef.current.contentWindow.document.open();
                iframeRef.current.contentWindow.document.write(`
                    <style>
                        @media not print {
                            * {
                                color: white;
                                font- family: "Roboto";
                        border-color: white !important;
                            }
                        .signature {
                            display: none !important;
                            }
                        }
                    </style>
                    ${res.data.data}
                    `);
                iframeRef.current.contentWindow.document.close();
                if (props2.printCB) props2.printCB(() => iframeRef.current.contentWindow.print());
            } else {
                iframeRef.current.contentWindow.document.open();
                iframeRef.current.contentWindow.document.write("<p style='color: white;'>Greška prilikom generiranja dokumenta!</p>")
                iframeRef.current.contentWindow.document.close();
            };
        }).catch(() => {
            iframeRef.current.contentWindow.document.open();
            iframeRef.current.contentWindow.document.write("<p style='color: white;'>Server ne reagira!</p>")
            iframeRef.current.contentWindow.document.close();
        }).finally(() => {
            setSpinner(false);
        });
    }, [iframeRef, props2.ID]);

    return <div className="ccmanager__singleLead__documents__frame">
        <div className="ccmanager__singleLead__documents__frame__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner color="white" style={{ width: "32px", height: "32px" }} />
        </div>

        <iframe ref={iframeRef} style={{
            opacity: spinner ? 0 : 1,
            pointerEvents: spinner ? "none" : "all"
        }}></iframe>
    </div>
};

const ImportedDocuments = (props) => {
    const [data, setData] = React.useState({ status: "ok", data: [] });

    const chatUsersSelector = useSelector(state => state?.chatUsers ?? []);
    const returnedDocumentTypesSelector = useSelector(state => state?.statusCodes?.returnedDocumentTypes ?? {});
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const curDispatch = useDispatch();

    const getCreatedByUser = (UserID) => {
        let firstName = null;
        let lastName = null;
        let image = null;

        let foundUser = chatUsersSelector.find(c => c.ID === UserID);
        if (foundUser) {
            [firstName, lastName, image] = [foundUser.FirstName, foundUser.LastName, foundUser.Image];
        };

        return <div className="ccmanager__singleLead__importedDocuments__createdUser">
            <span>Kreirao:</span>
            <img src={image ? image : "/images/undefined.png"} onError={e => e.currentTarget.src = "/images/undefined.png"} />
            <span>{firstName ?? "?"} {lastName ?? "?"}</span>
        </div>
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/returnedDocuments/getAllreturnedDocuments`,
            data: {
                DealID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        })
    };

    const removeDocument = (id, text) => {
        curDispatch(modalActions.addModal(<YesNoModal
            heading="Da li ste sigurni?"
            text={[`Ovaj dokument će biti trajno obrisan!`, <p style={{ color: "rgb(36, 31, 238)" }}>{text}</p>]}
            onYes={() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/returnedDocuments/removeReturnedDocument`,
                    data: {
                        ID: id
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null).finally(() => getData());
            }}
        />));
    };

    React.useEffect(() => {
        getData();

        if (props.onOpen) props.onOpen?.();

        return () => {
            if (props.onClose) props.onClose?.();
        };
    }, []);

    return <div className="ccmanager__singleLead__importedDocuments">
        <CustomButtonSmall
            value="Dodaj dokument"
            style={{
                border: "1px solid white",
                width: "100%",
                marginBottom: "10px"
            }}
            onClick={e => animateBox(e, <ImportDocument onChange={() => getData()} ID={props.ID} />)}
        />
        <FilteredCustomTable
            theme="dark"
            accent="#8B88FE"
            headers={["Naziv", "Datum"]}
            data={(() => {
                let out = [];

                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Došlo je do greške" }]]

                for (let item of data.data) {
                    out.push([
                        { keyID: item.ID, type: "text", text: `[${item.ID}] ${returnedDocumentTypesSelector[item.DocumentType]}` },
                        { keyID: item.ID, type: "text", text: (new Date(item.createdAt)).toLocaleString() },
                        {
                            keyID: item.ID, type: "groupNewline", group: [
                                { keyID: item.ID, type: "custom", data: getCreatedByUser(item.UploadedBy) }
                            ]
                        },
                        (item.Description ? {
                            keyID: item.ID, type: "groupNewline", group: [
                                {
                                    keyID: item.ID, type: "text", text: <>
                                        <span style={{ marginRight: "10px" }}>Opis:</span>
                                        <span style={{ color: "#d0cbcb" }}>{item.Description}</span>
                                    </>
                                }
                            ]
                        } : null),
                        {
                            keyID: item.ID, type: "groupNewline", group: [
                                {
                                    keyID: item.ID, type: "button", text: "Preuzmi", onClick: () => {
                                        window.open(`${backendModule.backendURL}/returnedDocuments/getItem/${item.ID}`, "_blank");
                                    }
                                },
                                (userSelector?.Flags?.isAdmin ? { keyID: item.ID, type: "button", text: "Obriši", style: { borderColor: "rgb(233, 86, 86)" }, onClick: () => removeDocument(item.ID, `[${item.ID}] ${returnedDocumentTypesSelector[item.DocumentType]}`) } : null)
                            ].filter(t => t)
                        }
                    ].filter(t => t))
                };

                if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nema podataka za prikaz" }]);
                return out;
            })()}
        />
    </div>
};

const CreateReclamation = (props) => {
    const [infoP, setInfoP] = React.useState();
    const [selectedFile, setSelectedFile] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const clientAccountRef = React.useRef();
    const clientBankRef = React.useRef();
    const evidenceRef = React.useRef();
    const commentRef = React.useRef();
    const returnValue = React.useRef();




    React.useEffect(() => {
        if (!props.deal) return;
    }, [])

    const sendData = () => {
        setSpinner(true);
        let data = {
            Deal: props.deal.ID,
            Image: selectedFile,
            Comment: commentRef.current?.value,
            CustomerBankAccount: clientAccountRef.current?.value,
            CustomerBankName: clientBankRef.current?.value,
            ReturnValue: returnValue.current?.value
        }
        let fd = new FormData();
        for (let key of Object.keys(data)) fd.append(key, data[key]);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reclamations/addReclamation`,
            data: fd,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setInfoP("Reklamacija je uspjesno kreirana")
            } else {
                setInfoP("reklamacija nije uspjesno kreirana molimo pokusajte kasnije ili kontaktirajte administraciju")
            }
        }).catch(() => {
            setInfoP("reklamacija nije uspjesno kreirana molimo pokusajte kasnije ili kontaktirajte administraciju")
        }).finally(() => { setSpinner(false) })
    }


    return <div className="ccmanager__singleLead__createReclamation">

        <p>Ovo ce kreirati reklamaciju kako bi klijentu bio vracen novac</p>
        <p>Ukoliko se radi o kapima za Srbiju ili Crnu Goru bit ce potrebno zavrsiti obradu leada kako bi se reklamacija smatrala dovrsenom</p>
        <p>Unesite broj bankovnog racuna klijenta</p>
        <input ref={clientAccountRef} />
        <p>Unesite vrijednost povrata</p>
        <input ref={returnValue} type="number" defaultValue={props.deal.Amount} />
        {props.deal.Country === "HR" && <>
            <p>Unesite naziv banke klijenta</p>
            <input ref={clientBankRef} />
        </>}
        <p>Dokaz o fiskalnom racunu</p>
        <input ref={evidenceRef} type="file" accept="image/jpeg, image/png, image/jpg" onChange={e => {
            if (e.target.files.length === 0) return;
            setSelectedFile(e.target.files[0]);
        }} />
        <p>Razlog reklamacije</p>
        <textarea ref={commentRef}></textarea>

        {spinner ? <Spinner /> : <CustomButtonSmall theme="dark" value="Kreiraj reklamaciju" style={{ marginTop: "20px", border: "1px solid #6664e5" }} accent="#2b2c3e" onClick={sendData} />}
        <p>{infoP}</p>
    </div>
};

const SendEmailToExpress = (props) => {
    const [reason, setReason] = React.useState();
    const commentRef = React.useRef();
    const [infoP, setInfoP] = React.useState();
    const [spinner, setSpinner] = React.useState();
    const listOfReasons = [
        "Zamjena adrese",
        "Paket ostavljen na paketomatu, klijent želi isporuku na adresu",
        "Klijent želi isporuku, nije pozivan od strane kurira",
        "Kurir ne želi isporučiti paket na adresu",
        "Kurir želi naplatiti dostavu",
        "Klijent želi isporuku sutra",
        "Klijent nije pozivan od strane kurira"
    ]




    React.useEffect(() => {
        if (!props.deal) return;
    }, [])

    const sendData = () => {
        setSpinner(true);
        let data = {
            ID: props.deal.ID,
            Comment: commentRef.current?.value,
            Reason: reason
        }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/SendEmailToExpressShippmetAgent`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setInfoP("E-mail je uspjesno poslan")
            } else {
                setInfoP("Doslo je do greske prilikom slanja e-maila")
            }
        }).catch(() => {
            setInfoP("Doslo je do greske prilikom slanja e-maila")
        }).finally(() => { setSpinner(false) })
    }


    return <div className="ccmanager__singleLead__createReclamation">

        <p>Ovo ce poslati e-mail notifikaciju posti kako bi se eventualna greska ispravila</p>

        <p>Izaberite razlog slanja e-maila</p>
        <Dropdown data={listOfReasons.map(key => {
            return { name: key, value: key }
        })} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => setReason(e?.value)} />
        <p>Unesite adresu ako je pitanu promjena iste (ulica i broj)</p>
        <input ref={commentRef} />

        {spinner ? <Spinner /> : <CustomButtonSmall theme="dark" value="Posalji" style={{ marginTop: "20px", border: "1px solid #6664e5" }} accent="#2b2c3e" onClick={sendData} />}
        <p>{infoP}</p>
    </div>
};


export default SingleLead