import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";

import Spinner from "../Spinner";
import CustomButtonSmall from "../ButtonSmall";
import CustomCheckbox from "../CustomCheckbox";

const ExportCSV = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [prepData, setPrepData] = React.useState();
    const [selectedColumns, setSelectedColumns] = React.useState({});

    const exportData = () => {
        let finalColumns = Object.keys(selectedColumns).map(key => {
            if (selectedColumns[key]) return key;
            return null;
        }).filter(t => t);
        if (finalColumns.length === 0) return;

        setSpinner(true);
        let url = `${backendModule.backendURL}/dataExport/getLeadDealExportData`;
        if (props.reject === true && props.type === "lead") {
            url = `${backendModule.backendURL}/dataExport/getLeadExportDataReject`
        }
        axios({
            method: "POST",
            url: url,
            data: {
                Columns: finalColumns,
                Type: props.type,
                filters: props.filters
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "error") return;
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data.data));
            element.setAttribute('download', `ScaleCRM-export-${(new Date()).toLocaleString()}.csv`.replace(" ", "_").replace(",", "-"));

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }).catch(() => null).finally(() => {
            setSpinner(false);
        })
    };

    const getPrep = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/dataExport/getLeadDealExportPrep`,
            data: {
                Type: props.type,
                filters: props.filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                res.data.data.columns = res.data.data.columns.map(c => {
                    if (c === "City") return ["City", "_CityName"];
                    if (c === "BirthYear") return ["BirthYear", "Age"];
                    return c;
                }).flat(3);

                let out = {};
                for (let item of res.data.data.columns) {
                    out[item] = false;
                };
                setSelectedColumns(out);
            };
            setPrepData(res.data);
        }).catch(() => {
            return setPrepData(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        getPrep();
    }, []);

    return <div className="customComponents__kanban__csv">
        <div className="customComponents__kanban__csv__wrap">
            <div className="customComponents__kanban__csv__wrap__close">
                <CustomButtonSmall onClick={exportData} theme="dark" accent="#2E2E41" value="Exportaj" style={{
                    border: "1px solid #999FAE",
                    height: "30px",
                    display: prepData?.data?.count > 0 ? null : "none"
                }} />
                <img src="/images/close.png" onClick={props.onClose} />
            </div>
            {(prepData && !spinner) ? (prepData.status === "ok" ? <>
                <p className="customComponents__kanban__csv__wrap__count">Za exportati: ~{prepData.data.count} elemenata</p>
                <p className="customComponents__kanban__csv__wrap__title">Kolone koje se exportaju ({Object.keys(selectedColumns).reduce((acc, val) => {
                    if (selectedColumns[val]) return acc + 1;
                    return acc;
                }, 0)} / {Object.keys(selectedColumns).length}):</p>
                <div className="customComponents__kanban__csv__wrap__columns">
                    {prepData.data.columns.map(c => {
                        return <CustomCheckbox placeholder={c} defaultValue={selectedColumns[c] ?? false} theme="dark" onChange={e => {
                            setSelectedColumns(sc => {
                                return {
                                    ...sc,
                                    [c]: !!e
                                }
                            });
                        }} />
                    })}
                </div>
            </> : <p>Došlo je do greške prilikom dohvatanja podataka!</p>) : <div className="customComponents__kanban__csv__wrap__spinner">
                <Spinner color="white" />
            </div>}
        </div>
    </div>
};


export default ExportCSV;