import React from "react";
import "../index.scss";
import "../call.scss";
import "./index.scss";

import { useDispatch, useSelector, Provider } from "react-redux";

import * as backendModule from '../../../modules/backendModule'
import axios from "axios";

import Spinner from '../../../components/customComponents/Spinner'
import Dropdown from '../../../components/customComponents/Dropdown'
import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import Skripta from '../../../Files/script.json'
import ScriptCS from '../../../Files/scriptCS.json';
import ingridients from "../../../Files/ingridiens.json";
import translate from '../translate.json'
import { updateLanguage } from "../../../actions/languageAction";
import offers from "../../../offers.json";


const AgentHelper = (props) => {
    const [timer, setTimer] = React.useState([])
    let language = useSelector(state => state.language)
    let languageDispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState("genericData");

    const [selectedArticle, setSelectedArticle] = React.useState()
    const [allArticles, setAllArticles] = React.useState([]);
    const [allCompounds, setAllCompounds] = React.useState(0)
    const [extraPack, setExtraPack] = React.useState()
    const [extraCompounds, setExtraCompounds] = React.useState()
    const script = Skripta;
    const scriptCS = ScriptCS;
    const [selectedProductHelper, setSelectedProductHelper] = React.useState();
    const [selectedProductNameHelper, setSelectedProductNameHelper] = React.useState();

    //articles for offer
    const [articlesForOffer, setArticlesForOffer] = React.useState([]);
    const [gratisArticles, setGratisArticles] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [selectedArticleForOffer, setSelectedArticleForOffer] = React.useState();
    const [decription, setDescription] = React.useState();
    const [genericData, setGenericData] = React.useState();
    const [CampaignDescriptions, setCampaignDescriptions] = React.useState();
    const [stAngle, setSTAngle] = React.useState();
    const [selectedAdvertiser, setSelectedAdvertiser] = React.useState();
    const [agentScripts, setAgentScripts] = React.useState();


    const getScripts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/agentScripts/getAgentScriptForLead`,
            data: {
                Offer: selectedProductHelper,
                Angle: stAngle,
                SubType: props?.subType?.includes("cross") ? "Cross" : "Standard"
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setAgentScripts(res.data.data)
            };
        }).catch(() => { });
    }
    React.useEffect(() => {
        if (!selectedProductHelper) return;
        getScripts();
    }, [selectedProductHelper, stAngle, props.subType])

    const getArticles = async () => {
        let tmpSelectedCountry = props.countries.find(co => co.CountryCode === props.country);
        setSelectedCountry(tmpSelectedCountry);

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/articles/getAllArticlesAgent`,
            data: {
                CountryID: tmpSelectedCountry.ID,
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllArticles(res.data.data);
                console.log(res.data.data)
                let leadAdditionalData;
                let finalArticles = [];
                try {
                    leadAdditionalData = JSON.parse(props.additionalData);
                } catch {
                    leadAdditionalData = {}
                }
                setSelectedAdvertiser(leadAdditionalData.AdvertiserID);
                setGenericData(leadAdditionalData.GenericData)
                setCampaignDescriptions(leadAdditionalData.CampaignDescriptors)
                setSTAngle(leadAdditionalData.STAngle);
                if (props?.subType?.includes("cross")) {
                    for (let art of res.data.data) {
                        if (!art.OfferIDs) art.OfferIDs = [];
                        if (art.ArticleName.includes("(CS)")) {
                            if (art.OfferIDs.includes(leadAdditionalData?.OfferID)) {
                                finalArticles.push(art)
                            }

                        }
                    }
                    setSelectedProductHelper(leadAdditionalData?.OfferID);
                } else if (leadAdditionalData?.OfferID) {
                    finalArticles = [];
                    setSelectedProductHelper(leadAdditionalData?.OfferID);
                    for (let art of res.data.data) {

                        if (art.ArticleName === "Gratis") {
                            setGratisArticles(art)
                        }
                        if (!art.OfferIDs) art.OfferIDs = [];
                        if (!art.ArticleName.includes("(CS)")) {
                            if (art.OfferIDs.includes(leadAdditionalData?.OfferID)) {
                                finalArticles.push(art)
                            }
                        }
                    }
                } else if (props.subType === "dodatno-oboljenje") {
                    for (let art of res.data.data) {
                        if (art.ArticleName === "Gratis") {
                            setGratisArticles(art)
                        }
                        if (art.ArticleName.includes(props.name.split(" ")[0])) {
                            finalArticles.push(art)
                        } else if (props.subType === "dodatno-oboljenje") {
                            if (props.name.toLowerCase().includes("prostatitis") && art.ArticleName.includes("Prostate")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("hipertenzija") && art.ArticleName.includes("Hyper")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("dijabetes") && art.ArticleName.includes("Dia active")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("anksiozni") && art.ArticleName.includes("Mind vital")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("osteoporoza") && art.ArticleName.includes("Flexi")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("hiperlipidemija") && art.ArticleName.includes("Hepa")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("anemija") && art.ArticleName.includes("Blood spark")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("hipotireoza") && art.ArticleName.includes("Thyro")) {
                                finalArticles.push(art)
                            } else if ((props.name.toLowerCase().includes("demencija") || props.name.toLowerCase().includes("alzheimerova")) && art.ArticleName.includes("Ease Mind")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("demencija") && art.ArticleName.includes("Dream")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("multipla skleroza") && art.ArticleName.includes("Joint protect")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("multipla skleroza") && art.ArticleName.includes("Joint protect")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("policistični jajnici") && art.ArticleName.includes("Vagi")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("katarakta") && art.ArticleName.includes("Eyeon")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("anoreksija") && art.ArticleName.includes("Mass")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("bolesti štitne žlijezde") && art.ArticleName.includes("Thyro control")) {
                                finalArticles.push(art)
                            } else if (props.name.toLowerCase().includes("hemoroidi") && art.ArticleName.includes("Natura active")) {
                                finalArticles.push(art)
                            }

                        }
                    }
                }
                setArticlesForOffer(finalArticles);
            };
        }).catch((e) => { console.log(e) });
    };
    React.useEffect(() => {
        if (!props.country || !props.countries) return;
        getArticles()
    }, [props.country, props.countries])
    React.useEffect(() => {
        if (!selectedArticle) return

        let tmpTotal = 0;
        let tmpPack = ''
        try {
            JSON.parse(selectedArticle?.ArticleCompounds)?.map((item, index) => {
                tmpTotal += item.quantity
                if (index === 0) {
                    tmpPack += item.quantity
                } else tmpPack += ' + ' + item.quantity

            })
            setAllCompounds({ total: tmpTotal, pack: tmpPack })
        } catch (e) {
        }

    }, [selectedArticle])
    React.useEffect(() => {
        if (!extraPack) return
        let tmpTotal = 0;
        let tmpPack = ''
        JSON.parse(extraPack?.ArticleCompounds)?.map((item, index) => {
            tmpTotal += item.quantity
            if (index === 0) {
                tmpPack += item.quantity
            } else tmpPack += ' + ' + item.quantity

        })
        setExtraCompounds({ total: tmpTotal, pack: tmpPack })
    }, [extraPack])


    const updateArticlesForOffer = () => {
        let finalArticles = [];
        for (let art of allArticles) {

            if (!art.OfferIDs) art.OfferIDs = [];
            if (art.OfferIDs.includes(selectedProductHelper)) {
                if (!art.ArticleName.includes("(CS)")) {
                    finalArticles.push(art)
                }
            }
        }

        setArticlesForOffer(finalArticles);


    }
    React.useEffect(() => {
        if (!allArticles) return;
        if (props?.subType?.includes("cross")) return;
        updateArticlesForOffer();
    }, [selectedProductHelper])



    React.useEffect(() => {
        let addData;
        try {
            addData = JSON.parse(props.additionalData);
        } catch {
            addData = {};
        }

        if (addData.STAdID && addData.STIntegrationType) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/stAds/getAdForLead`,
                data: {
                    AdID: addData.STAdID,
                    Integration: addData.STIntegrationType
                },
                ...backendModule.axiosConfig
            }).then(res => {
                setDescription(res?.data?.data?.Description || ''); // Store the fetched description in state
            }).catch(e => {
                console.error(e);
            });
        }
    }, [props.additionalData]);


    return <div className="component__agentHelper" style={props.style ?? null}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src="/images/si.png" style={{ cursor: 'pointer' }} onClick={() => { languageDispatch(updateLanguage('SI')) }} />
            <img src="/images/ba.png" style={{ cursor: 'pointer' }} onClick={() => { languageDispatch(updateLanguage('BA')) }} />
            <img src="/images/RU.png" style={{ cursor: 'pointer', width: "36px", height: "24px" }} onClick={() => { languageDispatch(updateLanguage('RO')) }} />
        </div>
        <p style={{
            fontFamily: "Roboto",
            margin: "10px"
        }}>Dostavu ovog paketa ce vrsiti: {(() => {
            if (props.country === "BA") {
                return "X Express"
            } if (props.country === "HR") {
                return "DPD"
            } if (props.country === "ME") {
                return "GO Express"
            } if (props.country === "RS") {
                if (selectedAdvertiser === "0b8b3436-a520-4903-ac74-6e91134c3d98") {
                    return "AKS Express"
                } else {
                    return "Daily Express"
                }
            }
        })()}</p>
        <p style={{
            fontFamily: "Roboto",
            margin: "10px"
        }}>{decription}</p>
        <div className="component__agentHelper__tabs">
            <p onClick={() => {
                setActiveTab("product")
            }} style={{ backgroundColor: activeTab === "product" ? "rgb(102, 100, 229)" : null }}>Proizvodi</p>
            <p onClick={() => {
                setActiveTab("script")
            }} style={{ backgroundColor: activeTab === "script" ? "rgb(102, 100, 229)" : null }}>Skripta</p>
            <p onClick={() => {
                setActiveTab("ingridiens")
            }} style={{ backgroundColor: activeTab === "ingridiens" ? "rgb(102, 100, 229)" : null }}>Sastojci</p>
            {(genericData || CampaignDescriptions) && <p onClick={() => {
                setActiveTab("genericData")
            }} style={{ backgroundColor: activeTab === "genericData" ? "rgb(102, 100, 229)" : null }}>Info o korisniku</p>}
        </div>
        <div>
            <Dropdown accent={"dark"} theme="dark" selected={offers.findIndex(innerArray => innerArray[1] === selectedProductHelper)} data={offers.map((item, index) => {
                return {
                    name: item[0],
                    value: item[1]
                }
            })} onChange={val => {
                setSelectedProductHelper(val.value)
            }} />
        </div>
        <div style={{
            maxHeight: activeTab === "product" ? "calc(100% - 150px)" : "0",
            overflow: activeTab === "product" ? "auto" : "hidden",
        }} className="component__agentHelper__products">
            <div>
                <p>Naziv</p>
                <p>Cijena</p>
                <p>Cijena po pakovanju</p>
                <p></p>
            </div>
            {(articlesForOffer && articlesForOffer.length > 0) ? <>
                {articlesForOffer.map(article => {
                    return <div>
                        <p style={{
                            cursor: "pointer",
                            backgroundColor: selectedArticleForOffer === article.ID ? "#2e6244" : "#272737"
                        }}>{article.ArticleName}</p>
                        <p style={{
                            cursor: "pointer",
                            backgroundColor: selectedArticleForOffer === article.ID ? "#2e6244" : "#272737"
                        }}>{article.ArticlePrice} {selectedCountry && selectedCountry.CountryCurrency}</p>
                        <p style={{
                            cursor: "pointer",
                            backgroundColor: selectedArticleForOffer === article.ID ? "#2e6244" : "#272737"
                        }}>{(() => {
                            let compounds;
                            if (article.ArticleCompounds === null) return `${article.ArticlePrice} ${selectedCountry && selectedCountry.CountryCurrency}`
                            try {
                                compounds = JSON.parse(article.ArticleCompounds)
                            } catch {
                                compounds = [{ quantity: 1 }]
                            }
                            let finalNumber = 0;
                            for (let single of compounds) {
                                finalNumber += single.quantity;
                            }
                            return `${(Number(article.ArticlePrice) / finalNumber).toFixed(2)} ${selectedCountry && selectedCountry.CountryCurrency}`
                        })()}</p>
                        <p style={{
                            cursor: "pointer",
                            backgroundColor: selectedArticleForOffer === article.ID ? "green" : "#6664e5"
                        }} onClick={() => {
                            setSelectedArticleForOffer(article.ID)
                            let data = { name: `(${selectedCountry.CountryCode}) ${article.ArticleName} - ${article.ArticlePrice} ${selectedCountry.CountryCurrency}`, value: article.ID };
                            let tmpComp = 0;
                            if (article.ArticleCompounds !== null) {
                                let ArticleCompounds;
                                try {
                                    ArticleCompounds = JSON.parse(article.ArticleCompounds)
                                } catch {
                                    ArticleCompounds = { quantity: 0 }
                                }
                                for (let cmp of ArticleCompounds) {
                                    tmpComp += cmp.quantity;
                                }
                            }
                            if (props.subType !== "green-side" && tmpComp < 3 && gratisArticles) {
                                props.setSelectedArticles([
                                    {
                                        id: props.generateArticleID(),
                                        data: {
                                            id: data.value,
                                            text: data.name,
                                            quantity: 1
                                        }
                                    },
                                    {
                                        id: props.generateArticleID(),
                                        data: {
                                            id: gratisArticles.ID,
                                            text: `(${selectedCountry.CountryCode}) ${gratisArticles.ArticleName} - ${gratisArticles.ArticlePrice} ${selectedCountry.CountryCurrency}`,
                                            quantity: 1
                                        }
                                    }
                                ])
                            } else {
                                props.setSelectedArticles([
                                    {
                                        id: props.generateArticleID(),
                                        data: {
                                            id: data.value,
                                            text: data.name,
                                            quantity: 1
                                        }
                                    }
                                ])
                            }
                        }}>Odaberi proizvod</p>
                    </div>
                })}
            </> : <p style={{
                width: "100%",
                height: "100%",
                background: "#272737",
                margin: "0"
            }} >Lead ne sadrzi informacije o proizvodu</p>}
        </div>
        <div style={{
            maxHeight: activeTab === "script" ? "calc(100% - 150px)" : "0",
            overflow: activeTab === "script" ? "auto" : "hidden",
        }} className="component__agentHelper__scripts">
            {(() => {
                if (props.subType === "crossSellNH" || props.subType === "crossSellBIO" || props.subType === "crossSellNHP") {
                    return <>{scriptCS[selectedProductHelper] || agentScripts ? <ProductDetails descriptions={CampaignDescriptions} subType={"cross"} agentScripts={agentScripts} data={scriptCS[selectedProductHelper]} /> : <></>}</>
                } else {
                    return <>{script[selectedProductHelper] || agentScripts ? <ProductDetails descriptions={CampaignDescriptions} agentScripts={agentScripts} subType={props.subType} data={script[selectedProductHelper]} /> : <></>}</>
                }
            })()}


        </div>
        <div style={{
            maxHeight: activeTab === "ingridiens" ? "calc(100% - 150px)" : "0",
            overflow: activeTab === "ingridiens" ? "auto" : "hidden",
        }} className="component__agentHelper__scripts">
            {(() => {
                let tmp = Object.keys(ingridients).find(elem => ingridients[elem].offers.includes(selectedProductHelper));
                return <ProductIngridients data={ingridients[tmp]} />
            })()}


        </div>

        {(genericData || CampaignDescriptions) && <div style={{
            maxHeight: activeTab === "genericData" ? "calc(100% - 150px)" : "0",
            overflow: activeTab === "genericData" ? "auto" : "hidden",
        }} className="component__agentHelper__scripts">
            <GenericData data={genericData} descriptions={CampaignDescriptions} angle={stAngle} />


        </div>}





    </div>
}

function ProductDetails(props) {
    const [script, setScript] = React.useState();
    React.useEffect(() => {
        setScript(props.agentScripts)
    }, [props.agentScripts])
    const renderContent = (item) => {
        const { tag, text, children } = item;

        switch (tag) {
            case 'div':
                return <div>{children && children.map(child => renderContent(child))}</div>;
            case 'p':
                return <p>{text} {children && children.map(child => (
                    renderContent(child)
                ))}</p>;
            case 'h1':
            case 'h2':
                return <div><h1>{text}</h1> {children && children.map(child => (
                    renderContent(child)
                ))}</div>;
            case 'ul':
            case 'ol':
                return (
                    <ul>
                        {children && children.map(child => (
                            <li>{child.text}</li>
                        ))}
                    </ul>
                );
            case 'li':
                return <li>{text}</li>;
            default:
                return null;
        }
    };
    return (
        <div className="component__agentHelper__scripts__content">
            {props.subType !== "cross" && <p>
                Pozivam vas jer ste dobili jednu kutiju besplatno kapsula protiv _________ . Ja vam ovim putem čestitam! Pa bih vas zamolila da mi kažete
            </p>}
            {script && (() => {
                let angle;
                let offer;
                let angleScript;
                let offerScript;
                if (script) {
                    for (let scr of script) {
                        if (scr.Type === "Offer") {
                            offer = scr;
                            offerScript = scr.Script;
                        } else if (scr.Type === "Angle") {
                            angle = scr;
                            angleScript = scr.Script;
                        }
                    }
                }
                // case "CGC": return "Kategorija kreative"
                //             case "CGCDescription": return "Opis kategorije"
                //             case "Comments": return "Komentari"
                //             case "ClientPromises": return "Obecanja klijentu"
                //             case "KeyInformation": return "Kljucne informacije"
                if (props.descriptions && offerScript) {
                    if (props.descriptions.ClientPromises) {
                        offerScript = offerScript.replace("&lt;%ClientPromises%&gt;", props.descriptions.ClientPromises.join(', '))
                    }
                    // if (props.descriptions.CGC) {
                    //     offerScript = offerScript.replace("&lt;%Doctor%&gt;", props.descriptions.CGC)
                    // }
                    if (props.descriptions.CGCDescription) {
                        offerScript = offerScript.replace("&lt;%Doctor%&gt;", props.descriptions.CGCDescription)
                    }
                    if (props.descriptions.Comments) {
                        offerScript = offerScript.replace("&lt;%Comments%&gt;", props.descriptions.Comments)
                    }
                    if (props.descriptions.KeyInformation) {
                        offerScript = offerScript.replace("&lt;%KeyInformation%&gt;", props.descriptions.KeyInformation)
                    }
                }
                if (props.descriptions && angleScript) {
                    if (props.descriptions.ClientPromises) {
                        angleScript = angleScript.replace("&lt;%ClientPromises%&gt;", props.descriptions.ClientPromises.join(', '))
                    }
                    // if (props.descriptions.CGC) {
                    //     offerScript = angleScript.replace("&lt;%Doctor%&gt;", props.descriptions.CGC)
                    // }
                    if (props.descriptions.CGCDescription) {
                        angleScript = angleScript.replace("&lt;%Doctor%&gt;", props.descriptions.CGCDescription)
                    }
                    if (props.descriptions.Comments) {
                        angleScript = angleScript.replace("&lt;%Comments%&gt;", props.descriptions.Comments)
                    }
                    if (props.descriptions.KeyInformation) {
                        angleScript = angleScript.replace("&lt;%KeyInformation%&gt;", props.descriptions.KeyInformation)
                    }
                }

                if (angle) {
                    return <div dangerouslySetInnerHTML={{ __html: angleScript }} />
                } else if (offer) {
                    return <div dangerouslySetInnerHTML={{ __html: offerScript }} />
                } else if (props.data) {
                    return props.data.content.map(item => renderContent(item))
                } else {
                    return <p>Sistem nije pronasao skriptu za ovaj offer</p>
                }
            })()}
        </div>
    )
}

function ProductIngridients(props) {

    return (
        <div className="component__agentHelper__scripts__content">
            {props.data?.title && props.data?.columns && <>
                <p>{props.data.title}</p>
                <div className="component__agentHelper__scripts__content__table">
                    {props.data.columns.map(column => {
                        if (column !== "Zaštićeni nazivi supstanci") {
                            return <div>
                                <p>{column}</p>
                                {props.data.data.map(single => {
                                    return <p>{single[column]}</p>
                                })}
                            </div>
                        }

                    })}
                </div>

            </>}
        </div>
    )
}

function GenericData(props) {

    return (
        <div className="component__agentHelper__scripts__content">
            {props.angle && <p>
                <strong>Ugao:</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <spam>{props.angle}</spam>
            </p>}

            {props.descriptions && Object.keys(props.descriptions).map(elem => {
                return <p>

                    <strong>{(() => {
                        switch (elem) {
                            case "CGC": return "Kategorija kreative"
                            case "CGCDescription": return "Opis kategorije"
                            case "Comments": return "Komentari"
                            case "ClientPromises": return "Obecanja klijentu"
                            case "KeyInformation": return "Kljucne informacije"
                            default: return "?"
                        }
                    })()}:</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <spam>{(() => {
                        if (typeof (props.descriptions[elem]) === "string" || !isNaN(props.descriptions[elem])) {
                            let tmp;
                            try {
                                tmp = decodeURI(props.descriptions[elem]);
                            } catch {
                                tmp = props.descriptions[elem];
                            }
                            return tmp;
                        } else if (Array.isArray(props.descriptions[elem])) {
                            let tmp = props.descriptions[elem].join(', ');
                            try {
                                tmp = decodeURI(props.descriptions[elem]);
                            } catch {
                                tmp = props.descriptions[elem];
                            }
                            return tmp;
                        }


                    })()}</spam>
                </p>
            })}

            {props.data && Object.keys(props.data).map(elem => {
                return <p>

                    <strong>{elem}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <spam>{(() => {
                        let tmp;
                        try {
                            tmp = decodeURI(props.data[elem]);
                        } catch {
                            tmp = props.data[elem];
                        }
                        return tmp;
                    })()}</spam>
                </p>
            })}

        </div >
    )
}


export default AgentHelper