import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import * as activitiyActions from "../../actions/activityAction";

import Sidebar from "./Sidebar";
import Content from "./Content";

const Chat = (props) => {
    const mainDispatch = useDispatch();
    const mainRef = React.useRef();

    const [activeChat, setActiveChat] = React.useState(null);
    const [sidebarOpen, setSidebarOpen] = React.useState(true);
    const [oldActivity, setOldActivity] = React.useState("");

    const activityState = useSelector(state => state.activity?.status);

    const onClose = () => {
        if (!mainRef?.current) {
            if (oldActivity) mainDispatch(activitiyActions.updateActivity(oldActivity));
            return props.onClose();
        };

        mainRef.current.animate([
            {opacity: getComputedStyle(mainRef.current).opacity},
            {opacity: 0}
        ], {duration: 300, iterations: 1, delay: 0, fill: "both", easing: "ease"});
        if (oldActivity) mainDispatch(activitiyActions.updateActivity(oldActivity));
        props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef?.current) return;

        mainRef.current.animate([
            {opacity: getComputedStyle(mainRef.current).opacity},
            {opacity: 1}
        ], {duration: 300, iterations: 1, delay: 100, fill: "both", easing: "ease"});
    }, [mainRef]);

    React.useEffect(() => {
        if (oldActivity) return;
        setOldActivity(activityState);

        mainDispatch(activitiyActions.updateActivity("Chat"));
    }, []);

    return <div className="component__chat" ref={mainRef} >
        <div className="component__chat__wrap">
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={d => setSidebarOpen(d)}
                changeActiveChat={c => setActiveChat(c)}
                activeChat={props.activeChat}
            />
            <Content
                sidebar={{sidebarOpen, setSidebarOpen}}
                onClose={onClose}
                activeChat={activeChat}
            />
        </div>
    </div>
};

export default Chat;