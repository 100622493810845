export const addRoom = (ID, ChatRoomName, Participants, Date, CreatedBy) => {
    return {
        type: "ROOMS_ADD",
        payload: {
            ID,
            ChatRoomName,
            Participants,
            Date,
            CreatedBy
        }
    };
};

export const removeRoom = id => {
    return {
        type: "ROOMS_REMOVE",
        payload: id
    }
};

export const bulkAddRooms = rooms => {
    return {
        type: "ROOMS_BULK_ADD",
        payload: rooms
    };
};

export const cleanRooms = () => {
    return {
        type: "ROOMS_CLEAN"
    };
};

export const addFrontRoom = (payload) => {
    return {
        type: "ROOMS_ADD_FRONT",
        payload
    };
};

export const updateFrontRoom = (users) => {
    return {
        type: "ROOMS_UPDATE_FRONT",
        payload: users
    };
};

export const updateChatRoomName = (users) => {
    return {
        type: "ROOMS_UPDATE_NAME",
        payload: users
    };
};

export const updateChatRoomUsers = (users) => {
    return {
        type: "ROOMS_UPDATE_USERS",
        payload: users
    };
};

export const incrementUnreadCount = (roomID) => {
    return {
        type: "ROOMS_INCREMENT_UNREAD",
        payload: roomID
    };
};

export const clearUnreadCount = (roomID) => {
    return {
        type: "ROOMS_CLEAR_UNREAD",
        payload: roomID
    };
};

export const moveRoomToTop = (roomID) => {
    return {
        type: "ROOMS_MOVE_TO_TOP",
        payload: roomID
    };
};