import React from "react";
import "./index.scss";

import { getStore } from "../../../modules/backendModule";
import { useDispatch, useSelector, Provider } from "react-redux";
import { updateLanguage } from "../../../actions/languageAction";

import translate from './translate.json'
import moment from "moment";

import {on as socketOn, off as socketOff, internal__createChatRoom} from "../../../modules/socketModule";

import CustomInput from "../../customComponents/CustomInput";
import Dropdown from "../../customComponents/Dropdown";
import Spinner from "../../customComponents/Spinner";

let onChatChangedHandlers = [];

export const addChangedHandlers = handler => onChatChangedHandlers.push(handler);
export const removeChangedHandlers = handler => onChatChangedHandlers = onChatChangedHandlers.filter(t => t !== handler);

const Sidebar = props => {
    const chatRoomsSelector = useSelector(state => state.chatRooms ?? []);
    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {})
    const curChatsSelector = useSelector(state => state.chats ?? []);
    let language = useSelector(state => state.language)
    let languageDispatch = useDispatch();

    const [addChatActive, setAddChatActive] = React.useState(false);
    const [activeChat, setActiveChat] = React.useState();
    const [search, setSearch] = React.useState("");

    const getUserActivityStatus = ID => {
        if (ID === null || ID === undefined) return "red";
        if (ID < 0) return "#6664E5";

        let cUser = chatUsersSelector.find(cu => cu.ID === ID);
        if (!cUser) return "red";

        if (!cUser.LastSeen) return "gray";
        if (Date.now() - cUser.LastSeen <= 30000) return "green";

        return "gray";
    };

    const generateChatRoomName = IDs => {
        let tmp = IDs.filter(ID => ID !== curUserSelector.ID).map(ID => {
            let found = chatUsersSelector.find(cu => cu.ID === ID);
            if (found) {
                if (IDs.length > 2) {
                    return found.FirstName;
                } else {
                    return `${found.FirstName} ${found.LastName}`;
                };
            }
            return "?";
        });
        if (tmp.length === 0) return <span style={{color: "gray"}}>[Nema sudionika]</span>;
        
        let final = tmp.join(", ");
        if (final.length >= 50) final.substring(0, 47);

        return final;
    };

    const getUserImage = IDs => {
        if (IDs.find(ID => ID < 0)) return "/images/robo.png";
        if (IDs.length > 2) return "/images/chat_userGroup.svg";

        let curID = IDs.find(ID => ID !== curUserSelector.ID);
        if (!curID) return "/images/undefined.png";

        let finalUser = chatUsersSelector.find(cu => cu.ID === curID);
        if (!finalUser) return "/images/undefined.png";

        return finalUser.Image ?? "#";
    };

    const getLastChatMessage = (chatRoom) => {
        let messages = curChatsSelector?.[chatRoom.ID];

        if (!messages) return "...";
        
        if (messages.length === 0) return "...";
        
        let lastMessage = messages[messages.length-1];
        let curUser = chatUsersSelector.find(cu => cu.ID === lastMessage.From);
        if (!curUser) return "[Obrisan korisnik]";
        
        if (lastMessage.Type === "text") {
            let final = String(lastMessage?.Content).substring(0, 50).replace(/^\<\#.*>/g, "");
            final = <span><span style={{color: curUser.ID === curUserSelector.ID ? "white" : "yellow"}}>{curUser?.FirstName ?? "?"}</span>:{final}{final.length === lastMessage?.Content?.length ? "" : " ..."}</span>;
            return final;
        } else {
            return <span><span style={{color: curUser.ID === curUserSelector.ID ? "white" : "yellow"}}>{curUser?.FirstName ?? "?"}</span>: {lastMessage.Content ? "[Privitak]" : <Spinner style={{width: "10px", height: "10px"}} color="white" />}</span>;
        };
    };

    const changeChat = ID => {
        if (ID === null || ID === undefined) return;
        if (activeChat === ID) return;

        onChatChangedHandlers.forEach(t => t());

        const messages = document.querySelectorAll(".component__chat__wrap__content__chat__message");
        if (messages.length > 0) {
            Promise.allSettled([...messages].map(elem => new Promise(r => {
                if (!elem) return r();
                elem.animate([
                    {left: getComputedStyle(elem).left},
                    {left: "120%"}
                ], {duration: 300, iterations: 1, fill: "both", easing: "ease", delay: 0}).onfinish = () => r();
            }))).then(() => {
                setActiveChat(ID);
            });
        } else {
            setActiveChat(ID);
        };
    };

    React.useEffect(() => {
        if (props.changeActiveChat) props.changeActiveChat(activeChat);
    }, [activeChat]);

    React.useEffect(() => {
        if (props.activeChat !== null && props.activeChat !== undefined) {
            if (String(activeChat) !== String(props.activeChat)) {
                changeChat(Number(props.activeChat));
            };
        };
    }, [props.activeChat]);

    React.useEffect(() => {
        let handler = data => {
            changeChat(data?.ID);
        };

        socketOn("createChatRoomSwitch", handler);

        return () => {
            try {
                socketOff("createChatRoomSwitch", handler);
            } catch {};
        };
    }, []);

    return <div className="component__chat__wrap__sidebar" style={{
        width: props.sidebarOpen ? null : 0
    }}>
        <CreateChat isActive={addChatActive} onClose={() => setAddChatActive(false)} />
        <div className="component__chat__wrap__sidebar__logo">
            <img src="/images/logoHeader.svg" />
            <img src="/images/chat_hamburgerClosed.svg" className="component__chat__wrap__sidebar__logo__hamburger" onClick={() => {
                props.setSidebarOpen(false);
            }} />
        </div>

        <div className="component__chat__wrap__sidebar__content">
            <div className="component__chat__wrap__sidebar__content__addBtn" onClick={() => setAddChatActive(t => !t)}>
                <img src="/images/closeImg.svg" />
                <span>Započni novi razgovor</span>
            </div>
            <div className="component__chat__wrap__sidebar__content__search">
                <img src="/images/search.svg" />
                <input type="text" placeholder="Traži.." value={search} onInput={e => setSearch(e.currentTarget.value)} />
            </div>
        </div>

        <div className="component__chat__wrap__sidebar__chats">
            {chatRoomsSelector.filter(cr => {
                if (!search) return true;
                if (
                    String(cr.ID).includes(search.toLowerCase()) ||
                    String(cr.ChatRoomName).toLowerCase().includes(search.toLowerCase())
                ) {
                    return true;
                } else {
                    for (let usr of cr.Participants) {
                        let user = chatUsersSelector.find(u => u.ID === usr);
                        if (user) {
                            if (`${user.FirstName} ${user.LastName}`.toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            };
                        };
                    };
                    return false;
                };
            }).map((cr) => {
                return <div className={`component__chat__wrap__sidebar__chats__chat ${activeChat === cr.ID ? "component__chat__wrap__sidebar__chats__chat--active" : ""}`} onClick={() => {
                    changeChat(cr.ID);
                    if(window.innerWidth <= 890) props.setSidebarOpen(false); 
                }}>
                    <div className="component__chat__wrap__sidebar__chats__chat__user"style={{gridRow: "1 / span 2", gridColumn: "1"}}>
                        <img onError={e => e.currentTarget.src = "/images/undefined.png"} src={getUserImage(cr.Participants)} />
                        <span style={{
                            backgroundColor: getUserActivityStatus(cr.Participants.find(ID => ID !== curUserSelector.ID))
                        }}></span>
                    </div>
                    <p className="component__chat__wrap__sidebar__chats__chat__chatName" style={{gridRow: "1", gridColumn: "2"}}>
                        {cr.Participants?.length > 0 && <span style={{
                            backgroundColor: getUserActivityStatus(cr.Participants.find(ID => ID !== curUserSelector.ID))
                        }}></span>}
                        {cr.Participants.length > 2 ? `[${cr.Participants.length-1}] ` : ""}{cr.ChatRoomName ? cr.ChatRoomName : generateChatRoomName(cr.Participants)}
                    </p>
                    <p className="component__chat__wrap__sidebar__chats__chat__lastMessage" style={{gridRow: "2", gridColumn: "2"}}>{getLastChatMessage(cr)}</p>
                    {cr.UnreadCount > 0 && <div style={{gridRow: "1 / span 2", gridColumn: "3"}} className="component__chat__wrap__sidebar__chats__chat__notification">
                        {cr.UnreadCount}
                    </div>}
                </div>
            })}
        </div>
    </div>
};

const CreateChat = props => {
    const [selectedUsers, setSelectedUsers] = React.useState([]);

    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);

    const resetDropdownRef = React.useRef();
    const chatNameRef = React.useRef();

    React.useEffect(() => {
        if (!props.isActive) setTimeout(() => {
            setSelectedUsers([]);
            chatNameRef.current.value = "";
        }, 200);
    }, [props.isActive]);

    const createChatRoom = () => {
        if (selectedUsers.length === 0) return props.onClose();
        internal__createChatRoom(chatNameRef.current.value, selectedUsers);
        return props.onClose();
    };

    return <div className={`component__chat__wrap__sidebar__createChat ${props.isActive ? "component__chat__wrap__sidebar__createChat--active": ""}`}>
        <h3>Kreiraj razgovor</h3>
        <CustomInput type="text" theme="dark" accent="#969698" placeholder="Naziv razgovora" ref={chatNameRef} />

        <p style={{marginTop: "24px", marginBottom: "20px"}}>Sudionici</p>
        <Dropdown inlinePlaceholder="Kliknite za odabir" theme="dark" accent="#969698" data={chatUsersSelector.filter(usr => !selectedUsers.includes(usr.ID)).map(usr => {
            return {
                name: <p style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <img src={usr.Image ?? "#"} onError={e => e.currentTarget.src="/images/undefined.png"} style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        marginRight: "10px"
                    }} />
                    <span>{`${usr.FirstName} ${usr.LastName}`}</span>
                </p>,
                value: usr.ID,
                search: `${usr.FirstName} ${usr.LastName}`
            };
        })} onReset={r => resetDropdownRef.current = r} onChange={e => {
            if (e?.name) {
                setSelectedUsers(usrs => [...usrs, e.value]);
                resetDropdownRef.current();
            };
        }} />
        <div className="component__chat__wrap__sidebar__createChat__users">
            {selectedUsers.map(usrID => {
                let curUser = chatUsersSelector.find(u => u.ID === usrID);

                return <div className="component__chat__wrap__sidebar__createChat__users__user">
                    <img src="/images/closeImg.svg" onClick={() => {
                        setSelectedUsers(u => u.filter(ut => ut !== usrID));
                    }} />
                    <img src={curUser?.Image ?? "#"} onError={e => e.currentTarget.src="/images/undefined.png"} />
                    <span>{curUser?.FirstName ?? "?"} {curUser?.LastName ?? "?"}</span>
                </div>
            })}
        </div>

        <div className="component__chat__wrap__sidebar__createChat__buttons">
            <div className="component__chat__wrap__sidebar__createChat__buttons__button" onClick={createChatRoom}>Kreiraj</div>
            <div className="component__chat__wrap__sidebar__createChat__buttons__button" onClick={props.onClose}>Zatvori</div>
        </div>
    </div>
};

export default Sidebar;