import React from "react";
import "./index.scss";

import * as backendModule from "../../../modules/backendModule";
import axios from "axios";
import { animateBox } from "../../../modules/componentAnimation";

import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import Spinner from "../../../components/customComponents/Spinner";
import CustomInput from "../../../components/customComponents/CustomInput";
import Progressbar from "../../../components/customComponents/Progressbar";

const Images = (props) => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/getAllImagesDashboard`,
            data: {
                pagination: paginationOffset.current,
                filters: [{
                    name: "Tag",
                    op: "eq",
                    value: "uploads"
                }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 100) {
                    paginationOffset.current += 100;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/getAllImagesDashboard`,
            data: {
                pagination: paginationOffset.current,
                filters: [{
                    name: "Tag",
                    op: "eq",
                    value: "uploads"
                }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 100) {
                    paginationOffset.current += 100;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch {};

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="route__adminPanel__images">
        <div className="route__adminPanel__images__buttons">
            {!props.selectMode && <CustomButtonSmall accent="#2b2c3e" value="Dodaj sliku" onClick={(e) => {
                animateBox(e, <AddImage onChange={() => getData()} />);
            }} style={{border: "1px solid #999FAE"}} />}
            <CustomButtonSmall accent="#2b2c3e" value="Osvježi" onClick={getData} style={{border: "1px solid #E264E59E"}} />
           {props.onClose && <CustomButtonSmall accent="#2b2c3e" value="X" onClick={() => props.onClose()} style={{border: "1px solid rgb(233, 86, 86)"}} />}
        </div>
        <div className="route__adminPanel__images__data">
            {data ? <>
                {data.status === "error" ? <p style={{gridColumn: "1 / -1", textAlign: "center"}}>
                    Došlo je do greške prilikom dohvatanja slika!
                </p> : <>
                    {data?.data?.map((img, imgIndex) => {
                        return <div style={{
                            backgroundImage: `url(${img.URL})`
                        }} key={`adminPanel-images-${imgIndex}`}>
                            <p className="route__adminPanel__images__data__clickable" onClick={e => {
                            animateBox(e, <DeleteImage data={img} selectMode={props.selectMode} onChange={(d) => {
                                if (!props.selectMode) {
                                    setData(oldData => {
                                        return {
                                            ...oldData,
                                            data: oldData?.data?.filter((_,ix) => ix !== imgIndex)
                                        };
                                    });
                                } else {
                                    props.onChange(d.URL);
                                    props.onClose();
                                };
                            }} />)
                        }}></p>
                        </div>;
                    })}
                    {canPaginate && <PaginationData />}
                    {secondarySpinner && <Spinner style={{width:"64px", height:"64px", alignSelf: "center"}} color="#6664E5" align="center" />}
                </>}
            </> : <Spinner style={{width:"64px", height:"64px"}} color="#6664E5" align="center" />}
        </div>
    </div>
};

const AddImage = (props) => {
    const internalRef = React.useRef();
    const nameRef = React.useRef();

    const [image, setImage] = React.useState();
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const addImage = () => {
        setInfoP("");
        if (!image) {
            return setInfoP("Slika nije odabrana");
        };
        if (!nameRef.current.value) return setInfoP("Naziv slike ne može biti prazan");
        

        let fd = new FormData();
        fd.append("ImageName", nameRef.current.value);
        fd.append("tag", "uploads");
        fd.append("image", image);
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/uploadImage`,
            data: fd,
            onUploadProgress: function(progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                percentCompleted = Math.round(percentCompleted);
                if (progress !== percentCompleted) setProgress(percentCompleted);
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                setInfoP("Došlo je do greške tokom uploada slike (možda prevelika)");
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        })
    };

    React.useEffect(() => {
        if (!image) return;
        if (!internalRef.current) return;

        const fr = new FileReader();
        fr.onload = e => {
            internalRef.current.querySelector("img").src = e.target.result;
        };
        fr.readAsDataURL(image);
    }, [image]);
    
    return <div className="route__adminPanel__images__addImage" ref={internalRef}>
        <div className="route__adminPanel__images__addImage__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            {progress === 0 ? 
                <Spinner style={{width:"64px", height:"64px"}} color="#6664E5" align="center" /> : 
                <>
                    <p>Uploadam...</p>
                    <Progressbar style={{width:"64px", height:"64px"}} color="#6664E5" align="center" value={progress} maximum={100} />
                </>
            }
        </div>
        <h2>Dodaj sliku</h2>

        <CustomInput ref={nameRef} type="text" placeholder="Naziv slike" accent="#6664E5" theme="dark" />
        <div className="route__adminPanel__images__addImage__placeholder">
            <img src="" style={{
                opacity: image ? 1 : 0
            }} />
            <p style={{opacity: image ? null : 1}} onClick={(e) => {
                e.currentTarget.parentElement.querySelector("input").click();
            }}>Kliknite da dodate / izmijenite sliku</p>
            <input type="file" accept="image/*" style={{display: "none"}} onChange={e => {
                if (e.target.files[0]) setImage(e.target.files[0]);
            }} />
        </div>
        {image && <CustomButtonSmall accent="#2b2c3e" value="Spremi" onClick={() => addImage()} style={{border: "1px solid #999FAE"}} />}
        <CustomButtonSmall accent="#2b2c3e" value="Izlaz" onClick={() => props.onClose()} style={{border: "1px solid rgb(233, 86, 86)"}} />
        {infoP && <p className="route__adminPanel__images__addImage__infoP">{infoP}</p>}
    </div>
};

const DeleteImage = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const deleteImage = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/deleteImage`,
            data: {
                id: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange();
                props.onClose();
            } else {
                setInfoP("Generična greška prilikom brisanja slike");
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__adminPanel__images__removeImage">
        <div className="route__adminPanel__images__removeImage__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }}>
            <Spinner style={{width:"64px", height:"64px"}} color="#6664E5" align="center" />
        </div>
        <p>{props.data.ImageName}</p>
        <img src={props.data.URL} />
        <div>
            {!props.selectMode ?
                <CustomButtonSmall accent="#2b2c3e" value="Obriši" onClick={() => deleteImage()} style={{border: "1px solid #999FAE"}} /> :
                <CustomButtonSmall accent="#2b2c3e" value="Odaberi" onClick={() => {
                    props.onChange(props.data);
                    props.onClose();
                }} style={{border: "1px solid #999FAE"}} />
            }
            <CustomButtonSmall accent="#2b2c3e" value="Izlaz" onClick={() => props.onClose()} style={{border: "1px solid rgb(233, 86, 86)"}} />
        </div>
        {infoP && <p style={{color: "#f75454"}}>{infoP}</p>}
    </div>    
}

export default Images;