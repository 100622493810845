const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "BACKNED_TASKS_UPDATE": return action.payload.map(p => {
            let tmp = {...p};
            tmp.DateCreated = Date.now();
            return tmp;
        });
        case "BACKEND_TASKS_REMOVE_STALE":
            let staleDate = Date.now() - (2 * 60 * 10000);
            return state.filter(s => s?.DateCreated < staleDate);
        default: return state;
    };
};