import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

import * as wavesurfer from "./wavesurfer/wavesurfer";
import { useSelector } from "react-redux";

import Spinner from "../Spinner";
import Slider from "../Slider";
import SendMessage from "../../SingleLead/sendMessage";
import { animateBox } from "../../../modules/componentAnimation";

// audioprocess
// finish
// loading
// mute
// volume

// props.theme
// props.accent
// props.src
const AudioPlayer = (props) => {
    const internalRef = React.useRef();
    const wavesurferInstance = React.useRef();
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [wavesurferReady, setWavesurferReady] = React.useState(false);
    const [audioTime, setAudioTime] = React.useState({
        cur: "00:00",
        max: "00:00"
    });
    const [audioLevel, setAudioLevel] = React.useState(100);
    const [canDownloadRecording, setCanDownloadRecording] = React.useState(false);
    const [canPlayRecording, setCanPlayRecording] = React.useState(false);
    const [playbackSpeed, setPlaybackSpeed] = React.useState(1);

    const theme = props.theme === "dark" ? "dark" : "light";
    const accent = props.accent ? props.accent : theme === "light" ? "black" : "white";
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});

    function clamp(input, min, max) {
        return input < min ? min : input > max ? max : input;
    };

    function map(current, in_min, in_max, out_min, out_max) {
        const mapped = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
        return clamp(mapped, out_min, out_max);
    };

    const processSeconds = sec => {
        sec = Math.floor(sec);
        let minutes = 0;

        while (sec >= 60) {
            minutes += 1;
            sec -= 60;
        };
        minutes = String(minutes);
        sec = String(sec);
        if (minutes.length === 1) minutes = `0${minutes}`;
        if (sec.length === 1) sec = `0${sec}`;
        return `${minutes}:${sec}`;
    };

    const generateTimestamps = (duration) => {
        if (!props.timestamps) return null;
        if (!Array.isArray(props.timestamps)) return null;
        if (!internalRef.current) return null;
        if (!duration) return;

        let curElement = internalRef.current.querySelector(".customComponents__audioPlayer__controls__surfer");
        if (!curElement) return;
        if (!curElement.querySelector("wave")) return;

        if (curElement.querySelector(".customComponents__audioPlayer__controls__surfer__overlay")) return;
        let overlayDiv = document.createElement("div");
        overlayDiv.classList.add("customComponents__audioPlayer__controls__surfer__overlay");
        overlayDiv.style.order = 0;
        curElement.appendChild(overlayDiv);
        let curRect = curElement.getBoundingClientRect();

        for (let timestamp of props.timestamps) {
            let div = document.createElement("div");

            div.style.backgroundColor = timestamp.color ?? "gray";
            div.style.left = `${map(timestamp.start, 0, duration, 0, curRect.width)}px`;
            div.style.width = `${(curRect.width / duration) * (timestamp.end - timestamp.start)}px`
            div.title = `Name: ${timestamp.name}\nStart: ${processSeconds(timestamp.start)}\nEnd: ${processSeconds(timestamp.end)}\nDuration: ${processSeconds(timestamp.end - timestamp.start)}`
            div.onclick = (e) => {
                e.stopPropagation();
                if (wavesurferInstance.current) {
                    wavesurferInstance.current.seekTo(map(timestamp.start, 0, duration, 0, 1));
                };
            };

            overlayDiv.appendChild(div);
        };
    };

    const generatePlaybackSpeedBtns = () => {
        let curElement = internalRef.current.querySelector(".customComponents__audioPlayer__controls__surfer");
        if (!curElement) return;
        if (!curElement.querySelector("wave")) return;

        let div = curElement.querySelector(".customComponents__audioPlayer__controls__surfer__playbackSpeedBtns");
        if (div) {
            let allElems = div.querySelectorAll(".customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn");
            allElems.forEach(e => e.remove());
        } else {
            div = document.createElement("div");
            div.classList.add("customComponents__audioPlayer__controls__surfer__playbackSpeedBtns");
            curElement.appendChild(div);
        };

        for (let item of [0.5, 1, 1.5, 2]) {
            let playbackBtn = document.createElement("span");
            playbackBtn.textContent = `${item}x`;
            playbackBtn.classList.add("customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn");
            if (props.theme === "dark") playbackBtn.style.color = "white";
            if (item === playbackSpeed) {
                playbackBtn.classList.add("customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn--active");
            };
            playbackBtn.addEventListener("click", (e) => {
                setPlaybackSpeed(item);
                e.currentTarget.parentNode.querySelectorAll(".customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn--active").forEach(elem => {
                    elem.classList.remove("customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn--active");
                });
                e.currentTarget.classList.add("customComponents__audioPlayer__controls__surfer__playbackSpeedBtns__btn--active");
            });
            div.appendChild(playbackBtn);
        };
    };

    React.useEffect(() => {
        document.documentElement.style.setProperty("--customComponents__audioPlayer", accent);
    }, [accent]);

    React.useEffect(() => {
        if (!internalRef.current) return;
        if (!props.src) return;
        let wsTimeout = null;
        let ws = wavesurfer.default.create({
            container: internalRef.current.querySelector(".customComponents__audioPlayer__controls__surfer"),
            scrollParent: false,
            progressColor: accent,
            backend: "MediaElement",
            preload: "auto",
            cursorColor: accent
        });

        wavesurferInstance.current = ws;

        ws.load(props.src);

        ws.on("ready", () => {
            setWavesurferReady(true);
            setAudioTime({
                cur: processSeconds(ws.getCurrentTime()),
                max: processSeconds(ws.getDuration())
            });
            clearTimeout(wsTimeout);
            wsTimeout = setTimeout(() => {
                generateTimestamps(wavesurferInstance.current.getDuration());
                generatePlaybackSpeedBtns();
            }, 0);
        });
        ws.on("play", () => setIsPlaying(true));
        ws.on("pause", () => setIsPlaying(false));
        ws.on("finish", () => setIsPlaying(false));
        ws.on("audioprocess", () => {
            setAudioTime({
                cur: processSeconds(ws.getCurrentTime()),
                max: processSeconds(ws.getDuration())
            });
        });
        ws.on("seek", () => {
            setAudioTime({
                cur: processSeconds(ws.getCurrentTime()),
                max: processSeconds(ws.getDuration())
            });
        });

        return () => {
            try {
                ws.destroy();
                ws = null;
                clearTimeout(wsTimeout);
            } catch { };
        };
    }, [internalRef.current]);

    React.useEffect(() => {
        if (wavesurferInstance.current) {
            wavesurferInstance.current.setVolume(audioLevel / 100);
        };
    }, [audioLevel]);

    React.useEffect(() => {
        if (userSelector?.isAdmin === true || userSelector?.isCCManager === true) {
            if (!canDownloadRecording) setCanDownloadRecording(true);
        } else {
            if (canPlayRecording) setCanPlayRecording(false);
        };
    }, [userSelector]);

    React.useEffect(() => {
        if (wavesurferInstance.current) {
            wavesurferInstance.current.setPlaybackRate(playbackSpeed);
        };
    }, [playbackSpeed]);

    return <div style={{ ...props.style ?? {} }} className={`customComponents__audioPlayer customComponents__audioPlayer--${theme}`} ref={internalRef}>
        {wavesurferReady ? (isPlaying ? <div className="customComponents__audioPlayer__btns">
            <svg onClick={() => {
                    try { wavesurferInstance.current.playPause(); } catch { };
                
            }} width="50" height="50" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="a">
                        <path d="m139.21 139.21h473.58v473.58h-473.58z" />
                    </clipPath>
                </defs>
                <path d="m330.36 270.29c4.6797 0 9.3633 4.6797 9.3633 10.145v191.15c0 5.4609-4.6797 9.3633-9.3633 9.3633-5.4609 0-10.145-3.9023-10.145-9.3633v-191.15c0-5.4609 4.6797-10.145 10.145-10.145z" fillRule="evenodd" />
                <path d="m421.64 270.29c5.4609 0 10.145 4.6797 10.145 10.145v191.15c0 5.4609-4.6797 9.3633-10.145 9.3633-5.4609 0-9.3633-3.9023-9.3633-9.3633v-191.15c0-5.4609 3.9023-10.145 9.3633-10.145z" fillRule="evenodd" />
                <g clipPath="url(#a)">
                    <path d="m375.61 139.21c131.07 0 237.18 106.11 237.18 236.4 0 131.07-106.11 237.18-237.18 237.18-130.29 0-236.4-106.11-236.4-237.18 0-130.29 106.11-236.4 236.4-236.4zm0 17.164c121.71 0 220.02 98.305 220.02 219.23 0 121.71-98.305 220.02-220.02 220.02-120.93 0-219.23-98.305-219.23-220.02 0-120.93 98.305-219.23 219.23-219.23z" fillRule="evenodd" />
                </g>
            </svg>
            {canDownloadRecording && <p onClick={() => {
                window.open(props.src, "_blank");
            }}>Download</p>}
            {props.selectRecording && <p onClick={(e) => animateBox(e, <SendMessage type={'recording'} id={props.src} />)} >Pošalji u poruci</p>}
        </div> : <div className="customComponents__audioPlayer__btns">
            <svg onClick={() => {
                try { wavesurferInstance.current.playPause(); } catch { };
            }} width="50" height="50" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
                <path d="m376 172.36c-112.41 0-203.64 91.23-203.64 203.64s91.23 203.64 203.64 203.64 203.64-91.23 203.64-203.64-91.23-203.64-203.64-203.64zm0 9.4727c107.29 0 194.17 86.875 194.17 194.17s-86.875 194.17-194.17 194.17-194.17-86.879-194.17-194.17c0-107.29 86.875-194.17 194.17-194.17zm-71.926 99.453h0.003906c-2.2656 0.42969-3.8906 2.4297-3.8516 4.7344v179.96c-0.011718 1.6992 0.88281 3.2773 2.3516 4.1328s3.2812 0.85938 4.7539 0.011719l161.02-89.98h0.003906c1.6797-0.94922 2.6133-2.8242 2.3672-4.7344-0.18359-1.4961-1.0625-2.8125-2.3672-3.5547l-161.02-89.98h-0.003907c-0.98047-0.58203-2.1328-0.79297-3.2539-0.58984zm5.625 12.727 146.66 81.988-146.66 81.988z" />
            </svg>

            <div className="customComponents__audioPlayer__btns__options" style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:"100%"}}>
                {canDownloadRecording && <img style={{width:'20px', cursor:'pointer'}} onClick={() => {
                    window.open(props.src, "_blank");
                }} src='/images/download.png' />}
                {props.selectRecording && <p style={{width:'20px', cursor:'pointer'}} onClick={(e) => animateBox(e, <SendMessage type={'recording'} id={props.src}/>)}> 
                    <img style={{width:'20px'}}   src='/images/msgIcon.svg' />
                    </p>}
            </div>
        </div>) : <Spinner style={{ width: "30px", height: "30px" }} color={accent} />}
        <div className="customComponents__audioPlayer__controls">
            <div className="customComponents__audioPlayer__controls__surfer"></div>
            <div className="customComponents__audioPlayer__controls__slider">
                <p>{`${audioTime.cur} / ${audioTime.max}`}</p>
                <svg className="customComponents__audioPlayer__controls__slider__audio" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                    <path style={{ fill: accent }} d="M4,11v10h5l10,6V5L9,11H4z M17,8.532v14.935L9.554,19H6v-6h3.554L17,8.532z M21.657,21.657  l-1.414-1.414c2.339-2.34,2.339-6.146,0-8.486l1.414-1.414C24.775,13.462,24.775,18.538,21.657,21.657z M24.485,24.485l-1.414-1.414  c3.899-3.899,3.899-10.243,0-14.143l1.414-1.414C29.164,12.193,29.164,19.807,24.485,24.485z" />
                </svg>
                <div className="customComponents__audioPlayer__controls__slider__audioPopout">
                    <Slider accent={accent} theme={theme} min={0} max={100} defaultValue={audioLevel} onChange={(e) => {
                        setAudioLevel(Number(e));
                    }} />
                </div>
            </div>
        </div>
    </div>
};

AudioPlayer.prototype = {
    theme: PropTypes.oneOf(["light", "dark"]),
    accent: PropTypes.string,
    src: PropTypes.string,
    timestamps: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        color: PropTypes.string
    }))
};

export default AudioPlayer;