import React from "react";
import "./MessageTypes.scss";

import moment from "moment";
import "moment/locale/bs";
import axios from "axios";
import emojiToolkit from "emoji-toolkit";

import { internal__paginateChat, internal__clearChatRoomNotifications, on as socketOn, off as socketOff } from "../../../modules/socketModule";
import { animateBox } from "../../../modules/componentAnimation";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule"
import { getDealStatusColor2, getLeadStatusColor2 } from "../../../modules/leadModule";

import CustomButtonSmall from "../../customComponents/ButtonSmall";

import Spinner from "../../customComponents/Spinner";
import SingleLead from "../../SingleLead";
import { Tasks_singleTask } from "../../Tasks";
import AudioPlayer from "../../customComponents/AudioPlayer";


export const Message = (props) => {
    const mainRef = React.useRef();

    let isRepeating = false;
    if (props.message?.From === props?.previous?.From) isRepeating = true;

    if (isRepeating) {
        if (moment(props.message.Date).format("DD.MM.YYYY") !== moment(props.previous.Date).format("DD.MM.YYYY")) {
            isRepeating = false;
        };
    };

    React.useEffect(() => {
        if (!props.pingNotifications) return;

        internal__clearChatRoomNotifications(props.chatRoomID);
    }, [props.lastMessage, props.pingNotifications]);

    React.useEffect(() => {
        if (!mainRef?.current) return;

        if (props.scrollIntoView) {
            setTimeout(() => {
                mainRef?.current?.scrollIntoView({ behavior: props.animated.animated ? "auto" : "smooth", inline: "end" });
                if (!props.animated.animated) props.animated.setAnimated(true);
            }, (props.animated.animated ? 0 : 200))
        };

        mainRef.current.animate([
            { left: getComputedStyle(mainRef.current).left },
            { left: "0%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease", delay: 0 });
    }, [mainRef.current]);

    return <div className={`component__chat__wrap__content__chat__message ${props.message?.From === props.curUser?.ID ? "component__chat__wrap__content__chat__message--primary" : "component__chat__wrap__content__chat__message--secondary"}`} ref={mainRef} style={{
        marginTop: (props.messageID !== 0 ? (isRepeating ? "10px" : "20px") : "0px")
    }} title={moment(props.message?.Date).toDate().toLocaleString()}>
        {!isRepeating ?
            <img src={props.userImage} onError={e => e.currentTarget.src = "/images/undefined.png"} className="component__chat__wrap__content__chat__message__userImage" /> :
            <p className="component__chat__wrap__content__chat__message__userImage component__chat__wrap__content__chat__message__userImage--hidden">
                {moment(props.message?.Date).format("HH:mm")}
            </p>
        }
        <p className="component__chat__wrap__content__chat__message__content">
            {!isRepeating && <p className="component__chat__wrap__content__chat__message__content__usr">
                <p>{props.userData?.FirstName ? `${props.userData?.FirstName} ${props.userData?.LastName}` : "?"}</p>
                <p className="component__chat__wrap__content__chat__message__content__usr__date">{moment(props.message?.Date).isAfter(moment().add("-1", "days")) ? (() => {
                    let tmpFromNow = moment(props.message?.Date).locale("ba").fromNow();
                    if (!tmpFromNow) return null;
                    return [tmpFromNow[0].toUpperCase(), tmpFromNow.substring(1, tmpFromNow.length)].join("");
                })() : moment(props.message?.Date).toDate().toLocaleDateString()}</p>
            </p>}
            {React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    style: {
                        maxWidth: '100%',
                        backgroundColor: (props.message?.From === props.curUser?.ID) ? "rgb(82, 82, 116)" : "rgb(186, 186, 207)",
                        color: (props.message?.From === props.curUser?.ID) ? "#FFFFFF" : "rgb(29, 29, 41)",
                        marginTop: isRepeating ? "0px" : "5px",
                        borderRadius: "2px",
                        ...(child?.props?.style ?? {})
                    }
                });
            })}
        </p>
    </div>
};

export const TextMessage = (props) => {
    const chatUsersSelector = useSelector(state => state?.chatUsers ?? []);


    const convertEmoji = text => {
        emojiToolkit.ascii = true;
        return emojiToolkit.shortnameToUnicode(text);
    };

    const returnSpecialTags = str => {
        if (!str.startsWith("<#") || !str.endsWith(">")) return str;
        str = String(str).substring(2, str.length - 1).split(":");
        if (str.length < 2) return str.join(":");

        const type = str.shift();
        const data = str.join(":");

        switch (type) {
            case "link": return <a href={data} target="_blank">{decodeURI(data)}</a>
            case "mention": return <span className={`component__chat__wrap__content__chat__message__text__mention ${data === "all" || (String(data) === String(props.curUser?.ID)) ? "component__chat__wrap__content__chat__message__text__mention--active" : ""}`}>{(() => {
                if (data === "all") return "@svi";
                let tmpUsr = chatUsersSelector.find(u => String(u.ID) === String(data));
                if (!tmpUsr) return "@?";
                return `@${tmpUsr?.FirstName ?? "?"} ${tmpUsr?.LastName ?? ""}`
            })()}</span>
            default: return data;
        };
    };

    const parseSpecialMessage = (msg) => {
        let out = [];
        let buff = "";
        let specialRead = false;

        for (let c of msg) {
            if (c === "<" && !specialRead) {
                out.push(buff);
                buff = "";
            } else if (c === "#" && buff === "<" && specialRead === false) {
                specialRead = true;
            } else if (buff.endsWith(">") && specialRead) {
                specialRead = false;
                out.push(returnSpecialTags(buff));
                buff = "";
            };

            buff += c;
        };
        if (buff) out.push(returnSpecialTags(buff));

        out = out.map(text => {
            if (typeof (text) !== "string") return text;
            return convertEmoji(text);
        });

        return out
    };

    const parseMessage = (msg) => {
        let rgxp = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;
        let c = String(msg);
        let match = String(c).match(rgxp);

        if (!match) {
            if (!c?.includes("<#")) {
                return convertEmoji(c);
            } else {
                return parseSpecialMessage(convertEmoji(c));
            };
        };

        for (let m of [...new Set(match)]) {
            c = c.replaceAll(m, `<#link:${encodeURI(m)}>`);
        };

        return parseSpecialMessage(c);
    };

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__text" style={{
            display: "unset"
        }}>
            {parseMessage(props.message?.Content)}
        </div>
    </Message>
};

export const ImageMessage = (props) => {
    const imgData = React.useRef(JSON.parse(props.message?.Content ?? "'{}'"));

    const FullScreenMessage = (props2) => {
        return <div className="component__chat__wrap__content__chat__message__image__fullscreen">
            <CustomButtonSmall value="X" accent="#d82626" style={{ marginLeft: "auto", marginBottom: "10px", position: 'absolute', top: 0, right: 0 }} onClick={props2.onClose} />
            <img src={props2.image} />
        </div>
    }

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__image" onClick={(e) => { animateBox(e, <FullScreenMessage image={imgData.current.file} />) }}>
            <img src={imgData.current.file} />
            <p>{imgData.current.name}</p>
        </div>
    </Message>
};

export const VideoMessage = (props) => {
    const videoData = React.useRef(JSON.parse(props.message?.Content ?? "'{}'"));

    const FullScreenVideo = (props2) => {
        return <div className="component__chat__wrap__content__chat__message__video__fullscreen">
            <CustomButtonSmall value="X" accent="#d82626" style={{ marginLeft: "auto", marginBottom: "10px", position: 'absolute', top: 0, right: 0 }} onClick={props2.onClose} />
            <video src={props2.source} preload="metadata" controls={true}>
                <source src={`${props2.source}#t=0.1`} />
            </video>
        </div>
    }

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__video" onClick={(e) => { animateBox(e, <FullScreenVideo source={videoData.current.file} />) }}>
            <video src={videoData.current.file} preload="metadata">
                <source src={`${videoData.current.file}#t=0.1`} />
            </video>
            <p>{videoData.current.name}</p>
        </div>
    </Message>
};

export const FileMessage = (props) => {
    const fileData = React.useRef(JSON.parse(props.message?.Content ?? "'{}'"));
    let tmpData = fileData.current.name.split(".");
    let extension = "?";
    let name = "?";
    if (tmpData.length === 1) {
        name = tmpData[0];
    } else {
        extension = tmpData.pop().toUpperCase();
        name = tmpData.join(".");
    };

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__file" style={{ pointerEvents: "all" }} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(fileData.current.file);
        }}>
            <div className="component__chat__wrap__content__chat__message__file__left">
                <img src="/images/chat_file.svg" />
            </div>
            <div className="component__chat__wrap__content__chat__message__file__right">
                <p className="component__chat__wrap__content__chat__message__file__right__name">{name}</p>
                <p className="component__chat__wrap__content__chat__message__file__right__type">{extension}</p>
            </div>
        </div>
    </Message>
};

export const LeadDealMessage = (props) => {
    const [data, setData] = React.useState();

    const chatUsersSelector = useSelector(state => state.chatUsers ?? []);
    const statusCodesSelector = useSelector(state => state.statusCodes ?? {});

    const finalStatusSelector = props.type === "lead" ? statusCodesSelector["leads"] : statusCodesSelector["deals"];
    const finalStatusFn = props.type === "lead" ? getLeadStatusColor2 : getDealStatusColor2;

    const getUserImage = (ID) => {
        let curID = ID;

        let finalUser = chatUsersSelector.find(cu => cu.ID === curID);
        if (!finalUser) return "/images/undefined.png";

        return finalUser.Image ?? "#";
    };

    React.useEffect(() => {
        if (data) return;

        let url = "";
        if (props.type === "lead") {
            url = "/leads/getAllLeadsDashboard";
        } else {
            url = "/deals/getAllDealsDashboard";
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}${url}`,
            data: {
                filters: [{ name: "ID", op: "eq", value: props.message?.Content }],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    return setData({ status: "ok", data: res.data.data[0] });
                };
            };
            return setData(backendModule.genericError);
        }).catch(() => {
            return setData(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        let handler = hData => {
            hData = backendModule.parseData(hData);
            if (hData.ID === data.data.ID) setData({ ...data, data: hData });
        };

        let handlerName = props.type === "deal" ? "pingDealChanges" : "pingLeadChanges";
        socketOn(handlerName, handler);

        return () => socketOff(handlerName, handler);
    }, [data]);

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__lead" style={{ pointerEvents: "all" }} onClick={(e) => {
            if (data.status !== "ok") return;
            animateBox(e, <SingleLead data={data.data} prefetch={true} type={props.type} />);
        }}>
            <h2>{props.type === "lead" ? "Lead" : "Deal"} -
                {props.message?.Content}
                {/* {data?.status === "ok" ? data?.data?.criptedID : ""} */}
            </h2>
            {!data ? <Spinner style={{ marginTop: "10px", width: "32px", height: "32px" }} color="white" /> : <>
                {data.status === "error" ? <p className="component__chat__wrap__content__chat__message__lead__error">{props.type === "deal" ? "Deal" : "Lead"} ne postoji ili nemate pristupa informacijama!</p> : <>
                    <p className="component__chat__wrap__content__chat__message__lead__data">
                        <small>Naziv</small>
                        <span>{String(data?.data?.Name).substring(0, 100)}</span>
                    </p>
                    <p className="component__chat__wrap__content__chat__message__lead__data">
                        <small>Ime klijenta</small>
                        <span>{String(data?.data?.FirstLastName).substring(0, 100)}</span>
                    </p>
                    <p className="component__chat__wrap__content__chat__message__lead__data">
                        <small>Broj telefona</small>
                        <span>{String(data?.data?.PhoneNumber).substring(0, 4) + "*".repeat(String(data?.data?.PhoneNumber).length - 1)}</span>
                    </p>
                    <p className="component__chat__wrap__content__chat__message__lead__data">
                        <small>Odgovorni agent</small>
                        <span><img src={getUserImage(data?.data?.ResponsiblePerson)} onError={e => e.currentTarget.src = "/images/undefined.png"} /> {String(data?.data?.ResponsiblePersonName).substring(0, 100)}</span>
                    </p>
                    <p className="component__chat__wrap__content__chat__message__lead__data component__chat__wrap__content__chat__message__lead__data--status">
                        <small>{finalStatusSelector[String(data.data.Status)]}</small>
                        <div style={{ gridTemplateColumns: `repeat(${Object.keys(finalStatusSelector).length}, 1fr)` }}>
                            {Object.keys(finalStatusSelector).map((sc, sci, arr) => {
                                let idx = arr.indexOf(String(data.data.Status));
                                return <span style={{
                                    backgroundColor: idx > sci ? finalStatusFn(data.data.Status) : "gray"
                                }}></span>
                            })}
                        </div>
                    </p>
                    <span className="component__chat__wrap__content__chat__message__text__click">Klikni za više informacija</span>
                </>}
            </>}
        </div>
    </Message>
};

export const NoteMessage = (props) => {
    const noteRef = React.useRef(JSON.parse(props.message?.Content ?? "{}"));

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__text component__chat__wrap__content__chat__message__note">
            <h3>Bilješka - <span>{noteRef.current?.name}</span></h3>
            <p>{noteRef.current?.description}</p>
        </div>
    </Message>
};

export const TaskMessage = (props) => {
    const [data, setData] = React.useState();

    React.useEffect(() => {
        const handler = task => {
            task = backendModule.parseData(task);
            if (data.ID === task.ID && !task?.isDeleted) {
                setData(task);
            } else {
                setData({ status: "error", data: "DELETED" });
            };
        };

        socketOn("pingTaskChanges", handler);

        return () => socketOff("pingTaskChanges", handler);
    }, [data]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/tasks/getAllTasks`,
            data: {
                limit: 1,
                filters: [{ name: "ID", op: "eq", value: props.message?.Content }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    setData({ status: "ok", data: res.data.data[0] });
                } else if (res.data.data.length > 1) {
                    setData({ status: "error", data: "DELETED" });
                } else {
                    setData(backendModule.genericError);
                };
            } else {
                setData(backendModule.genericError);
            };
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__text component__chat__wrap__content__chat__message__task" onClick={e => {
            if (data.status !== "ok") return;
            animateBox(e, <Tasks_singleTask data={data.data} />);
        }}>
            {!data ? <Spinner style={{ marginTop: "10px", width: "32px", height: "32px" }} color="white" /> : <>
                {data.status === "error" ? <p>
                    {data.data === "DELETED" ? <div>
                        <h3 >Zadatak:</h3>
                        <br />
                        <p style={{ color: 'red' }}>Zadatak obrisan</p>
                    </div> : <div>
                        <h3 >Zadatak:</h3>
                        <br />
                        <p style={{ color: 'red' }}>Nemate pristupa ovom zadatku!</p>
                    </div>}
                </p> : <>
                    <h3>Zadatak - <span>{data.data.Name}</span></h3>
                    <p>{data.data.Description}</p>
                    <p style={{ marginTop: "20px" }}>Završen: {data.data.isCompleted ? "Da" : "Ne"}</p>
                    {!data.data.isCompleted && <p>Krajnji rok: {moment(data.data.DueDate).toDate().toLocaleString()}</p>}
                    <span className="component__chat__wrap__content__chat__message__text__click">Klikni za više informacija</span>
                </>}
            </>}
        </div>
    </Message>
};

export const SpinnerMessage = (props) => <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
    <div className="component__chat__wrap__content__chat__message__text">
        <Spinner color="white" />
    </div>
</Message>;


let tmpTimeout = null;
export const PaginateSpinner = (props) => {
    let tmpRef = React.useRef();
    let tmpReact = null;

    React.useEffect(() => {
        if (!tmpRef.current);
        tmpRef.current.style.display = null;
    }, [tmpRef.current]);

    React.useEffect(() => {
        if (!tmpRef?.current) return;
        let observer = null;
        clearTimeout(tmpReact);
        tmpReact = setTimeout(() => {
            observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        try { observer.unobserve(tmpRef.current); } catch { };
                        if (true) {
                            clearTimeout(tmpTimeout);
                            tmpTimeout = setTimeout(() => {
                                try {
                                    internal__paginateChat(props.room, props.chat)
                                    if (props.onPaginate) props.onPaginate();
                                } catch { };
                            }, 1000);
                        };
                    } else {
                        clearTimeout(tmpTimeout);
                    };
                });
            }, { threshold: [1] });
            observer.observe(tmpRef.current);
        }, 500);
        return () => {
            if (tmpRef?.current) {
                try { observer.unobserve(tmpRef.current); } catch { };
            };
        };
    }, [tmpRef]);

    return <div ref={tmpRef} className="component__paginateSpinner" style={{ width: "100%" }}>
        <Spinner color="white" align="center" />
    </div>
}


export const RecordingMessage = (props) => {
    return <Message {...props} key={`chat-${props.chatRoomID}-${props.message.ID}`}>
        <div className="component__chat__wrap__content__chat__message__recording">
            <AudioPlayer accent="#6664e5" theme={props.message?.From === props.curUser?.ID ? "dark" : "white"} src={props.message?.Content} />
        </div>
    </Message>
};