export const addModal = (component, additionalInfo) => {
    return {
        type: "ADD_MODAL",
        payload: {
            component,
            additionalInfo
        }
    };
};

export const removeModal = ID => {
    return {
        type: "REMOVE_MODAL",
        payload: {
            ID
        }
    };
};

export const setModalVisibility = visible => {
    return {
        type: "SET_VISIBILITY_MODAL",
        payload: !!visible
    };
};