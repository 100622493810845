const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "NOTIFICATIONS_ADD":
            let id = 1;
            if (state.length > 0) id = Math.max(...state.map(s => s.ID)) + 1;
            
            return [
                ...state,
                {
                    ID: id,
                    text: action.payload.text,
                    onClose: action.payload.onClose,
                    closeText: action.payload.closeText,
                    onConfirm: action.payload.onConfirm,
                    confirmText: action.payload.confirmText,
                    date: new Date(),
                    isClosed: false
                }
            ];
            case "NOTIFICATIONS_REMOVE": return state.filter(t => t.ID !== action.payload);
            case "NOTIFICATIONS_CLOSE": return state.map(st => {
                if (st.ID === action.payload) st.isClosed = true;
                return st;
            })
        default: return state;
    };
};