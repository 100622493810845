const initialState = {
    modals: [],
    visible: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "ADD_MODAL":
            let maxID = Math.max(...state.modals.map(e => e.ID));
            if (maxID === -Infinity) maxID = -1;
            return {
                ...state,
                modals: [
                    ...state.modals,
                    {
                        ...action.payload,
                        ID: maxID+1,
                        component: action.payload.component
                    }
                ]
            };
        case "REMOVE_MODAL":
            return {
                ...state,
                modals: state.modals.filter(item => item.ID !== action.payload.ID)
            };
        case "SET_VISIBILITY_MODAL":
            return {
                ...state,
                visible: action.payload
            };
        default: return state;
    };
};