import React from "react";
import axios from "axios";
import "./index.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from '../../components/customComponents/Spinner'
import Dropdown from "../customComponents/Dropdown";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import CustomInput from "../customComponents/CustomInput";
import * as backendModule from '../../modules/backendModule'

const EditDeal = (props) => {
    let userData = useSelector((state) => state.userData)
    let statusCodes = useSelector((state) => state.statusCodes);
    let headersColors = ['#6664E5A1', '#85E956A1', '#85E956A1','#85E956A1', '#56E9CFA1', '#E9568BA1', '#E656E9A1', '#3B88E3A1', "#2b2c3e"]
    const [activeStatus, setActiveStatus] = React.useState(String(String(props.lead.oldStatus) ? props.lead.oldStatus : props.lead.Status));
    const [callAgainFinal, setCallAgainFinal] = React.useState(props.lead.ScheduledCallTime);
    //edit states
    const [buyerName, setBuyerName] = React.useState(props.lead.FirstLastName);
    const [buyerPhone, setBuyerPhone] = React.useState(props.lead.PhoneNumber);
    const [buyerAddress, setBuyerAddress] = React.useState(props.lead.Address);
    const [buyerCity, setBuyerCity] = React.useState(props.lead.City);
    const [buyerCountry, setBuyerCountry] = React.useState(props.lead.Country);
    const [buyerHouse, setBuyerHouse] = React.useState(props.lead.HouseNumber)
    const [allCountries, setAllCountries] = React.useState([]);
    const [allCities, setAllCities] = React.useState(null);
    const [shippmentInfo, setShipmentInfo] = React.useState(props.lead.AdditionalShippmentInfo)
    const [amount, setAmount] = React.useState(props.lead.Amount)
    const [allArticles, setAllArticles] = React.useState([]);
    const [selectedArticles, setSelectedArticles] = React.useState(props.lead.Articles ? JSON.parse(props.lead.Articles) : []);
    const [deliveryDate, setDeliveryDate] = React.useState(Date.now());
    const [leadBirthYear, setBirthYear]=React.useState(props.lead.BirthYear);



    const getCities = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: buyerCountry
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllCities(res.data.data)
            };
        }).catch(() => null);
    };
    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setAllCountries(res.data.data);
            } else {
                setTimeout(() => getCountries(), 1000);
            };
        }).catch(() => {
            setTimeout(() => getCountries(), 1000);
        });
    };
    const getArticles = async () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/articles/getAllArticlesAgent`,
            data: {
                CountryID: allCountries.find(t => t.CountryCode === buyerCountry)?.ID ?? null
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {

                setAllArticles(res.data.data)
            };
        }).catch(() => null);
    };
    React.useEffect(() => {
        if (!buyerCountry) return;
        if (!allCountries) return;
        if (allCountries.length === 0) return;
        getCities();
        getArticles();

    }, [buyerCountry, allCountries]);
    React.useEffect(() => {
        getCountries();
    }, [])
    const saveData = () => {
        let data = {
            ...props.lead,
            authUser: userData.userData.UserInfo.ID,
            ID: props.lead.ID,
            UserID: props.lead.UserID,
            Name: props.lead.Name,
            Status: activeStatus,
            Amount: amount,
            FirstLastName: buyerName,
            PhoneNumber: buyerPhone,
            Address: buyerAddress,
            City: buyerCity,
            Country: buyerCountry,
            HouseNumber: buyerHouse,
            ResponsiblePerson: props.lead.ResponsiblePerson,
            ResponsiblePersonName: props.lead.ResponsiblePersonName,
            ScheduledCallTime: callAgainFinal,
            Articles: selectedArticles,
            AdditionalShippmentInfo: shippmentInfo,
            ScheduledDelivery: deliveryDate,
            BirthYear:leadBirthYear
        }
        if (props.onChange) {
            data.Articles = JSON.stringify(data.Articles);
            props.onChange(data);
            props.onClose();
        } else {
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/deals/editDeal`,
                data,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    props.onClose()
                }
            }).catch((err) => { })
        };
    }
    React.useEffect(() => {
        if (!allArticles) return;
        let tmp = 0
        for (let i = 0; i < selectedArticles?.length; i++) {
            for (let j = 0; j < allArticles.length; j++) {
                if (selectedArticles[i]?.data.id === allArticles[j].ID) {
                    tmp += Number(allArticles[j].ArticlePrice * selectedArticles[i].data.quantity)
                }

            }
        }
        setAmount(tmp)
    }, [selectedArticles, allArticles])

    return <>{allCities === null ? <div className="component__editLead" ><Spinner /></div> : <div className="component__editLead" >
        <div className="route__adminPanel__leads__buttons">
        {props.onClose && <CustomButtonSmall accent="#d82626" value="X" onClick={() => props.onClose()} />}
        </div>
        {!props.onChange && <>
            <p>Status</p>
            <div className="component__editLead__statusContainer">
                {Object.keys(statusCodes?.deals).map((item, index) => {
                    if (item === "5000") return null;
                    return <p key={index} className="component__editLead__statusContainer__single"
                        style={activeStatus === item ? { 'backgroundColor': headersColors[index] } : { 'backgroundColor': '#323341' }}
                        onClick={(e) => {
                            if(item==='49' && (userData.userData.UserInfo.Flags.isAdmin===false || userData.userData.UserInfo.Flags.isCCManager===false)){
                                return 
                            }
                            
                            setActiveStatus(item)
                        }}
                    >
                        {statusCodes?.deals[item]}
                    </p>
                })}
            </div>
        </>}
        <div className="component__editLead__inputs">
            <label>Ime kupca</label>
            <input type='text' defaultValue={buyerName} onChange={(e) => {
                setBuyerName(e.target.value)
            }} /></div>
            <div className="component__editLead__inputs">
            <label>Godina rođenja</label>
            <input type='number' defaultValue={leadBirthYear}  onChange={(e) => {
                setBirthYear(e.target.value)
            }} /></div>
        <div className="component__editLead__inputs">
            <label>Broj telefona</label>
            <input type='text' defaultValue={buyerPhone} onChange={(e) => {
                setBuyerPhone(e.target.value)
            }} /></div>
        <div className="component__editLead__inputs">
            <label>Adresa</label>
            <input type='text' defaultValue={buyerAddress} onChange={(e) => {
                setBuyerAddress(e.target.value)
            }} /></div>
        <div className="component__editLead__dropdown" style={{
            display: props.onChange ? "none" : null
        }}>
            <Dropdown theme='dark' accent="#6664E5A1" data={allCountries.map(country => {
                return { name: country.CountryName, value: country.CountryCode }
            })} placeholder='Izaberite državu'
                selected={(() => {
                    let id = null;
                    if (buyerCountry) {
                        id = allCountries.indexOf(allCountries.find(t => t.CountryCode === buyerCountry))
                        if (id < 0) id = null
                    } else if (props.lead.Country) {
                        id = allCountries.indexOf(allCountries.find(t => t.CountryCode === props.lead.Country))
                        if (id < 0) id = null
                    };
                    return id
                })()}
                onChange={(e) => {
                    setBuyerCountry(e?.value)
                }} />
        </div>
        <div className="component__editLead__dropdown">
            <Dropdown theme='dark' accent="#6664E5A1"
                selected={(() => {
                    let id = null;

                    if (props.lead.City) {
                        id = allCities.indexOf(allCities.find(t => t.CityID === props.lead.City))
                        if (id < 0) id = null
                    }
                    return id
                })()}
                data={allCities.map((item) => {
                    return { name: `${item.Name} ${item.PttNo}`, value: item.CityID }
                })} placeholder='Izaberite grad' onChange={(e) => {
                    setBuyerCity(e?.value)
                }} />
        </div>

        <div className="component__editLead__inputs">
            <label>Broj kuće</label>
            <input type='text' defaultValue={buyerHouse} onChange={(e) => {
                setBuyerHouse(e.target.value)
            }} />

        </div>
        <div className="component__editLead__dropdown">
            <Dropdown theme='dark' accent="#6664E5A1"

                data={allArticles.map((item, index) => {
                    return { name: `${item.ArticleName}`, value: { id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`, data: { id: item.ID, text: item.ArticleName + ' ' + item.ArticlePrice, quantity: 1 } } }
                })} placeholder='Izaberite proizvode' onChange={(e) => {
                    setSelectedArticles([...selectedArticles, e.value])
                }} />
        </div>
        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles">
            {selectedArticles?.map(art => {
                return <div key={art.id} className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle">
                    <CustomButtonSmall value="Obriši" accent="red" style={{ width: "70px", height: "45px" }} onClick={() => {
                        setSelectedArticles(d => [
                            ...d.filter(dt => dt.id < art.id),
                            ...d.filter(dt => dt.id > art.id)
                        ]);
                    }} />
                    {art.data === null ? <Dropdown
                        accent="#6664E5"
                        style={{ margin: "10px 0px", width: "calc(100% - 80px)" }}
                        data={allArticles.map(curArticle => {
                            const curCountry = allCountries?.find(t => t.ID === curArticle.CountryID);
                            let CountryCurrency = "?";
                            let countryPrefix = "";
                            if (curCountry) {
                                CountryCurrency = curCountry.CountryCurrency;
                                countryPrefix = `(${curCountry.CountryCode}) `;
                            };
                            return { name: `${countryPrefix}${curArticle.ArticleName} - ${curArticle.ArticlePrice} ${CountryCurrency}`, value: curArticle.ID }
                        })}
                        onChange={e => {
                            if (!e) return;
                            setSelectedArticles(d => [
                                ...d.filter(t => t.id < art.id),
                                {
                                    id: art.id,
                                    data: {
                                        id: e.value,
                                        text: e.name,
                                        quantity: 1
                                    }
                                },
                                ...d.filter(t => t.id > art.id),
                            ])
                        }}
                    /> : <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle__selected">
                        <p>{art?.data.text}</p>
                        <p> količina:</p>
                        <CustomInput accent="#6664E5" theme="dark" type="number" value={art.data.quantity} onChange={e => {
                            setSelectedArticles(d => {
                                return d.map(dt => {
                                    if (dt.id === art.id) {
                                        let newVal = Number(e.currentTarget.value) ?? 0;
                                        dt.data.quantity = newVal > 0 ? newVal : 1;
                                    };
                                    return dt;
                                });
                            });
                        }} />
                    </div>}
                </div>
            })}
        </div>
        <div className="component__editLead__inputs">
            <label>Otkupna cijena: </label>
            <input type='number' value={amount} readOnly/>

        </div>
        <div className="component__editLead__inputs">
            <label>Informacije kuriru</label>
            <input type='text' defaultValue={shippmentInfo} onChange={(e) => {
                setShipmentInfo(e.target.value)
            }} />
        </div>
        <div className="component__editLead__inputs">
            <label>Datum dostave</label>
            <input type='date' defaultValue={moment(deliveryDate).format("YYYY-MM-DD")} onChange={(e) => {
                setDeliveryDate(moment(e.target.value).toDate().getTime());
            }} />
        </div>
        {/* <Dropdown accent="#6664E5" theme="dark"
            selected={activeAgent}
            data={chatUsers.map((item) => {
                return { name: `${item.FirstName} ${item.LastName}`, value: item.ID }
            })} placeholder='Agent' onChange={(e) => {
                setActiveAgent(e.value)
            }} /> */}
        <div className="ccmanager__singleLead__buttons">
            <CustomButtonSmall accent="rgb(102, 100, 229)" value="Sačuvaj" onClick={(e) => saveData()} />
            <CustomButtonSmall accent="rgb(102, 100, 229)" value="Otkaži" onClick={(e) => props.onClose()} />
        </div>

    </div>}</>
}
export default EditDeal