import React from "react";
import "./index.scss";

import PropTypes from "prop-types";

const Slider = (props) => {
    const internalRef = React.useRef();
    const theme = props.theme ? (props.theme === "dark" ? "dark" : "light") : "light";
    const accent = props.accent ? props.accent : theme === "light" ? "#00a3ff" : "white";

    const [isMoving, setIsMoving] = React.useState(false);

    function clamp(input, min, max) {
        return input < min ? min : input > max ? max : input;
    };

    function map(current, in_min, in_max, out_min, out_max) {
        const mapped = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
        return clamp(mapped, out_min, out_max);
    };

    React.useEffect(() => {
        if (!internalRef.current) return;

        let value = props.value ? props.value : (props.defaultValue ? props.defaultValue : 0);
        value = Number(value);
        if (isNaN(value)) value = 0;

        let min = props.min ?? 0;
        let max = props.max ?? 100;
        min = Number(min);
        max =  Number(max);

        if (isNaN(min)) min = 0;
        if (isNaN(max)) max = 0;

        if (min > max) {
            let tmp = min;
            min = max;
            max = tmp;
        };

        const finalValue = map(value, min, max, 0, 100);

        const fill = internalRef.current.querySelector(".customComponents__slider__fill");
        if (!fill) return;
        const thumb = internalRef.current.querySelector(".customComponents__slider__thumb");
        if (!thumb) return;

        thumb.style.left = `calc(${finalValue}% - 10px)`;
        fill.style.width = `${finalValue}%`;
    }, [props.defaultValue, props.value]);

    React.useEffect(() => {
        document.documentElement.style.setProperty("--customComponents__slider__accent", accent);
    }, [accent]);

    React.useEffect(() => {
        if (!internalRef.current) return;
        const thumb = internalRef.current.querySelector(".customComponents__slider__thumb");
        if (!thumb) return;

        const pointerDown = () => {
            setIsMoving(true);
            document.documentElement.addEventListener("mouseup", pointerUp);
            internalRef.current.addEventListener("mouseup", pointerUp);
        };
        const pointerUp = () => {
            setIsMoving(false);
            document.body.removeEventListener("mouseup", pointerUp);
            internalRef?.current?.removeEventListener("mouseup", pointerUp);
        };

        thumb.addEventListener("mousedown", pointerDown);

        return () => {
            thumb.removeEventListener("mousedown", pointerDown);
            document.body.removeEventListener("mouseup", pointerUp);
            internalRef?.current?.removeEventListener("mouseup", pointerUp);
        };
    }, [internalRef.current]);

    React.useEffect(() => {
        if (!internalRef.current) return;
        if (!isMoving) return;

        const track = internalRef.current.querySelector(".customComponents__slider__track");
        if (!track) return;
        const thumb = internalRef.current.querySelector(".customComponents__slider__thumb");
        if (!thumb) return;
        const fill = internalRef.current.querySelector(".customComponents__slider__fill");
        if (!fill) return;

        const setThumbValue = (percent) => {
            if (!internalRef.current) return;
            if (!track || !thumb) return;

            thumb.style.left = `calc(${percent}% - 10px)`;
            fill.style.width = `${percent}%`;
        };

        const maxValue = props.max ? Number(props.max) : 100;
        const minValue = props.min ? Number(props.min) : 0;
        if (isNaN(maxValue)) maxValue = 100;
        if (isNaN(minValue)) minValue = 0;
        if (minValue > maxValue) {
            let tmp = maxValue;
            maxValue = minValue;
            minValue = tmp;
        };

        const trackBounding = track.getBoundingClientRect();

        const pointerMove = e => {
            if (!isMoving) return;
            let finalX = e.clientX - trackBounding.x
            let finalValue = Math.round(100 / trackBounding.width * finalX);
            if (finalValue < 0) finalValue = 0;
            if (finalValue > 100) finalValue = 100;
            
            setThumbValue(finalValue);
            if (props.onChange) props.onChange(map(finalValue, 0, 100, minValue, maxValue));
        }
        document.body.addEventListener("mousemove", pointerMove);

        return () => document.body.removeEventListener("mousemove", pointerMove);
    }, [isMoving, props.max]);

    return <div className={`customComponents__slider customComponents__slider--${theme}`} ref={internalRef}>
        <div className="customComponents__slider__thumb"></div>
        <div className="customComponents__slider__track"></div>
        <div className="customComponents__slider__fill"></div>
    </div>
};

Slider.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
    theme: PropTypes.oneOf(["light", "dark"]),
    accent: PropTypes.string,

    onChange: PropTypes.func
};

export default Slider;