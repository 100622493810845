export const getLeadStatusColor = (statusCode) => {
    switch (statusCode) {
        case 100:
            return "#6de66d";
        case 99:
        case 98:
            return "#e66d6d";
        case 2:
            return "#e6de6d";
        default: return "#dfdfdf";
    };
};
export const getLeadStatusColor2 = (statusCode) => {
    switch (statusCode) {
        case 0: return "rgb(136, 135, 249)";
        case 1: return "rgb(155, 238, 116)";
        case 2: return "rgb(59, 166, 147)";
        case 49: return "rgb(237, 110, 100)";
        case 50: return "rgb(237, 110, 100)";

        case 97: return "rgb(119, 138, 27)";
        case 98: return "rgb(233, 86, 139)";
        case 99: return "rgb(230, 86, 233)";

        case 100: return "rgb(59, 136, 227)";
        default: return "#dfdfdf";
    };
};

export const getDealStatusColor = statusCode => {
    switch (statusCode) {
        case 100:
            return "#6de66d";
        case 99:
        case 98:
        case 50:
            return "#e66d6d";
        case 2:
            return "#e6de6d";
        default: return "#dfdfdf";
    };
};
export const getDealStatusColor2 = statusCode => {
    switch (statusCode) {
        case 0: return "rgb(136, 135, 249)";
        case 1: return "rgb(155, 238, 116)";
        case 2: return "rgb(67, 193, 120)";
        case 3: return "rgb(47, 185, 161)";
        
        case 49: return "rgb(19, 117, 100)";
        case 50: return "rgb(233, 86, 139)";
        
        case 98: return "rgb(237, 110, 100)";
        case 99: return "rgb(230, 86, 233)";
        case 100: return "rgb(59, 136, 227)";
        
        default: return "#dfdfdf";
    };
};

export const getDealShipColor = statusCode => {
    switch (statusCode) {
        case 7:
            return "#6de66d";
        case 0:
        case 4:
        case 8:
            return "#e66d6d";
        case 1:
        case 2:
        case 3:
            return "#e6de6d";
        case 5:
        case 6:
            return "rgb(162, 120, 255)";
        default: return "#dfdfdf";
    };
};

export const getDealSaveShipColor = statusCode => {
    switch (statusCode) {
        case 2:
            return "#e6de6d";
        case 99:
            return "#e66d6d";
        case 100:
        case 101:
            return "#6de66d";
        default: return "#dfdfdf";
    };
};

export const getUnpaidRemindersStatusColor = statusCode => {
    switch (statusCode) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            return "#e6de6d";
        case 5:
            return "#e66d6d";
        default: return "#dfdfdf";
    };
};

export const getRecurringPaymentColor = statusCode => {
    switch (statusCode) {
        case false: return "#e6de6d";
        case true: return "#6de66d";
        default: return "#dfdfdf";
    };
};

export const headerColors = type => {
    if (type === "lead") return ['#6664E5', 'rgb(84, 145, 56)', 'rgb(59, 166, 147)',"rgb(30 196 193)","rgb(19, 117, 100)", "rgb(237, 110, 100)", "rgb(119, 138, 27)", '#E9568B', '#E656E9', '#3B88E3'];
    if (type === "deal") return ['#6664E5', 'rgb(94, 168, 59)', 'rgb(67, 193, 120)', 'rgb(47, 185, 161)',"#8f8f25","rgb(105 161 50)",'rgb(19 117 100)', '#E9568B', '#ed6e64', '#E656E9', '#3B88E3', "#2b2c3e"];

    return [];
};