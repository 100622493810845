const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "USERS_ADD":
            const foundUser = state.find(tmp => tmp.ID === action.payload.ID);
            if (foundUser) {
                return [
                    ...state.filter(tmp => tmp.ID < action.payload.ID),
                    action.payload,
                    ...state.filter(tmp => tmp.ID > action.payload.ID)
                ]
            } else {
                return [
                    ...state,
                    action.payload
                ];
            };
        case "USERS_REMOVE":
            return state.filter(item => item.ID !== action.payload);
        case "USERS_BULK_ADD":
            for (let i = 0; i < action.payload.length; i++) {
                if (action.payload[i].ID < 0) {
                    action.payload[i].Image = '/images/robo.png'
                }
            }
            return [...state, ...action.payload];
        case "USERS_CLEAN": return [];
        default: return state;
    };
};