import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";

import { useSelector, useDispatch, Provider } from "react-redux";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import * as modalActions from "../../actions/modalActions";
import { animateBox } from "../../modules/componentAnimation";

import YesNoModal from "../ModalController/modals/YesNoModal";
import SIPCallModal from "../ModalController/modals/SIPCallModal";
import { showModalController } from "../ModalController";
import Spinner from "../customComponents/Spinner";
import { FilteredCustomTable } from "../customComponents/Table";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import CustomInput from "../customComponents/CustomInput";

const closeSipModal = () => {
    let tmp = document.body.querySelector("[data-sipCallModal]");
    if (tmp) {
        tmp.style.pointerEvents = "none";
        tmp.animate([{opacity: getComputedStyle(tmp).opacity}, {opacity: 0}], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        }).onfinish = () => {
            tmp.remove();
            document.body.removeEventListener("click", closeSipModal);
        };
        return;
    };
};

const SIPButton = (props) => {
    const sipSelector = useSelector(state => state.sip);
    const sipDispatch = useDispatch();

    const createSipModal = (e) => {
        e.stopPropagation();

        try {
            if (!backendModule.getStore().getState().modal.visible) {
                showModalController();
                return;
            };
        } catch {};

        const visibility = {
            onlineUsers: false
        };

        const checkInput = () => {
            callNumberClose.style.opacity = callNumberInput.value ? 1 : 0;
            callNumberClose.style.pointerEvents = callNumberInput.value ? "all" : "none";
            if (callNumberInput.value === "00") callNumberInput.value = "+";
        };

        let tmp = document.body.querySelector("[data-sipCallModal]");
        if (tmp) {
            tmp.animate([{opacity: getComputedStyle(tmp).opacity}, {opacity: 0}], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease-in-out"
            }).onfinish = () => tmp.remove();
            return;
        };
        tmp = document.createElement("div");

        tmp.classList.add("component__sipButton__sipCallModal");
        tmp.setAttribute("data-sipCallModal", "1");
        tmp.style.top =props.left ?? `${e.clientY+30}px`;
        tmp.style.left =props.top?? `${e.clientX - 127}px`;
        tmp.addEventListener("click", e2 => e2.stopImmediatePropagation());

        const onlineUsersDiv = document.createElement("div");
        onlineUsersDiv.classList.add("component__sipButton__sipCallModal__buttons__onlineUsersWrap");
        tmp.appendChild(onlineUsersDiv);

        const buttonsDiv = document.createElement("div");
        buttonsDiv.classList.add("component__sipButton__sipCallModal__buttons");

        const onlineUsersBtn = document.createElement("div");
        onlineUsersBtn.style.backgroundImage = `url("/images/usersIcon.png")`;
        onlineUsersBtn.classList.add("component__sipButton__sipCallModal__buttons__onlineUsers");
        onlineUsersBtn.addEventListener("click", () => {
            visibility.onlineUsers = !visibility.onlineUsers;
            let cmp = getComputedStyle(onlineUsersDiv);
            if (visibility.onlineUsers) {
                let wrapDiv = document.createElement("div");
                wrapDiv.classList.add("component__sipButton__sipCallModal__buttons__onlineUsers__wrap");
                onlineUsersDiv.appendChild(wrapDiv);
                createRoot(wrapDiv).render(<Provider store={backendModule.getStore()}>
                    <ActiveUsers onChange={(num) => {
                        onlineUsersBtn.click();
                        callNumberInput.value = num;
                        checkInput();
                    }} />
                </Provider>);
            };
            onlineUsersDiv.style.pointerEvents = visibility.onlineUsers ? null : "none";
            onlineUsersDiv.animate([
                {width: cmp.width},
                {width: visibility.onlineUsers ? `${tmp.getBoundingClientRect().width - Number(cmp.left.replace("px", ""))}px` : "0px"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease-in-out"
            }).onfinish = () => {
                if (!visibility.onlineUsers) {
                    [...onlineUsersDiv.children].forEach(child => child.remove());
                };
            };
        });
        buttonsDiv.appendChild(onlineUsersBtn);

        const phoneBook = document.createElement("div");
        phoneBook.style.backgroundImage = `url("/images/fileIcon.png")`;
        phoneBook.classList.add("component__sipButton__sipCallModal__buttons__onlineUsers");
        phoneBook.classList.add("component__sipButton__sipCallModal__buttons__phoneBook");
        phoneBook.addEventListener("click", (e) => {
            animateBox(e, <ContactBook callNum={(num) => {
                callNumberInput.value = num;
                checkInput();
            }} />);
        });
        buttonsDiv.appendChild(phoneBook);

        const recentCalls = document.createElement("div");
        recentCalls.style.backgroundImage = `url("/images/sip_history.svg")`;
        recentCalls.classList.add("component__sipButton__sipCallModal__buttons__onlineUsers");
        recentCalls.classList.add("component__sipButton__sipCallModal__buttons__recentCalls");
        recentCalls.addEventListener("click", (e) => {
            animateBox(e, <RecentCalls callNum={(num) => {
                callNumberInput.value = num;
                checkInput();
            }} />);
        });
        buttonsDiv.appendChild(recentCalls);

        tmp.appendChild(buttonsDiv);

        const callNumber = document.createElement("div");
        callNumber.classList.add("component__sipButton__sipCallModal__callNumber");
        const callNumberInput = document.createElement("input");
        callNumberInput.type = "text";
        callNumberInput.addEventListener("keyup", checkInput);
        callNumberInput.addEventListener("keyup", (e) => {
            if (e.key === "Enter") callBtn.click();
        });
        callNumber.appendChild(callNumberInput); 
        const callNumberClose = document.createElement("img");
        callNumberClose.src = "/images/closeImg.svg";
        callNumberClose.addEventListener("click", () => {
            callNumberInput.value = "";
            checkInput();
        });
        callNumber.appendChild(callNumberClose);
        tmp.appendChild(callNumber);

        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].forEach(dialNum => {
            let dialElem = document.createElement("div");
            dialElem.classList.add("component__sipButton__sipCallModal__dialNum");
            dialElem.innerText = dialNum;
            dialElem.addEventListener("click", () => {
                callNumberInput.value += dialNum;
                checkInput();
            });
            tmp.appendChild(dialElem);
        });

        const finalDialButtons = document.createElement("div");
        finalDialButtons.classList.add("component__sipButton__sipCallModal__finalDialButtons");
        const callBtn = document.createElement("div");
        callBtn.classList.add("component__sipButton__sipCallModal__finalDialButtons__call");
        callBtn.innerHTML = "Pozovi";
        callBtn.addEventListener("click", () => {
            if (callNumberInput) {
                sipDispatch(modalActions.addModal(<SIPCallModal type="outgoing" phone={callNumberInput.value} />));
                closeSipModal();
            };
        })
        finalDialButtons.appendChild(callBtn);
        const closeBtn = document.createElement("div");
        closeBtn.innerHTML = "Zatvori";
        closeBtn.classList.add("component__sipButton__sipCallModal__finalDialButtons__close");
        closeBtn.addEventListener("click", closeSipModal);
        finalDialButtons.appendChild(closeBtn);
        tmp.appendChild(finalDialButtons);

        document.body.appendChild(tmp);

        let curRect = tmp.getBoundingClientRect();
        let bodyRect = document.body.getBoundingClientRect();
        if (curRect.x + curRect.width > bodyRect.width - 10) {
            tmp.style.left = `${bodyRect.width - curRect.width - 10}px`;
        };

        callNumberInput.focus();
        tmp.animate([{opacity: 0}, {opacity: 1}], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        });

        document.body.addEventListener("click", closeSipModal);
    };

    const ActiveUsers = (props) => {
        const [data, setData] = React.useState();

        const userSelector = useSelector(state => state?.userData?.userData?.UserInfo?.ID);
        const chatUsersSelector = useSelector(state => state?.chatUsers ?? {});

        React.useEffect(() => {
            if (!userSelector) {
                return setData({status: "error", data: "SERVER_ERROR"});
            };
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/getAllActiveUserIDs`,
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setData({status: "ok", data: res?.data?.data?.filter(t => t.ID !== userSelector)});
                } else {
                    setData({status: "error", data: "SERVER_ERRROR"});
                };
            }).catch(() => {
                setData({status: "error", data: "SERVER_ERRROR"});
            });
        }, []);

        return <div style={{
            justifyContent: (!data || data?.status === "error") ? "center" : null,
            alignItems: (!data || data?.status === "error") ? "center" : null
        }} className="component__sipButton__sipCallModal__buttons__onlineUsers__wrap__inner">
            {data ? <>
                {data.status === "ok" ? <>
                    {data?.data?.map(usr => {
                        return <div key={`sipCallModal__user-${usr.ID}`} onClick={e => {
                            e.stopPropagation();
                            props.onChange && props.onChange(usr.SIPUsername);
                        }} className="component__sipButton__sipCallModal__buttons__onlineUsers__wrap__inner__usr">
                            {chatUsersSelector[usr.ID] ? <>
                                <img src={chatUsersSelector[usr.ID].Image ?? "/images/undefined.png"} onError={e => {
                                    e.currentTarget.src = "/images/undefined.png";
                                }} />
                                <span>{chatUsersSelector[usr.ID].FirstName} {chatUsersSelector[usr.ID].LastName}</span>
                            </> : <>
                                <img src="/images/undefined.png" />
                                <span>Nepoznat korisnik</span>
                            </>}
                        </div>
                    })}
                </> : <p>Došlo je do greške</p>}
            </> : <Spinner color="#6664E5" />}
        </div>
    };

    return <div className="component__sipButton" style={props.style}>
        {(()=>{
            if (!sipSelector.sip.status || sipSelector.sip.status === "sipStatus/CONNECTING" || sipSelector.sip.status === "sipStatus/CONNECTED") return <Spinner key="sipSpinner" align="center" style={{width: "18px", height: "18px"}} color="#6664E5" />
            if (sipSelector.sip.status === "sipStatus/REGISTERED") {
                return <img style={props.imageStyle} src="/images/sipPhone.png" onClick={createSipModal} />
            } else {
                return <img src="/images/sipWarning.png" onClick={() => {
                    return sipDispatch(modalActions.addModal(<YesNoModal
                        hideNo={true}
                        textYes="Ok"
                        heading="SIP Greška"
                        text={(()=>{
                            switch (sipSelector.sip.status) {
                                case "sipStatus/DISCONNECTED": return "SIP server odspojen, osvježite stranicu i probajte ponovo!";
                                case "sipStatus/ERROR": return "Greška prilikom povezivanja na SIP server. Obratite se administratoru.";   
                                default: return "Došlo je do generične greške na SIP serveru!";
                            };
                        })()}
                    />));
                }} />
            };
        })()}
    </div>
};

const ContactBook = (props) => {
    const [filteredData, setFilteredData] = React.useState();
    const [data, setData] = React.useState();

    const parseSearch = (inp) => {
        if (filteredData?.status !== "ok") {
            return setFilteredData(data);
        };
        if (!inp) return setFilteredData(data);

        let out = [];
        for (let item of data.data) {
            if (
                item.FirstLastName.toLowerCase().includes(inp.toLowerCase()) ||
                item.PhoneNumber.toLowerCase().includes(inp.toLowerCase())
            ) out.push(item);
        };
        setFilteredData({status: "ok", data: out});
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/contacts/getAllContacts`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
                parseSearch();
            } else {
                setData({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        })
    };

    React.useEffect(() => {
        if (!data) return;
        if (data?.status !== "ok") return;
        parseSearch();
    }, [data]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="component__sipButton__contactBook" onClick={e => e.stopPropagation()}>
        <div className="component__sipButton__contactBook__wrap">
            <div className="component__sipButton__contactBook__wrap__buttons">
                <CustomButtonSmall className="component__sipButton__contactBook__wrap__buttons__add" value="Dodaj kontakt" accent="#6664E5" theme="dark" onClick={e => {
                    animateBox(e, <ContctBookAdd onChange={() => getData()} />);
                }} style={{width: "120px"}} />
                <CustomButtonSmall value="Izlaz" accent="rgb(216, 38, 38)" theme="dark" onClick={props.onClose} />
            </div>
            <CustomInput onChange={(e) => {
                parseSearch(e.currentTarget.value);
            }} type="text" placeholder="Traži..." accent="#6664E5" theme="dark" style={{marginBottom: "10px"}} />
            <FilteredCustomTable
                theme="dark"
                accent="#6664E5"
                headers={["Ime i prezime", "Broj telefona"]}
                data={(()=>{
                    if (!filteredData) return [[{keyID: "dataSpinner", type: "spinner"}]];
                    if (filteredData.status === "error") return [[{keyID: "dataErrorText", type: "custom", data: <p style={{textAlign: "center"}}>Došlo je do greške prilikom dohvatanja kontakata</p>}]]
                    if (filteredData.data.length === 0) return [[{keyID: "noDataText", type: "custom", data: <p style={{textAlign: "center"}}>Nema brojeva za prikaz</p>}]];
    
                    let tmp = filteredData.data.map((elem) => {
                        return [
                            {keyID: String(elem.ID), type: "text", text: elem.FirstLastName},   
                            {keyID: String(elem.ID), type: "text", text: elem.PhoneNumber},
                            {keyID: String(elem.ID), type: "groupNewline", group: [
                                {keyID: String(elem.ID), type: "button", text: "Pozovi", onClick: () => {
                                    props.callNum(elem.PhoneNumber);
                                    props.onClose();
                                }},
                                {keyID: String(elem.ID), type: "button", text: "Uredi", triggerDropdown: true, triggerData: c => <ContctBookAdd onChange={() => getData()} onClose={c} editMode={elem.ID} data={elem} style={{padding: 0, width: "100%", backgroundColor: "transparent"}} />},
                                {keyID: String(elem.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: c => <DeleteContact onChange={() => getData()} onClose={c} data={elem} style={{padding: 0, width: "100%", backgroundColor: "transparent"}} />}
                            ]}
                        ]
                    });

                    return tmp;
                })()}
            />
        </div>
    </div>
};

const ContctBookAdd = (props) => {
    const [infoP, setInfoP] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);

    const nameRef = React.useRef();
    const numberRef = React.useRef();

    const processData = () => {
        setInfoP();
        let data = {
            FirstLastName: nameRef.current.value,
            PhoneNumber: numberRef.current.value
        };
        if (props.editMode) data.ID = props.editMode;
        if (!data.FirstLastName) return setInfoP("Ime ne može biti prazno");
        if (!data.PhoneNumber) return setInfoP("Broj telefona ne može biti prazan");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/contacts/${data.ID ? "editContact" : "addContact"}`,
            data,
            ...backendModule.axiosConfig
        }).then(async res => {
            if (res.data.status === "ok") {
                await props.onClose();
                props.onChange();
            } else {
                setInfoP("Došlo je do greške!");
            };
        }).catch(() => {
            setInfoP("Server ne reagira");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="component__sipButton__contactBook__wrap__add" onClick={e => e.stopPropagation()} style={props.style ?? {}}>
        <CustomInput defaultValue={props?.data?.FirstLastName} ref={nameRef} placeholder="Ime i prezime" accent="#6664E5" theme="dark" />
        <CustomInput defaultValue={props?.data?.PhoneNumber} ref={numberRef} placeholder="Broj telefona" accent="#6664E5" theme="dark" />

        {spinner ? <>
            <Spinner color="#6664E5" />
        </> : <>
            <div>
                <CustomButtonSmall accent="#6664E5" theme="dark" value="Spremi" onClick={processData} />
                <CustomButtonSmall accent="rgb(216, 38, 38)" theme="dark" value="Izlaz" onClick={props.onClose} />
            </div>
        </>}
        {!spinner && <p style={{
            color: "#ff6f6f"
        }}>{infoP}</p>}
    </div>
};

const DeleteContact = props => {
    const [spinner, setSpinner] = React.useState(false);

    const deleteData = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/contacts/removeContact`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(async () => {
            await props.onClose();
            props.onChange();
        })
    };


    return <div className="component__sipButton__contactBook__wrap__add" onClick={e => e.stopPropagation()} style={props.style ?? {}}>
        {spinner ? <>
            <Spinner color="#6664E5" />
        </> : <>
            <p>Da li ste sigurni?</p>
            <p>{props.data.FirstLastName} će biti trajno obrisan</p>
            <div>
                <CustomButtonSmall accent="#6664E5" theme="dark" value="Obriši" onClick={deleteData} />
                <CustomButtonSmall accent="rgb(216, 38, 38)" theme="dark" value="Izlaz" onClick={props.onClose} />
            </div>
        </>}
    </div>
};

const RecentCalls = props => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/callRecordings/getAllCalls`,
            data: {
                pagination: paginationOffset.current,
                filters: [
                    ...filters
                ].filter(t => t),
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/callRecordings/getAllCalls`,
            data: {
                pagination: paginationOffset.current,
                filters: [
                    ...filters
                ].filter(t => t),
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({status: "error", data: "SERVER_ERROR"});
        }).catch(() => {
            return setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch {};

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    React.useEffect(() => {
        getData();
    }, [filters]);

    return <div className="component__sipButton__recentCalls" onClick={e => e.stopPropagation()}>
        <div className="component__sipButton__recentCalls__wrap">
            <div className="component__sipButton__recentCalls__wrap__buttons">
                <p>Historija</p>
                <CustomButtonSmall value="Izlaz" accent="rgb(216, 38, 38)" theme="dark" onClick={props.onClose} />
            </div>

            <FilteredCustomTable
            headers={["Broj", "Tip", "Trajanje", "Datum"]}
            theme="dark"
            accent="#6664E5"
            filterCB={fi => setFilters(fi)}
            filters={[
                {name: "Number", friendlyName: "Broj telefona", type: "string"},
                {name: "isIncomming", friendlyName: "Dolazni poziv", type: "boolean"},
                {name: "isMissed", friendlyName: "Propušteni poziv", type: "boolean"}
            ]}
            data={(()=>{
                if (!data) return [[{keyID: "dataSpinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "dataErrorText", type: "custom", data: <p style={{textAlign: "center"}}>Došlo je do greške prilikom dohvatanja historje</p>}]]
                if (data.data.length === 0) return [[{keyID: "noDataText", type: "custom", data: <p style={{textAlign: "center"}}>Nema historje za prikaz</p>}]];

                let tmp = data.data.map(elem => {
                    return [
                        {key: elem.ID, type: "text", text: elem.Number},
                        {key: elem.ID, type: "text", text: elem.isIncomming ? (elem.Duration ? "Dolazni poziv ->" : "Dolazni propušteni poziv ->") : (elem.Duration ? "<- Odlazni poziv" : "<- Odlazni propušteni poziv"), style: {color: elem.Duration ? null : "#ffa0a0"}},
                        {key: elem.ID, type: "text", text: (()=>{
                            let dur = Math.floor(elem.Duration / 1000);
                            let minutes = 0;
                            let seconds = 0;
                            while (dur >= 60) {
                                minutes += 1;
                                dur -= 60;
                            };
                            minutes = String(minutes);
                            seconds = String(dur);
                            if (minutes.length === 1) minutes = `0${minutes}`;
                            if (seconds.length === 1) seconds = `0${seconds}`;
                            return `${minutes}:${seconds}`;
                        })()},
                        {key: elem.ID, type: "text", text: new Date(elem.createdAt).toLocaleString()}
                    ];
                });

                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);
                if (secondarySpinner) tmp.push([{keyID: "paginationSpinner", type: "spinner"}]);

                return tmp;
            })()}
        />
        </div>
    </div>
};

export default SIPButton;