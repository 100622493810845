import React from "react";
import "./index.scss";

import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import axios from "axios";
import { useSelector } from "react-redux";
import { getDealShipColor, headerColors } from "../../../modules/leadModule";

import CustomButtonSmall from "../../../components/customComponents/ButtonSmall";
import { FilteredCustomTable } from "../../../components/customComponents/Table";
import Spinner from "../../../components/customComponents/Spinner";
import AudioPlayer from "../../../components/customComponents/AudioPlayer";
import { Kanban } from "../../../components/customComponents/Kanban";
import EditLead from "../../../components/EditLead";
import EditDeal from "../../../components/EditDeal";
import { SingleLead as LeadInfo } from "../../../components/customComponents/Kanban";
import DealShipmentHistory from "../../../components/DealShipmentHistory";
import SingleLead from "../../../components/SingleLead";

const CCManagerDeals = (props) => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [countries, setCountries] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const [display, setDisplay] = React.useState('kanban')

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const dealStatusSelector = useSelector(state => state?.statusCodes?.deals ?? {});
    const shipmentStatusCodes = useSelector(state => state?.statusCodes?.shipment ?? null);

    const onSelect = props.onSelect ? (data) => {
        props.onSelect(data);
        props.onClose();
    } : null;

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(res.data);
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                pagination: paginationOffset.current,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}>

        </div>;
    };

    const processDealStatusCode = (dealStatus) => {
        const stages = [
            "#39a8ef",
            "#FFF467",
            "#FF5752",
            "#7BD500"
        ];
        let selected = 0;
        let fillLines = 0;
        dealStatus = Number(dealStatus);

        switch (dealStatus) {
            case 0:
                selected = 0;
                break;
            case 1:
                selected = 1;
                fillLines = 1;
                break;
            case 2:
            case 3:
                selected = 2;
                fillLines = 2;
                break;
            case 97:
            case 98:
            case 99:
                selected = 3;
                fillLines = 3;
                break;
            case 100:
                selected = 4;
                fillLines = 3;
                break;
            default: break;
        }

        return <div className="route__adminPanel__leads__statusCodes__lines">
            {[1, 2, 3].map(elem => {
                return <span key={`dealStatusLine${elem}`} style={{
                    backgroundColor: fillLines >= elem ? (selected > 0 ? stages[selected - 1] : "gray") : "gray"
                }}></span>
            })}
        </div>
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return setCountries(res.data);
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        });
    }, []);

    React.useEffect(() => {
        getData();
    }, [filters]);

    return <div className="route__adminPanel__leads">
        <div className="route__adminPanel__leads__buttons">
            {display === 'kanban' && props.onClose && <CustomButtonSmall accent="#6664E5A1" value="Tabela" onClick={() => { setDisplay('table') }} />}
            {display === 'table' && <CustomButtonSmall accent="#6664E5A1" value="Kanban" onClick={() => { setDisplay('kanban') }} />}

            {props.onClose && <CustomButtonSmall accent="#d82626" value="X" onClick={() => props.onClose()} />}
        </div>
        {display === 'kanban' && <Kanban
            onSelect={onSelect}
            type={'deal'}
            headersColors={headerColors("deal")}
            data={(() => {
                if (!data || !countries) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                if (data.status === "error" || countries.status === "error") return [[{ key: "dataInfoText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške</p> }]];
                if (data.data.length === 0) return [[{ key: "noDatatext", type: "custom", data: <p style={{ textAlign: "center" }}>Nema podataka za prikaz</p> }]]

                let tmp = data.data.map(item => {
                    return { ID: item.ID, name: item.Name, agent: item.ResponsiblePersonName, status: item.Status, allData: item }
                });

                if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);

                return tmp;
            })()}
        />}
        {display === 'table' && <FilteredCustomTable
            theme="dark"
            accent="#6664E5"
            headers={["ID", "Naziv", "Odgovorna osoba", "Status"
            ]}
            filterCB={fi => setFilters(fi)}
            filters={[
                { name: "ID", friendlyName: "ID", type: "string"},
                { name: "Name", friendlyName: "Naziv deala", type: "string" },
                {
                    name: "Status", friendlyName: "Status deala", type: "custom", varType: "string", data: Object.keys(dealStatusSelector).map(dealStatusKey => {
                        return { text: dealStatusSelector[dealStatusKey], value: dealStatusKey }
                    })
                },
                { name: "Amount", friendlyName: "Cijena", type: "string" },
                { name: "FirstLastName", friendlyName: "Ime i prezime kupca", type: "string" },
                { name: "PhoneNumber", friendlyName: "Broj telefona kupca", type: "string" },
                { name: "Address", friendlyName: "Adresa kupca", type: "string" },
                { name: "City", friendlyName: "Grad kupca", type: "string" },
                {
                    name: "Country", friendlyName: "Država kupca", type: "custom", varType: "string", data: countries?.data?.map(country => {
                        return { text: country.CountryName, value: country.CountryCode }
                    })
                },
                { name: "HouseNumber", friendlyName: "Broj kuće kupca", type: "string" },
                { name: "ScheduledCallTime", friendlyName: "Datum ponovnog poziva", type: "date" },
                { name: "CallAttempts", friendlyName: "Ukupni broj poziva", type: "number" }
            ]}
            data={(() => {
                if (!data || !countries) return [[{ keyID: "dataSpinner", type: "spinner" }]];
                if (data.status === "error" || countries.status === "error") return [[{ key: "dataInfoText", type: "custom", data: <p style={{ textAlign: "center" }}>Došlo je do greške</p> }]];
                if (data.data.length === 0) return [[{ key: "noDatatext", type: "custom", data: <p style={{ textAlign: "center" }}>Nema podataka za prikaz</p> }]]

                let tmp = data.data.map(item => {
                    let dealShipColor = null;
                    if (item.ShippmentStatus !== null && item.ShippmentStatus !== undefined) {
                        dealShipColor = getDealShipColor(Number(item.ShippmentStatus));
                    };
                    return [
                        { keyID: String(item.ID), type: "text", text: item.ID },
                        { keyID: String(item.ID), type: "text", text: item.Name },
                        { keyID: String(item.ID), type: "text", text: item.ResponsiblePersonName },
                        {
                            keyID: String(item.ID), type: "custom", data: <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                <div className="route__adminPanel__leads__statusCodes">
                                    <p>{dealStatusSelector[item.Status]}</p>
                                    {processDealStatusCode(item.Status)}
                                </div>
                                {(item.ShippmentStatus !== null && item.ShippmentStatus !== undefined) && <div className="route__adminPanel__leads__statusCodes" style={{marginTop: "30px"}}>
                                    <p>{shipmentStatusCodes[item.ShippmentStatus]}</p>
                                    <div className="route__adminPanel__leads__statusCodes__lines" style={{
                                        gridTemplateColumns: `repeat(${Object.keys(shipmentStatusCodes).length},1fr)`
                                    }}>
                                        {Object.keys(shipmentStatusCodes).map(key => {
                                            return <span style={{
                                                backgroundColor: key <= item.ShippmentStatus ? dealShipColor : "gray"
                                            }}></span>
                                        })}
                                    </div>
                                </div>}
                            </div>, style: {
                                width: "200px"
                            }
                        },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID),
                                    type: "button",
                                    text: "Detalji",
                                    onClick: (e) => {
                                        animateBox(e, <SingleLead data={item} type={'deal'} />)
                                    }
                                },
                            ]
                        },
                    ];
                });

                if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);

                return tmp;
            })()}
        />}
    </div>
};

export default CCManagerDeals;