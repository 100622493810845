export const addNotification = ({
    text = null,
    confirmText = null,
    closeText = null,
    onClose = null,
    onConfirm = null
} = {}) => {
    return {
        type: "NOTIFICATIONS_ADD",
        payload: {
            text,
            confirmText,
            closeText,
            onClose,
            onConfirm
        }
    };
};

export const removeNotification = (ID) => {
    return {
        type: "NOTIFICATIONS_REMOVE",
        payload: ID
    };
};

export const closeNotification = (ID) => {
    return {
        type: "NOTIFICATIONS_CLOSE",
        payload: ID
    };
};