export const addRefresh = ({
    value = false
} = {}) => {
    return {
        type: "REFRESH_ADD",
        payload: {
            value
        }
    };
};

export const removeRefresh = (ID) => {
    return {
        type: "REFRESH_REMOVE",
        payload: ID
    };
};

