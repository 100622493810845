const initialState = {
    sip: {
        status: null,
        errorType: null,
        errorMessage: null,
    },
    call: {
        id: null,
        status: null,
        direction: null,
        counterpart: null
    },
    ua: null,
    inboundStatus: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SIP_UPDATE": return {
            ...state,
            sip: {
                ...state.sip,
                status: action.payload.sip?.status ?? null,
                errorType: action.payload.sip?.errorType ?? null,
                errorMessage: action.payload.sip?.errorMessage ?? null,
            },
            call: {
                id: action.payload.call?.id ?? null,
                status: action.payload.call?.status ?? null,
                direction: action.payload.call?.direction ?? null,
                counterpart: action.payload.call?.counterpart ?? null
            }
        };
        case "SIP_SET_INBOUND_STATUS": return {
            ...state,
            inboundStatus: action.payload
        };
        default: return state;
    };
};